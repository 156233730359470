import styled from "styled-components";

interface SectionProps {
	hasMarginBottom?: boolean;
	hasBackgroundColor?: boolean;
}

const Section = styled.div<SectionProps>`
	${({ hasMarginBottom }) => hasMarginBottom && `margin-bottom: 24px;`}
	${({ hasBackgroundColor }) =>
		`background-color: ${
			hasBackgroundColor
				? "var(--color-surface-quaternary-default);"
				: "transparent"
		}`}
`;

export default Section;
