
import { DEFAULT_ORDER_CONVERSION_FEE } from "../../../../constants/common.constant";

/**
 * Get conversion fee
 * @param {number=} fee - use this instead if provided.
 * @return {number} The fee amount.
 */
const getConversionFee = (fee?: number): number => {
	return (
		fee ??
		process.env.REACT_APP_ORDER_CONVERSION_FEE ??
		DEFAULT_ORDER_CONVERSION_FEE
	);
};

export default getConversionFee;
