// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {MOCK_USER_PASSWORD} from "../constants/app.constant";
import {MAE_APP_EVENT_TYPE} from "../constants/integration.constant";
import {
    FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE, ONE_SECOND,
    POST_MESSAGE_PASSWORD_WAITING_TIME, POST_MESSAGE_RSA_WAITING_TIME
} from "../constants/fx.constant";
import {
    minusPasswordErrorTimeoutByOneSec, minusRsaErrorTimeoutByOneSec,
    resetPasswordErrorTimeout, resetRsaErrorTimeout
} from "../slice/errorTimeout";
import {
    FXOrderRSAType,
    FXOrderTransactionAcknowledgementInsufficientFundType,
    FXOrderTransactionAcknowledgementType
} from "../types/fx.type";
import {store} from "../store/store";

/**
 * Navigates the user back to the app.
 *
 * If the environment is react native web view, it posts a message to navigate back to the app.
 * Otherwise, it logs the back to app event type to the console.
 *
 * @function
 */
export const navigateToApp = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: MAE_APP_EVENT_TYPE.BACK_TO_APP,
                data: "",
            })
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.BACK_TO_APP);
    }
};

/**
 * Closes the application.
 *
 * @function
 * @name closeApp
 * @returns {void}
 */
export const closeApp = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: MAE_APP_EVENT_TYPE.CLOSE_APP,
                data: "",
            })
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.CLOSE_APP);
    }
};

/**
 * Emit error event to the app.
 *
 * @function
 * @name emitErrorToApp
 * @description This function sends an error event to the app using the React Native WebView's postMessage method.
 * If the postMessage method is not available, it logs the error event to the console.
 */
export const emitErrorToApp = (responseCode?:string |null) => {
    console.log( responseCode ? responseCode: "500")
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: MAE_APP_EVENT_TYPE.ERROR,
                data: responseCode ? responseCode: "500"})
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.ERROR);
    }
};

/**
 * Sends a message to the React Native WebView to contact the bank.
 * If the WebView is not available, logs the event type instead.
 *
 * @function
 * @name contactBank
 */
export const contactBank = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: MAE_APP_EVENT_TYPE.CONTACT_BANK,
                data: "",
            })
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.CONTACT_BANK);
    }
};

/**
 * Function to open the account.
 *
 * @function openAccount
 *
 * @returns {void}
 */
export const openAccount = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({type: MAE_APP_EVENT_TYPE.OPEN_ACCOUNT, data: ""})
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.OPEN_ACCOUNT);
    }
};

/**
 * Opens the Terms and Conditions view in the app.
 *
 * @param {string} url - The URL of the Terms and Conditions page.
 * @param {string} title - The title of the Terms and Conditions page.
 * @returns {void}
 */
export const openTNCView = (url: string, title: string) => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: MAE_APP_EVENT_TYPE.OPEN_TNC,
                data: {url, title},
            })
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.OPEN_TNC, url, title);
    }
};

/**
 * Represents an identifier for a timeout interval used in Node.js.
 *
 * @typedef {Object} NodeJS.Timeout
 *
 */
let passwordIntervalId: NodeJS.Timeout
/**
 * Represents the interval ID used for the final cleanup of password-related operations.
 *
 * @type {NodeJS.Timeout}
 */
let passwordIntervalIdForFinalCleanUp: NodeJS.Timeout
/**
 * Request password authentication.
 *
 * @returns {boolean|undefined} - True if password authentication succeeds, false otherwise. Undefined if running outside React Native WebView.
 */
export const requestPasswordAuthentication = (): boolean | undefined => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({type: MAE_APP_EVENT_TYPE.PASSWORD})
        );
        passwordIntervalId = setInterval(() => { //013a
            console.log('counting down')
            store.dispatch(minusPasswordErrorTimeoutByOneSec())
        }, ONE_SECOND)
        passwordIntervalIdForFinalCleanUp = setTimeout(() => {
            console.log('clearing interval')
            clearInterval(passwordIntervalId)
            store.dispatch(resetPasswordErrorTimeout(store.getState().errorTimeout.passwordResponseRemainTime))
        }, store.getState().errorTimeout.passwordResponseRemainTime + ONE_SECOND * 3)

    } else {
        console.log(MAE_APP_EVENT_TYPE.PASSWORD);
        const password = prompt("Please input password");
        return password === MOCK_USER_PASSWORD;
    }
};

/**
 * Mocks the request for password authentication.
 *
 * @returns {boolean | undefined} - Indicates whether the request for password authentication is successful or undefined if an error occurs.
 */
export const mockRequestPasswordAuthentication = (): boolean | undefined => {
    console.log('entered requestPasswordAuthentication')
    passwordIntervalId = setInterval(() => { //013a
        console.log('counting down')
        store.dispatch(minusPasswordErrorTimeoutByOneSec())
    }, ONE_SECOND)
    passwordIntervalIdForFinalCleanUp = setTimeout(() => {
        console.log('clearing interval')
        clearInterval(passwordIntervalId)
        store.dispatch(resetPasswordErrorTimeout(store.getState().errorTimeout.passwordResponseRemainTime))
    }, store.getState().errorTimeout.passwordResponseRemainTime + ONE_SECOND * 3)

};
/**
 * Clears the timeout interval for password error handling.
 * This function is called when the user successfully submits their password.
 */
export const clearPasswordTimeoutInterval = () => {
    // alert('ok, receive password, clear interval counter')
    clearInterval(passwordIntervalId)
    clearInterval(passwordIntervalIdForFinalCleanUp)
    store.dispatch(resetPasswordErrorTimeout(store.getState().errorTimeout.passwordResponseRemainTime))
}

/**
 * Represents the interval ID returned by `setInterval` function in Node.js.
 * @typedef {NodeJS.Timeout} rsaIntervalId
 */
let rsaIntervalId: NodeJS.Timeout
/**
 * @typedef {Object} NodeJS.Timeout
 * @property {number} _idleTimeout
 * @property {number} _idlePrev
 * @property {number} _idleNext
 * @property {number} _idleStart
 * @property {number} _onTimeout
 * @property {number} _repeat
 * @property {boolean} [unref]
 * @property {boolean} [ref]
 *
 * @description Variable `rsaIntervalIdForFinalCleanUp` stores the interval ID returned by the NodeJS `setInterval` function. It represents a timeout that is set to periodically perform
 * a final cleanup operation in RSA module.
 *
 * @type {NodeJS.Timeout}
 */
let rsaIntervalIdForFinalCleanUp: NodeJS.Timeout

/**
 * Sends an RSA request to the React Native WebView or prompts the user with an RSA question.
 *
 * @param {FXOrderRSAType} data - The data needed for the RSA request.
 * @returns {string | undefined} - The RSA response or undefined if the RSA request failed.
 */
export const requestRSA = (data: FXOrderRSAType): string | undefined => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({type: MAE_APP_EVENT_TYPE.RSA, data})
        );
        rsaIntervalId = setInterval(() => { //013a
            store.dispatch(minusRsaErrorTimeoutByOneSec())
        }, ONE_SECOND)
        rsaIntervalIdForFinalCleanUp = setTimeout(() => {
            clearInterval(rsaIntervalId)
            store.dispatch(resetRsaErrorTimeout(store.getState().errorTimeout.rsaResponseRemainTime))
        }, store.getState().errorTimeout.rsaResponseRemainTime + ONE_SECOND * 3)
    } else {
        console.log(MAE_APP_EVENT_TYPE.RSA, data);
        return prompt(data.rsaQuestion);
    }
};

/**
 * Mocks a request for RSA encryption.
 *
 * @param {FXOrderRSAType} data - The data for the RSA request.
 * @returns {string | undefined} Returns a string representing the result of the RSA request, or undefined if an error occurs.
 */
export const mockRequestRSA = (data: FXOrderRSAType): string | undefined => {
    rsaIntervalId = setInterval(() => { //013a
        store.dispatch(minusRsaErrorTimeoutByOneSec())
    }, ONE_SECOND)
    rsaIntervalIdForFinalCleanUp = setTimeout(() => {
        clearInterval(rsaIntervalId)
        store.dispatch(resetRsaErrorTimeout(store.getState().errorTimeout.rsaResponseRemainTime))
    }, store.getState().errorTimeout.rsaResponseRemainTime + ONE_SECOND * 3)
};

/**
 * Clears the RSA timeout interval and resets the RSA error timeout in the store.
 * This function is called when a password is received to clear the interval counter.
 */
export const clearRsaTimeoutInterval = () => {
    // alert('ok, receive password, clear interval counter')
    clearInterval(rsaIntervalId)
    clearInterval(rsaIntervalIdForFinalCleanUp)
    store.dispatch(resetRsaErrorTimeout(store.getState().errorTimeout.rsaResponseRemainTime))
}

/**
 * Sends a transaction acknowledgement to the native WebView in the MAE app.
 *
 * @param {FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE} status - The status of the transaction acknowledgement.
 * @param {FXOrderRSAType | FXOrderTransactionAcknowledgementType | FXOrderTransactionAcknowledgementInsufficientFundType} data - The data associated with the transaction acknowledgement
 *.
 * @returns {void}
 */
export const openTransactionResultView = (
    status: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE,
    data: FXOrderRSAType | FXOrderTransactionAcknowledgementType | FXOrderTransactionAcknowledgementInsufficientFundType
) => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: MAE_APP_EVENT_TYPE.TRANSACTION_ACK,
                transactionStatus: status,
                data,
            })
        );
    } else {
        console.log(MAE_APP_EVENT_TYPE.TRANSACTION_ACK, status, data);
    }
};
