import { DEFAULT_NUMPAD_INPUT_DECIMAL_POINT } from "../../../constants/common.constant";
import { formatNumber } from "../../../utils/common.utils";
import formatNumPadInput from "../formatNumPadInput";

/**
 * Shorthand function for format NumPad input to human readable format
 * @param {string} input - NumPad input value.
 * @param {boolean=} needFormat - Format with decimal point if needed.
 * @param {number=} decimal - Specify decimal point.
 * @return {string} The formatted human readable text.
 */
const formatNumPadInputToField = (
	input: string,
	needFormat = false,
	decimal = DEFAULT_NUMPAD_INPUT_DECIMAL_POINT
): string => {
	if (needFormat) input = formatNumPadInput(input);
	return formatNumber(input, decimal);
};

export default formatNumPadInputToField;
