import {toMalaysianTime} from "./toMalaysianTime";


/**
 * Returns the name of the day for a given date.
 *
 * @param {Date} date - The date for which to get the day name.
 * @return {string} - The name of the day.
 */
export function getDayName(date: Date): string {
    date = toMalaysianTime(date);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return daysOfWeek[date.getDay()];
}

/**
 * Calculates the index of a given day name in a backward week starting from Sunday.
 *
 * @param {string} dayName - The name of the day.
 * @return {number} The index of the day in a backward week starting from Sunday.
 * @throws {Error} If the provided day name is not valid.
 */
export function calculateBackwardMonthDays(dayName: string): number {
    const normalizedDayName = dayName.toLowerCase();
    switch (normalizedDayName) {
        case "monday":
            return 0;
        case "tuesday":
            return 1;
        case "wednesday":
            return 2;
        case "thursday":
            return 3;
        case "friday":
            return 4;
        case "saturday":
            return 5;
        case "sunday":
            return 6;
        default:
            alert("Invalid weekday: " + dayName);
            throw new Error("Invalid weekday: " + dayName);
    }
}

/**
 * Returns the first day of the month for a given input date.
 *
 * @param {Date} inputDate - The input date for which the first day of the month is to be determined.
 * @return {Date} The first day of the month for the given input date.
 */
function getFirstDayOfMonth(inputDate: Date): Date {
    inputDate = toMalaysianTime(inputDate);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    return toMalaysianTime(new Date(year, month, 1));
}

/**
 * Returns the last day of the month for a given input date.
 *
 * @param {Date} inputDate - The input date for which to find the last day of the month.
 * @return {Date} - The last day of the month as a Date object.
 */
function getLastDayOfMonth(inputDate: Date): Date {
    inputDate = toMalaysianTime(inputDate);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    return toMalaysianTime(new Date(year, month + 1, 0));
}

/**
 * Returns the date that is a specified number of days before the input date.
 *
 * @param {Date} inputDate - The input date.
 * @param {number} daysBack - The number of days to go back.
 * @return {Date} - The resulting date.
 */
function getDateBackward(inputDate: Date, daysBack: number): Date {
    let resultDate = toMalaysianTime(inputDate);
    resultDate.setDate(resultDate.getDate() - daysBack);
    return resultDate;
}

/**
 * Generates an array of dates starting from a given start date.
 *
 * @param {Date} startDate - The start date.
 * @param {number} daysToGen - The number of days to generate.
 * @returns {Date[]} - An array of generated dates.
 */
function generateDateRange(startDate: Date, daysToGen: number): Date[] {
    let dateArray: Date[] = [];
    startDate = toMalaysianTime(startDate);
    for (let i = 0; i < daysToGen; i++) {
        let newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + i);
        dateArray.push(toMalaysianTime(newDate));
    }
    return dateArray;
}

/**
 * Returns the next day of the given input date
 *
 * @param {Date} inputDate - The input date to find the next day from
 * @return {Date} - The next day of the input date
 */
export function getNextDay(inputDate: Date): Date {
    inputDate = toMalaysianTime(inputDate);
    let nextDay = new Date(inputDate);
    nextDay.setDate(inputDate.getDate() + 1);
    return toMalaysianTime(nextDay);
}

/**
 * Gets the date range based on the given date.
 *
 * @param {Date} dateObj - The date to generate the date range from.
 * @returns {Date[]} - The array of dates that make up the date range.
 */
export const getDateRange = (dateObj: Date): Date[] => {
    dateObj = toMalaysianTime(dateObj);
    const lastDateOfMonth = getLastDayOfMonth(dateObj);
    const backwardMonthDays: number = calculateBackwardMonthDays(getDayName(getFirstDayOfMonth(dateObj)));
    const startingDate: Date = getDateBackward(dateObj, backwardMonthDays);
    const dateRange = generateDateRange(startingDate, 35);

    const isLater: boolean = lastDateOfMonth > dateRange[dateRange.length - 1];
    if (isLater) {
        const nextDay = getNextDay(dateRange[dateRange.length - 1]);
        const additionalDateRange = generateDateRange(nextDay, 7);
        return [...dateRange, ...additionalDateRange];
    } else {
        return dateRange;
    }
}
