import { FormError } from "../../../../types/common.type";
import { CurrencyType, FXOrderFormType } from "../../../../types/fx.type";
import validateAmount from "../validateAmount";

/**
 * Validate FX order form
 * @param {FXOrderFormType} fxOrderForm - The FX order form data.
 * @return {Array<FormError>} Error list.
 */
const validateOrderForm = (
	fxOrderForm: FXOrderFormType,
	currencies: CurrencyType[] = []
): FormError[] => {
	const errors: FormError[] = [];

	const sourceAmountErrors = validateAmount(
		fxOrderForm.source.amount.value,
		fxOrderForm.source.currency,
		currencies,
		fxOrderForm.future,
		{ isContainMin: true, isContainMax: true }
	);
	if (sourceAmountErrors.length > 0) errors.concat(sourceAmountErrors);

	if (fxOrderForm.target.currency) {
		const targetAmountErrors = validateAmount(
			fxOrderForm.target.amount.value,
			fxOrderForm.target.currency,
			currencies,
			fxOrderForm.future
		);
		if (targetAmountErrors.length > 0) errors.concat(targetAmountErrors);
	} else {
		errors.push({
			field: "target",
			message: "E0001",
		});
	}

	if (!fxOrderForm.fx)
		errors.push({
			field: "fx",
			message: "E0002",
		});

	if (!fxOrderForm.bankAccount.source)
		errors.push({
			field: "fx",
			message: "E0003",
		});

	if (!fxOrderForm.bankAccount.target)
		errors.push({
			field: "fx",
			message: "E0004",
		});

	return errors;
};

export default validateOrderForm;
