import {
	createAsyncThunk,
	createSlice,
	current,
	PayloadAction,
} from "@reduxjs/toolkit";
import watchlistAPI from "../api/watchlist.api";
import { RootState } from "../store/store";
import { WatchlistStateType, WatchlistType } from "../types/watchlist.type";
import { FXType } from "../types/fx.type";

interface UpdateWatchlistActionType {
	selectedWatchlistIndex: number;
	watchlist: WatchlistType;
}

interface UpdateWatchlistFXActionType {
	selectedWatchlistIndex: number;
	fxList: FXType[];
}

const initialState: WatchlistStateType = {
	list: [],
	selectedListIndex: 0,
};

// export const fetchWatchlist = createAsyncThunk("watchlist/get", async () => {
// 	const results = await watchlistAPI.get();
// 	return {
// 		data: results.data,
// 	};
// });

export const watchlistSlice = createSlice({
	name: "watchlist",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		updateWatchlists: (state, action: PayloadAction<WatchlistType[]>) => {
			state.list = [...action.payload];
		},
		updateWatchlist: (
			state,
			action: PayloadAction<UpdateWatchlistActionType>
		) => {
			if (!state.list[action.payload.selectedWatchlistIndex]) return;

			state.list[action.payload.selectedWatchlistIndex] = {
				...state.list[action.payload.selectedWatchlistIndex],
				...action.payload.watchlist,
			};
		},
		updateWatchlistFX: (
			state,
			action: PayloadAction<UpdateWatchlistFXActionType>
		) => {
			if (!state.list[action.payload.selectedWatchlistIndex]) return;

			state.list[action.payload.selectedWatchlistIndex].fxList = state.list[
				action.payload.selectedWatchlistIndex
			].fxList.map((v) => {
				const existingFX = action.payload.fxList.find(
					(w) => w.symbol === v.symbol
				);

				return existingFX ? { ...v, ...existingFX } : v;
			});
		},
		updateSelectedWatchlistIndex: (state, action: PayloadAction<number>) => {
			state.selectedListIndex = action.payload;
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	// extraReducers: (builder) => {
	// 	builder
	// 		.addCase(
	// 			fetchWatchlist.fulfilled,
	// 			(state, action: PayloadAction<any>) => {
	// 				const { data } = action.payload;

	// 				return state;
	// 			}
	// 		)
	// 		.addCase(fetchWatchlist.rejected, () => initialState);
	// },
});

export const {
	updateWatchlists,
	updateWatchlist,
	updateWatchlistFX,
	updateSelectedWatchlistIndex,
} = watchlistSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectWatchlist = (state: RootState) => state.watchlist.list;
export const selectSelectedWatchlistIndex = (state: RootState) =>
	state.watchlist.selectedListIndex;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default watchlistSlice.reducer;
