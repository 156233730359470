import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    useParams,
    unstable_useBlocker as useBlocker,
    unstable_BlockerFunction as BlockerFunction,
    matchPath,
} from "react-router-dom";
import {useIntl} from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import Countdown, {CountdownApi} from "react-countdown";
import Layout from "../../components/common/Layout";
import Button from "../../components/styled/buttons/Button";
import LoadingContainer from "../../components/styled/LoadingContainer";
import LoadingSpinner from "../../components/styled/LoadingSpinner";
import Divider from "../../components/styled/Divider";
import FooterButtonContainer from "../../components/styled/buttons/FooterButtonContainer";
import AlertDialog from "../../components/common/dialog/AlertDialog";
import FXAmountSection from "../../components/order/FXAmountSection";
import AgreementSection from "../../components/order/confirmation/AgreementSection";
import OrderDataList from "../../components/order/confirmation/OrderDataList";
import {FXOrderType} from "../../types/fx.type";
import {
    DEFAULT_FX_ORDER_TIMEOUT,
    FX_TRADE_CURRENCY_TYPE,
} from "../../constants/fx.constant";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {selectUser} from "../../slice/user";
import {selectCurrencies, selectFXOrderForm, selectCurrentOrPrevOrderId, updateCurrentOrPrevOrderId} from "../../slice/fx";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {formatURL} from "../../formatters/common";
import {validateOrderForm} from "../../formatters/fx/order";
import {formatCreateFXOrderPayload} from "../../formatters/fx/api/request";
import {formatCreateFXOrder} from "../../formatters/fx/api/response";
import fxAPI from "../../api/fx.api";
import {
    MARKET_ORDER_CREATE_ORDER_FAIL, MARKET_ORDER_CREATE_ORDER_MISSING_DATA, REJECT_QUOTE_USER_LEAVE_PAGE
} from "../../constants/errorMsg.constant";
import {processApiError} from "../../utils/errorHandling.utils";
import {useSnackbar} from "notistack";
import {ErrorObjForToastBar} from "../../types/errorObjType.type";
import {cleanRET_APPandRET_DCCookie} from "../../utils/cleanCookieRET_APPandRET_DCCookie";
import {selectAppTriggerCloseApp} from "../../slice/app";


const ScrollableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const CountdownMessage = styled.div`
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: var(--font-weight-semi-light);
    text-align: center;
    line-height: 20px;
`;

const CountdownTimer = styled.span`
    font-weight: var(--font-weight-semi-bold);
`;

export interface confirmationMethods {
    closeSnackbar: () => void
}

const OrderConfirmationPage = forwardRef((props: any, ref) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const navigate = useNavigateThrottle();
    const params = useParams();
    const intl = useIntl();
    const user = useAppSelector(selectUser);
    const currencies = useAppSelector(selectCurrencies);
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    const dispatch = useAppDispatch();
    const [fxOrder, setFXOrder] = useState<FXOrderType | null>(null);
    const [isCreatingOrder, setIsCreatingOrder] = useState<boolean>(
        !fxOrderForm.future
    ); // Market order ONLY
    const [isTNCChecked, setIsTNCChecked] = useState<boolean>(false); // Always use local state because it should reset everytime
    const [isTimeoutAlertDialogOpen, setIsTimeoutAlertDialogOpen] =
        useState<boolean>(false);
    const [countdownTimeoutTime, setCountdownTimeoutTime] = useState<number>(
        Date.now() + DEFAULT_FX_ORDER_TIMEOUT
    );
    const isInitialized = useRef<boolean>(false);
    const countdownAPIRef = useRef<CountdownApi | null>(null);
    const currentOrPrevOrderId = useAppSelector(selectCurrentOrPrevOrderId)
    const appTriggerCloseApp = useAppSelector(selectAppTriggerCloseApp)
    const shouldBlock = useCallback<BlockerFunction>(
        ({currentLocation, nextLocation}) => {
            if (!matchPath({path: "/order/place"}, nextLocation.pathname)) {
                // Cancel order if user is trying to leave
                if (fxOrder && currentOrPrevOrderId)
                    fxAPI
                        .cancelOrder(fxOrder.orderId, REJECT_QUOTE_USER_LEAVE_PAGE)
                        .then(()=>{
                            dispatch(updateCurrentOrPrevOrderId(""))
                        })
                        .catch((error) => console.log(error));
            }
            return false;
        },
        [fxOrder]
    );
    useBlocker(shouldBlock);

    useEffect(() => {
        // ONLY create order when first load if this is a market order
        if (!fxOrderForm.future) createOrder();

        return () => {
            // Pause the count down timer, preventing the alert dialog will be shown in another page
            // console.log('pause timer')
            countdownAPIRef.current?.pause();
            closeSnackbar()
        };
    }, []);

    useImperativeHandle(ref, () => ({
        closeSnackbar,
    }));

    useEffect(() => {
        // Skip first load
        if (isInitialized.current) {
            countdownAPIRef.current?.start();
        } else isInitialized.current = true;
    }, [countdownTimeoutTime]);

    useEffect(():void=>{
        countdownAPIRef.current?.pause();
    },[appTriggerCloseApp])


    /**
     * Sets the countdown API reference.
     *
     * @param {Countdown | null} countdown - The Countdown instance or null.
     *
     * @returns {void}
     */
    const setCountdownAPIRef = (countdown: Countdown | null) => {
        if (countdown) {
            countdownAPIRef.current = countdown.getApi();
        }
    };

    // Make API call to create order (only call if it is market order)
    /**
     * Create an FX order asynchronously.
     *
     * @async
     * @function createOrder
     * @returns {Promise<void>} A promise that resolves when the order is created.
     * @throws {ErrorObjForToastBar} If the order creation fails or data is missing.
     */


    const createOrder = async () => {
        const formValidationErrors = validateOrderForm(fxOrderForm, currencies);
        if (formValidationErrors.length > 0) return;

        setIsCreatingOrder(true); // show loading indicator

        try {
            const response = await fxAPI.createOrder(
                formatCreateFXOrderPayload(fxOrderForm)
            );
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) throw {
                errorMsg: MARKET_ORDER_CREATE_ORDER_FAIL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
            if (
                !response.data.data.contraAmount ||
                !response.data.data.orderId ||
                !response.data.data.orderTag ||
                !response.data.data.quote ||
                (!response.data.data.quoteTimeout && response.data.data.spotDps!==0) ||
                (!response.data.data.spotDps && response.data.data.spotDps!==0) ||
                !response.data.data.tradeDate
            ) throw {
                errorMsg: MARKET_ORDER_CREATE_ORDER_MISSING_DATA,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar

            const formattedFXOrder = formatCreateFXOrder(response.data.data);
            setFXOrder(formattedFXOrder);
            dispatch(updateCurrentOrPrevOrderId(formattedFXOrder.orderId))
            setCountdownTimeoutTime(Date.now() + formattedFXOrder.timeout);
            setIsCreatingOrder(false);
        } catch (error: any) {
            processApiError(error, () => navigate(-1))
        }
    };

    /**
     * Handles the click event for the account button.
     *
     * Navigates back to the previous page.
     *
     * @function
     * @name handleAccountClick
     * @returns {void}
     */
    const handleAccountClick = () => {
        navigate(-1);
    };

    /**
     * Function called when the countdown is complete.
     * Sets the `isTimeoutAlertDialogOpen` state to `true`.
     *
     * @function
     * @name handleCountdownComplete
     * @returns {void}
     */
    const handleCountdownComplete = () => {
        setIsTimeoutAlertDialogOpen(true);
    };

    /**
     * Updates the TNC check status.
     *
     * @param {boolean} checked - The new TNC check status.
     *
     * @returns {void}
     */
    const handleTNCCheck = (checked: boolean) => {
        setIsTNCChecked(checked);
    };

    /**
     * Checks if confirmation is possible.
     *
     * @returns {boolean} True if confirmation is possible, otherwise false.
     */
    const ableToConfirm = (): boolean => {
        if (!isTNCChecked) return false;

        if (!fxOrderForm.future) {
            if (!fxOrder) return false;
        }
        return true;
    };

    /**
     * Handle the click event for confirm button.
     *
     * This function checks the type of order and navigates the user to the appropriate URL.
     *
     * If it is a market order, the user is navigated to the URL formatted with the orderId from fxOrder.
     * If it is a future order, the user is navigated to the URL formatted with a hardcoded order id.
     *
     * @returns {void}
     */
    const handleConfirmClick = () => {
        if (!fxOrderForm.future) {
            //If it is market order
            if (!fxOrder) return;
            navigate(formatURL(`/order/place`, {id: fxOrder.orderId}));
        } else {
            navigate(formatURL(`/order/place` ));
            //If it is future order
        }
    };

    /**
     * Function to handle the closing of a timeout alert dialog.
     *
     * @param {Object} event - The event object.
     * @param {"backdropClick" | "escapeKeyDown"} reason - The reason for closing the dialog.
     * - backdropClick: The dialog was closed due to a backdrop click.
     * - escapeKeyDown: The dialog was closed by pressing the escape key.
     *
     * @returns {void}
     */
    const handleTimeoutAlertDialogClose = (
        event: {},
        reason: "backdropClick" | "escapeKeyDown"
    ) => {
        if (reason === "backdropClick") return;

        setIsTimeoutAlertDialogOpen(false);
        navigate(-2); // Go back to step 1
    };

    /**
     * Handles the confirmation of the timeout alert dialog.
     * Sets the value of `isTNCChecked` to `false`, closes the timeout alert dialog by setting `isTimeoutAlertDialogOpen` to `false`,
     * and calls the `createOrder` function.
     */
    const handleTimeoutAlertDialogConfirm = () => {
        setIsTNCChecked(false);
        setIsTimeoutAlertDialogOpen(false);
        createOrder();
    };

    // Show empty page if target currency/FX/bank accounts is not set either
    if (
        !fxOrderForm.target.currency ||
        !fxOrderForm.fx ||
        !fxOrderForm.bankAccount.source ||
        !fxOrderForm.bankAccount.target
    ) {
        return null;
    }

    return (

        <Layout
            title={intl.formatMessage({
                id: "app.page.orderConfirmation.header.title",
            })}

        >
            <Helmet>
                <title>
                    {intl.formatMessage({
                        id: "app.page.orderConfirmation.header.title",
                    })}
                </title>
            </Helmet>
            {isCreatingOrder ? (
                <LoadingContainer>
                    <LoadingSpinner display="block" $centered/>
                </LoadingContainer>
            ) : (
                <>
                    <ScrollableContainer>
                        <FXAmountSection
                            page="confirmation"
                            source={{
                                currency: fxOrderForm.source.currency,
                                amount:
                                    fxOrderForm.selectingCurrencyType !==
                                    FX_TRADE_CURRENCY_TYPE.SOURCE && fxOrder
                                        ? fxOrder.contraAmount
                                        : fxOrderForm.source.amount.value,
                            }}
                            target={{
                                currency: fxOrderForm.target.currency,
                                amount:
                                    fxOrderForm.selectingCurrencyType !==
                                    FX_TRADE_CURRENCY_TYPE.TARGET && fxOrder
                                        ? fxOrder.contraAmount
                                        : fxOrderForm.target.amount.value,
                            }}
                            selectingCurrencyType={fxOrderForm.selectingCurrencyType}
                            fxObject={fxOrderForm.fx}
                            isLoading={isCreatingOrder}
                        />
                        {!fxOrderForm.future && (
                            <CountdownMessage>
                                {intl.formatMessage(
                                    {
                                        id: "app.page.orderConfirmation.message.countdown",
                                    },
                                    {
                                        timer: (
                                            <Countdown
                                                ref={setCountdownAPIRef}
                                                date={countdownTimeoutTime}
                                                autoStart={false}
                                                renderer={({minutes, seconds}) => (
                                                    <CountdownTimer>{`${minutes
                                                        .toString()
                                                        .padStart(2, "0")}:${seconds
                                                        .toString()
                                                        .padStart(2, "0")}`}</CountdownTimer>
                                                )}
                                                onComplete={handleCountdownComplete}
                                            />
                                        ),
                                    }
                                )}
                            </CountdownMessage>
                        )}
                        <OrderDataList
                            source={{
                                currency: fxOrderForm.source.currency,
                                amount:
                                    fxOrderForm.selectingCurrencyType !==
                                    FX_TRADE_CURRENCY_TYPE.SOURCE && fxOrder
                                        ? fxOrder.contraAmount
                                        : fxOrderForm.source.amount.value,
                            }}
                            target={{
                                currency: fxOrderForm.target.currency,
                                amount:
                                    fxOrderForm.selectingCurrencyType !==
                                    FX_TRADE_CURRENCY_TYPE.TARGET && fxOrder
                                        ? fxOrder.contraAmount
                                        : fxOrderForm.target.amount.value,
                            }}
                            accounts={fxOrderForm.bankAccount}
                            fxObject={
                                fxOrder
                                    ? {
                                        ...fxOrderForm.fx,
                                        bid: fxOrder.rate,
                                        ask: fxOrder.rate,
                                    }
                                    : fxOrderForm.fx
                            }
                            fee={fxOrderForm.fee}
                            onAccountClick={handleAccountClick}
                        />
                        <Divider
                            margin={{
                                top: 24,
                                bottom: 24,
                                left: "var(--common-layout-padding-x)",
                                right: "var(--common-layout-padding-x)",
                            }}
                        />
                        <AgreementSection
                            isTNCChecked={isTNCChecked}
                            onTNCCheck={handleTNCCheck}
                        />
                    </ScrollableContainer>
                    <FooterButtonContainer>
                        <Button
                            theme="primary"
                            size="lg"
                            disabled={!ableToConfirm()}
                            onClick={handleConfirmClick}
                        >
                            {intl.formatMessage({
                                id: "app.page.orderConfirmation.button.confirm",
                            })}
                        </Button>
                    </FooterButtonContainer>
                </>
            )}
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.orderConfirmation.dialog.timeout.title",
                })}
                contentComp={intl.formatMessage({
                    id: "app.page.orderConfirmation.dialog.timeout.content",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.orderConfirmation.dialog.timeout.button.ok",
                })}
                open={isTimeoutAlertDialogOpen}
                onClose={handleTimeoutAlertDialogClose}
                onConfirm={handleTimeoutAlertDialogConfirm}
            />
        </Layout>
    );
})

export default OrderConfirmationPage;
