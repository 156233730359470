import { useEffect, useRef, useState } from "react";
import { debounce } from "@mui/material";

const useInfiniteScroll = (
	callback: () => void,
	hasMore: boolean,
	threshold = 0
): [
	React.MutableRefObject<any>,
	boolean,
	React.Dispatch<React.SetStateAction<boolean>>
] => {
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const ref = useRef<any>();

	useEffect(() => {
		if (!isFetching || !hasMore) return;
		callback();
	}, [isFetching]);

	useEffect(() => {
		if (ref.current) {
			ref.current.addEventListener("scroll", debouncedHandleScroll);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener("scroll", debouncedHandleScroll);
			}
		};
	}, [ref.current, hasMore]);

	/**
	 * Handles scroll event for a given element.
	 * If the element is scrolled to the bottom,
	 * and has more content to load, it sets isFetching to true.
	 *
	 * @function handleScroll
	 * @returns {void}
	 */
	const handleScroll = () => {
		if (ref.current) {
			const atBottom =
				ref.current.clientHeight +
					Math.ceil(ref.current.scrollTop) +
					threshold >=
				ref.current.scrollHeight;
			if (
				!atBottom ||
				ref.current.scrollHeight <= ref.current.clientHeight ||
				!hasMore
			)
				return;

			setIsFetching(true);
		}
	};

	const debouncedHandleScroll = debounce(handleScroll, 100);

	return [ref, isFetching, setIsFetching];
};

export default useInfiniteScroll;
