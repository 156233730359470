import {RateAlertTabEnum} from "../enums/RateAlertTabEnum";

export interface RateAlertInfo {
    rateAlertStatus:RateAlertTabEnum
    CCY1:string
    CCY2: string,
    expiryDate: string,
    triggerDate: string,
    fxRate: string
}

export function isRateAlertInfo(item: any): item is RateAlertInfoReal {
    return 'alertStatus' in item; // Replace 'uniqueProperty' with the actual property that identifies the interface
}

export interface RateAlertInfoReal{
    alertStatus: string;
    symbol: string;
    country: string;
    cancelDate: string;
    alertType: number;
    purpose: string;
    dealtCurr: string;
    digitalId: string;
    buy: boolean;
    tradeDate: null | string;
    valueDate: string;
    takeProfit: number;
    inputDate: string;
    tenor: string;
    quote: null | string;
    rate: null | string;
    alertTag: string;
    cancelUser: string;
    limit: string;
    expireDate: string;
    alertId: string;
    alertState: number;
    cust_tier: string;
    account: string;
    dp:number,
    uq:number
}

export interface CreateRateAlertAPIRequestType{
    type: "limit";
    symbol: string;
    buy: boolean;
    currency: string;
    limit: number;
    expire: string;
}
