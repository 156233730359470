import { DEFAULT_NUMPAD_INPUT_DECIMAL_POINT } from "../../../../constants/common.constant";
import { FormError } from "../../../../types/common.type";
import { CurrencyType } from "../../../../types/fx.type";
import { formatNumber } from "../../../../utils/common.utils";
import { getIntlObject } from "../../../../utils/intl";
import getCurrencyAmountBoundaryByOrderType from "../getCurrencyAmountBoundaryByOrderType";

/**
 * Validate FX order form amount
 * @param {number} amount - The amount.
 * @param {string} currencyText - The currency symbol of amount.
 * @param {Array<CurrencyType>} currencies - The currency list from API.
 * @param {boolean} future - Is it future order.
 * @param {{isContainMin: boolean, isContainMax: boolean}} options - Use [less/greater than] OR [less/greater than or equal to]
 * @return {Array<FormError>} Error list.
 */
const validateAmount = (
	amount: number,
	currencyText: string,
	currencies: CurrencyType[],
	future: boolean,
	options?: { isContainMin?: boolean; isContainMax?: boolean }
): FormError[] => {
	const intl = getIntlObject();
	const errors: FormError[] = [];
	const currency = currencies.find((v) => v.currency === currencyText);

	if (!currency)
		return [
			{
				field: "amount",
				message: intl.formatMessage({ id: "app.common.message.error" }),
			},
		];

	if (
		(options?.isContainMin &&
			amount < getCurrencyAmountBoundaryByOrderType(currency, "min", future)) ||
		(!options?.isContainMin &&
			amount < getCurrencyAmountBoundaryByOrderType(currency, "min", future))
	)
		errors.push({
			field: "amount",
			message: intl.formatMessage(
				{
					id: "app.page.orderForm.form.order.field.amount.message.error.minAmount",
				},
				{
					currency: currency.currency,
					amount: formatNumber(
						getCurrencyAmountBoundaryByOrderType(currency, "min", future),
						DEFAULT_NUMPAD_INPUT_DECIMAL_POINT
					),
				}
			),
		});

	if (
		(options?.isContainMax &&
			amount > getCurrencyAmountBoundaryByOrderType(currency, "max", future)) ||
		(!options?.isContainMax &&
			amount > getCurrencyAmountBoundaryByOrderType(currency, "max", future))
	)
		errors.push({
			field: "amount",
			message: intl.formatMessage(
				{
					id: "app.page.orderForm.form.order.field.amount.message.error.maxAmount",
				},
				{
					currency: currency.currency,
					amount: formatNumber(
						getCurrencyAmountBoundaryByOrderType(currency, "max", future),
						DEFAULT_NUMPAD_INPUT_DECIMAL_POINT
					),
				}
			),
		});

	return errors;
};

export default validateAmount;
