import { formatFXSymbolToCurrencies } from "../../..";
import {DEFAULT_FX_PRICE_DECIMAL_POINT, DEFAULT_FX_PRICE_UNIT_QUOTE} from "../../../../../constants/fx.constant";
import { FXType } from "../../../../../types/fx.type";

/**
 * Format FX symbol list API response
 * @param {Array<string>} response - The API response FX symbol list
 * @return {Array<FXType>} The formatted array of FX object.
 */
const formatFXRawList = (response: string[]): FXType[] => {
	let formatted: FXType[] = [];

	for (let i = 0; i < response.length; i++) {
		formatted[i] = {
			symbol: response[i],
			currencies: formatFXSymbolToCurrencies(response[i]),
			bid: null,
			ask: null,
			decimalPoint: DEFAULT_FX_PRICE_DECIMAL_POINT,
			unitQuote: DEFAULT_FX_PRICE_UNIT_QUOTE,
		};
	}

	return formatted;
};

export default formatFXRawList;
