import Lottie, { LottieProps } from "react-lottie-player";
import styled from "styled-components";
import animationData from "../../assets/animations/loading.json";
import { CSSProperties, useEffect, useState } from "react";

interface MaeLoadingProps
	extends Omit<LottieProps, "animationData" | "renderer"> {
	size?: number | string;
	centered?: boolean;
}

const MaeLoadingContainer = styled.div<MaeLoadingProps>`
	display: flex;
	${({ centered }) => centered && `justify-content: center;`}
`;

const DEFAULT_MAE_LOADING_ANIMATION_OPTIONS = {
	loop: true,
	play: true,
	animationData: animationData,
};

const MaeLoading = ({ loop, play, size, centered }: MaeLoadingProps) => {
	const [loadingStyle, setLoadingStyle] = useState<CSSProperties | undefined>({
		flexBasis: size,
	});

	useEffect(() => {
		setLoadingStyle({ flexBasis: size });
	}, [size]);

	return (
		<MaeLoadingContainer centered={centered}>
			<Lottie
				{...DEFAULT_MAE_LOADING_ANIMATION_OPTIONS}
				play={play ?? DEFAULT_MAE_LOADING_ANIMATION_OPTIONS.play}
				loop={loop ?? DEFAULT_MAE_LOADING_ANIMATION_OPTIONS.loop}
				style={loadingStyle}
			/>
		</MaeLoadingContainer>
	);
};

export default MaeLoading;
