import { UserAPIResponseType, UserType } from "../../../../../types/user.type";

/**
 * Format auth API response
 * @param {UserAPIResponseType} response - Auth API response.
 * @return {Pick<UserType, "type" | "tokens" | "baseCurrency" | "fromCurrency">} The formatted user basic information object.
 */
const formatUserBasicInformation = (
	response: UserAPIResponseType
): Pick<UserType, "type" | "tokens" | "baseCurrency" | "fromCurrency"> => {
	return {
		type: response.type,
		tokens: {
			market: {
				token: response.mkttoken,
				expire: response.mkttokenexp,
			},
		},
		baseCurrency: response.baseCurrency,
		fromCurrency: response.fromCurrency,
	};
};

export default formatUserBasicInformation;
