import { useState } from "react";
import { useIntl } from "react-intl";
import { Drawer } from "@mui/material";
import styled from "styled-components";

import Anchor from "../styled/Anchor";

import { contactBank } from "../../utils/integration.utils";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface AppMenuProps {
	open: boolean;
	onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
	}
`;

const DrawerHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: calc(24px + constant(safe-area-inset-top));
	padding-top: calc(24px + env(safe-area-inset-top));
	padding-right: 24px;
	padding-left: 24px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
`;

const DrawerContent = styled.div`
	padding: 20px 24px 24px;
	overflow-x: hidden;
`;

const AppMenuListItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: var(--font-weight-semi-bold);
`;

const AppMenuList = styled.div`
	margin-bottom: 36px;

	${AppMenuListItem} {
		margin-top: 8px;
		margin-bottom: 8px;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const AppMenu = ({ open, onClose }: AppMenuProps) => {
	const intl = useIntl();

	/**
	 * Function to handle contact bank event.
	 *
	 * @param {React.MouseEvent} event - The mouse event object.
	 * @returns {void}
	 */
	const handleContactBank = (event: React.MouseEvent) => {
		contactBank();
		onClose(event);
	};

	return (
		<StyledDrawer anchor="top" open={open} onClose={onClose}>
			<DrawerHeader>
				<CloseIcon className="mbb-icon" onClick={onClose} />
			</DrawerHeader>
			<DrawerContent>
				<AppMenuList>
					<AppMenuListItem>
						<Anchor onClick={handleContactBank}>
							{intl.formatMessage({
								id: "app.menu.item.contactBank",
							})}
						</Anchor>
					</AppMenuListItem>
				</AppMenuList>
			</DrawerContent>
		</StyledDrawer>
	);
};

export default AppMenu;
