import styled from "styled-components";

interface HeaderTitleProps {
	hasMarginBottom?: boolean;
	textWrap?: boolean;
}

const HeaderTitle = styled.div<HeaderTitleProps>`
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--section-header-margin-bottom);
	color: var(--section-header-text-color);
	font-size: var(--section-header-font-size);
	font-weight: var(--section-header-font-weight);

	${({ textWrap }) => !textWrap && `align-items: center;`}

	${({ hasMarginBottom }) =>
		hasMarginBottom &&
		`
		margin-bottom: 8px;
	`}

	
    ${({ textWrap }) =>
		textWrap &&
		`
        overflow: visible;
        white-space: normal;
        text-overflow: clip;
        word-break: break-word;
    `}
`;

export default HeaderTitle;
