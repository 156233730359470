import * as React from 'react';
import Layout from "../../components/common/Layout";
import styled from "styled-components";
import {useIntl} from "react-intl";
import BorderedSelect, {StyledBorderedSelect} from "../../components/styled/BorderedSelect";
import {COUNTRY_CURRENCY_MAP,} from "../../constants/fx.constant";
import NationalFlag from "../../components/common/NationalFlag";
import FieldLabel from "../../components/styled/FieldLabel";
import {IconWrapper, LabelNation, NationWrapper, SelectTextWrapper} from "../currency-calculator";
import {ReactComponent as SwapArrowIcon} from "../../assets/icons/swap-arrow.svg";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    selectFXRateAlert, selectHideCreateRateAlertItems, swapRateAlertFromAndTo, updateRateAlertExpiryDate,
} from "../../slice/fx";
import {useEffect, useRef, useState} from "react";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {formatURL} from "../../formatters/common";
import {fetchRateForRateAlert} from "../order/services/fetchRate";
import {DatePickerDrawer} from "../../components/order/CalendarFutureOrder/DatePickerDrawer";
import InputField from "../../components/common/InputField";
import {convertDateStringToMalaysiaTime} from "../../components/order/FutureOrderFormFields";
import * as moment from "moment-timezone";
import {DEFAULT_DATE_PLUS_N, DEFAULT_DATE_PLUS_N_RATE_ALERT} from "../../constants/futureCalendar.constant";
import Button from "../../components/styled/buttons/Button";
import {FutureInputField} from "../../components/order/FutureOrderInput/FutureInputField/FutureInputField";
import CalculationCardForRateAlert from "./components/CalculationCardForRateAlert";
import {enqueueSnackbar} from "notistack";
import fxAPI from "../../api/fx.api";
import {CreateRateAlertAPIRequestType} from "./interface/RateAlertInfo";
import {formatDateTime} from "../order/services/formatDateTime";
import {CreateRateAlertStepOne} from "./CreateRateAlertStepOne";
import {CreateRateAlertStepTwo} from "./CreateRateAlertStepTwo";
import {Helmet} from "react-helmet";
import {
    onEnterAndUnfocusToCheckBound
} from "../../components/order/FutureOrderInput/FutureInputField/services/onEnterAndUnfocusToCheckBound";
import {getRateByDealt} from "../../formatters/fx";
import {formatCurrencyPairAddSlash} from "../../formatters/fx/formatCurrencyPairAddSlash";
import {

    CREATE_RATE_ALERT_RETURN_UNSUCCESSFUK,
    RESPONSE_ERROR_OR_USER_DATA_INVALID
} from "../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../types/errorObjType.type";
import {processApiError} from "../../utils/errorHandling.utils";
import {countActiveRateAlertByAPI} from "./services/countActveRateAlertByAPI";
import {selectRaMaxActiveCount} from "../../slice/app";
import {emitErrorToApp} from "../../utils/integration.utils";

type Props = {};
export const CreateRateAlertStepThree = (props: Props) => {
    const navigate = useNavigateThrottle();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const fxRateAlert = useAppSelector(selectFXRateAlert)
    const isHideDueToError = useAppSelector(selectHideCreateRateAlertItems)
    const [hasAdjustDate, setHasAdjustDate] = useState<boolean>(false)
    const [isSelectFromCcyOpen, setIsSelectFromCcyOpen] = useState<boolean>(false)
    const defaultRate: number = (getRateByDealt(fxRateAlert.source.currency, fxRateAlert.fx) as number) ?
        (getRateByDealt(fxRateAlert.source.currency, fxRateAlert.fx) as number) : 888;
    const rateInputRef = useRef<HTMLInputElement>(null);
    const raMaxActiveCount = useAppSelector(selectRaMaxActiveCount)
    const handleSelectFromCcyOpen = (open: boolean) => {
        if (open) {
            setIsSelectFromCcyOpen(open)
        } else {
            setTimeout(() => setIsSelectFromCcyOpen(open), 200)
        }
    }

    const [isSelectToCcyOpen, setIsSelectToCcyOpen] = useState<boolean>(false)
    const handleSelectToCcyOpen = (open: boolean) => {
        if (open) {
            setIsSelectToCcyOpen(open)
        } else {
            setTimeout(() => setIsSelectToCcyOpen(open), 200)
        }
    }

    const [canClick, setCanClick] = useState(true);

    useEffect(() => {
        if (!(fxRateAlert.source.currency && fxRateAlert.target.currency)) return
        fetchRateForRateAlert(fxRateAlert)
    }, [fxRateAlert.source.currency, fxRateAlert.target.currency]);

    useEffect(() => {
        if (!fxRateAlert.expiryDate) {
            console.log("init fxRateAlert.expiryDate")
            const dateInMY = moment.tz("Asia/Kuala_Lumpur");
            dateInMY.add(DEFAULT_DATE_PLUS_N_RATE_ALERT, 'days');
            dispatch(
                updateRateAlertExpiryDate(dateInMY.format())
            )
        }
    }, []);

    // useEffect(() => {
    //     const handleRateInputFocus = () => {
    //         onRateInputRefChange(rateInputRef.current as Element);
    //     };
    //
    //     rateInputRef.current?.addEventListener("focusin", handleRateInputFocus);
    //
    //     return () => {
    //         rateInputRef.current?.removeEventListener(
    //             "focusin",
    //             handleRateInputFocus
    //         );
    //     };
    // }, [rateInputRef.current]);

    useEffect(() => {
        let isTouchInsideInput = false;

        const handleTouchStart = (event: TouchEvent) => {
            console.log("touchStart")
            if (rateInputRef.current && rateInputRef.current.contains(event.target as Node)) {
                isTouchInsideInput = true; // Touch started inside the input field
            } else {
                isTouchInsideInput = false; // Touch started outside the input field
                rateInputRef.current?.blur(); // Blur the input to hide the numpad
            }
        };

        const handleTouchMove = (event: TouchEvent) => {
            console.log("touchMove")

            console.log('this should blur')
            rateInputRef.current?.blur(); // Blur the input if touch moves outside the input

        };

        document.addEventListener('touchstart', handleTouchStart, {passive: true});
        document.addEventListener('touchmove', handleTouchMove, {passive: true});

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    const handleCurrencyFromSelectClick = () => {
        handleSelectFromCcyOpen(true)
        // navigate(formatURL("/rateAlert/create/step1"))
    }
    const handleCurrencyToSelectClick = () => {
        handleSelectToCcyOpen(true)
        // navigate(formatURL("/rateAlert/create/step2"))
    }
    const [isDatePickerDrawerOpen, setIsDatePickerDrawerOpen]
        = useState<boolean>(false)

    const handleDrawerClose = () => {
        setIsDatePickerDrawerOpen(false)
    }

    const handleDatePicked = (expiryDate: Date) => {
        dispatch(
            updateRateAlertExpiryDate(expiryDate.toDateString())
        )
        setHasAdjustDate(true)
        //todo dispatch to rateAlert date
    }

    const handleCreateRateAlertButton = async () => {
        try {
            const activeRateAlert = await countActiveRateAlertByAPI()
            if (activeRateAlert) {
                if (activeRateAlert >= raMaxActiveCount) {
                    enqueueSnackbar(
                        intl.formatMessage({
                            id: "app.page.rateAlert.delete.toastbar.reachedMaxNumAlert",
                        }), {
                            variant: "general",
                            mode: 'info',
                        }
                    )
                    return
                }
            }
        } catch (error) {
            processApiError(error) //this is not fatal
            return
        }

        if (!canClick) return; // If canClick is false, ignore the click

        setCanClick(false); // Disable further clicks

        const createRateAlertAPIRequest: CreateRateAlertAPIRequestType = {
            type: "limit",
            symbol: fxRateAlert.fx!.symbol as string,
            buy: false,
            currency: fxRateAlert.source.currency,
            limit: Number(fxRateAlert.futureTargetRate),
            expire: formatDateTime(fxRateAlert.expiryDate as string)
        }
        try {
            const response = await fxAPI.createRateAlert(createRateAlertAPIRequest)
            if (
                !response.data ||
                response.data.responseCode !== "0"
            )
                throw {
                    errorMsg: CREATE_RATE_ALERT_RETURN_UNSUCCESSFUK,
                    responseHttpStatus: response.status,
                    responseData: response.data

                } as ErrorObjForToastBar
            navigate(formatURL("/rateAlert/view"))
            enqueueSnackbar(
                intl.formatMessage(
                    {
                        id: "app.page.createRateAlert.success",
                        defaultMessage: "You've created a {currencyPair} rate alert. We'll notify you once your desired rate is reached.",
                    },
                    {
                        currencyPair: formatCurrencyPairAddSlash(fxRateAlert.fx!.symbol, fxRateAlert.source.currency),
                    }
                ),
                {variant: "general", mode: "success"}
            );
        } catch (error) {
            processApiError(error, undefined) //this is non-FATAL
            setCanClick(true);
        }
    }

    const isButtonDisabled: boolean =
        !!fxRateAlert &&
        !!fxRateAlert.source &&
        !!fxRateAlert.source.currency &&
        !!fxRateAlert.target &&
        !!fxRateAlert.target.currency &&
        !!fxRateAlert.expiryDate &&
        !!fxRateAlert.futureTargetRate &&
        !isHideDueToError &&
        hasAdjustDate

    const isCalculationShown: boolean =
        !!fxRateAlert.source &&
        !!fxRateAlert.source.currency &&
        !!fxRateAlert.target &&
        !!fxRateAlert.target.currency &&
        !isHideDueToError
    return (
        <Layout
            title={""}
        >
            <Helmet>
                <title>
                    Create Rate Alert step-3
                </title>
            </Helmet>
            <RateAlertInfoContainer>
                <TextInfoContainer>
                    <SubtitleText style={{paddingBottom: 4, fontSize: 14}}>
                        {intl.formatMessage({id: "app.page.createRateAlert.title"})}
                    </SubtitleText>
                    <div style={{fontSize: 16, fontWeight: 600}}>
                        {intl.formatMessage({id: "app.page.createRateAlert.intro"})}
                    </div>
                </TextInfoContainer>
                <Label>
                    {intl.formatMessage({
                        id: "app.page.currencyCalculator.from",
                    })}
                </Label>
                <BorderedSelect
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleCurrencyFromSelectClick();
                    }}
                >
                    {fxRateAlert.source.currency ?
                        <NationWrapper>
                            <NationalFlag
                                country={
                                    COUNTRY_CURRENCY_MAP[
                                        fxRateAlert.source.currency.toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
                                        ]
                                }
                                width={24}
                                height={24}
                            />
                            <LabelNation>
                                {`${fxRateAlert.source.currency.toUpperCase()} - ${
                                    fxRateAlert.source.name
                                }`}
                            </LabelNation>
                        </NationWrapper> : (
                            <SelectTextWrapper>
                                {intl.formatMessage({
                                    id: "app.page.currencyCalculator.selectCurrency",
                                })}
                            </SelectTextWrapper>
                        )}
                </BorderedSelect>
                <IconWrapper>
                    <SwapArrowIcon onClick={() => {
                        dispatch(swapRateAlertFromAndTo())
                    }}/>
                </IconWrapper>
                <Label>
                    {intl.formatMessage({
                        id: "app.page.currencyCalculator.to",
                    })}
                </Label>
                <BorderedSelect
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleCurrencyToSelectClick();
                    }}
                >
                    {fxRateAlert.target.currency ? (
                        <NationWrapper>
                            <NationalFlag
                                country={
                                    COUNTRY_CURRENCY_MAP[
                                        fxRateAlert.target.currency.toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
                                        ]
                                }
                                width={24}
                                height={24}
                            />
                            <LabelNation>
                                {`${fxRateAlert.target.currency.toUpperCase()} - ${
                                    fxRateAlert.target.name
                                }`}
                            </LabelNation>
                        </NationWrapper>
                    ) : (
                        <SelectTextWrapper>
                            {intl.formatMessage({
                                id: "app.page.currencyCalculator.selectCurrency",
                            })}
                        </SelectTextWrapper>
                    )}
                </BorderedSelect>
                {isCalculationShown
                    &&
                    <div>
                        <CalculationCardForRateAlert
                            source={fxRateAlert.source}
                            fxObject={fxRateAlert.fx}
                        />
                        <Label style={{marginTop: 17, marginBottom: 17}}>
                            {intl.formatMessage({
                                id: "app.page.createRateAlert.alertMeWhen",
                            })}
                        </Label>

                        <FutureInputField
                            fullWidth
                            inputProps={{
                                type: "text",
                                ref: rateInputRef,
                                readOnly: false,
                                onClick: () => {
                                    const numpadMain = document.querySelector('.numpad-main');
                                    rateInputRef.current!.scrollIntoView({behavior: 'auto',});
                                    setTimeout(() => {
                                        rateInputRef.current!.scrollIntoView({behavior: 'auto',});
                                    }, 200)
                                    if (numpadMain) {
                                        // @ts-ignore
                                        numpadMain.style.setProperty('display', 'none', 'important');
                                    } else {
                                        console.log('Element not found');
                                    }
                                },
                            }}
                            helperText={"fxOrderForm.source.amount.helper?.text"}
                            helperTextProps={{}}
                            fxDataForm={fxRateAlert}
                        />
                        <Label style={{marginTop: 23, marginBottom: 17}}>
                            {intl.formatMessage({
                                id: "app.page.createRateAlert.validTill",
                            })}
                        </Label>
                        <div
                            onClick={() => {
                                setIsDatePickerDrawerOpen(true)
                            }}
                        >
                            {/*<InputField*/}
                            {/*    id={"future_date_field"}*/}
                            {/*    value={fxRateAlert.expiryDate as string ?*/}
                            {/*        convertDateStringToMalaysiaTime(fxRateAlert.expiryDate as string)*/}
                            {/*        :*/}
                            {/*        "Click to select date"}*/}
                            {/*    fullWidth*/}
                            {/*    inputProps={{*/}
                            {/*        type: "text",*/}
                            {/*        readOnly: true,*/}
                            {/*    }}*/}
                            {/*    helperText={intl.formatMessage({*/}
                            {/*        id: "app.page.orderForm.form.order.field.expiration.message.instruction",*/}
                            {/*    })}*/}
                            {/*    disableHelperText={true}*/}
                            {/*/>*/}
                            <InputField
                                id={"future_date_field"}
                                value={fxRateAlert.expiryDate as string && hasAdjustDate ?
                                    convertDateStringToMalaysiaTime(fxRateAlert.expiryDate as string)
                                    :
                                    "Tap to select end date"}
                                fullWidth
                                inputProps={{
                                    type: "text",
                                    readOnly: true,
                                }}
                                helperText={intl.formatMessage({
                                    id: "app.page.orderForm.form.order.field.expiration.message.instruction",
                                })}
                                disableHelperText={true}
                                specialColor={hasAdjustDate ? "" : "grey"}
                            />
                        </div>
                        <div style={{marginTop: 17}}>
                            <GreySmallTextBold>
                                {intl.formatMessage({id: "app.page.createRateAlert.note"})}
                            </GreySmallTextBold>
                            <GreySmallText>
                                <div style={{marginRight: 17}}>
                                    1.
                                </div>
                                <div>
                                    {intl.formatMessage({id: "app.page.createRateAlert.occurOnce"})}
                                </div>
                            </GreySmallText>
                            <GreySmallText>
                                <div style={{marginRight: 14}}>
                                    2.
                                </div>
                                <div>
                                    {intl.formatMessage({id: "app.page.createRateAlert.delays"})}
                                </div>
                            </GreySmallText>
                        </div>

                        <DatePickerDrawer
                            open={isDatePickerDrawerOpen}
                            onClose={handleDrawerClose}
                            onPick={handleDatePicked}
                            isRateAlert={true}
                        />
                    </div>
                }

            </RateAlertInfoContainer>

            <ButtonContainer>
                <Button
                    style={{width: "100%", height: 48, margin: 25}}
                    theme="primary"
                    onClick={handleCreateRateAlertButton}
                    disabled={!isButtonDisabled}
                >
                    {intl.formatMessage({
                        id: "app.page.createRateAlert.createAlert",
                    })}
                </Button>
            </ButtonContainer>

            {
                isSelectFromCcyOpen &&
                <CreateRateAlertStepOne notPage={true} onOpen={handleSelectFromCcyOpen}/>
            }
            {
                isSelectToCcyOpen &&
                <CreateRateAlertStepTwo notPage={true} onOpen={handleSelectToCcyOpen}/>
            }


        </Layout>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 128px
`

const RateAlertInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 25px;

    ${StyledBorderedSelect} {
        padding: 8px 24px;
        border: 1px solid var(--border-color);
    }
`

const TextInfoContainer = styled.div`
    padding-bottom: 36px;
`

const SubtitleText = styled.div`
    font-size: 14px;
    padding-bottom: 4px;
`

const GreySmallTextBold = styled.div`
    font-size: 12px;
    color: #7C7C7D;
    padding-bottom: 4px;
    font-weight: 600;
`

const GreySmallText = styled.div`
    font-size: 12px;
    color: #7C7C7D;
    padding-bottom: 4px;
    display: flex;
`
const Label = styled(FieldLabel)`
    margin-bottom: 8px;
`;
