// @flow
import * as React from 'react';
import styled from "styled-components";
import { OrderCardContent } from "../order/orderStatus/OrderCardContent";
import { isRateAlertInfo, RateAlertInfo } from "../rateAlert/interface/RateAlertInfo";
import { isOrderStatusInfoReal, OrderStatusInfoReal } from "../order/interface/OrderStatusInfo";
import { RateAlertCardContent } from "../rateAlert/components/RateAlertCardContent";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import formatURL from "../../formatters/common/formatURL";
import { useAppDispatch } from "../../utils/store.utils";
import { updateOrderStatusInfoReal } from "../../slice/fx";

type Props = {
    dataFilteredAfterPaginatior: OrderStatusInfoReal[]|RateAlertInfo[]
};

export const CardsContainer = (props: Props) => {
    const navigate = useNavigateThrottle();
    const dispatch = useAppDispatch();
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);

    const handleClickToGetInfo = (data: OrderStatusInfoReal) => {
        dispatch(updateOrderStatusInfoReal(data));
        navigate(formatURL(`/order/details`));
    };

    const handleScroll = () => {
        // console.log('scrolling...')

        const container = scrollContainerRef.current;
        if (container) {
            const { scrollTop, scrollHeight, clientHeight } = container;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight;
            const isAtTop = scrollTop <= 0;
            // console.log("scrollHeight", scrollHeight)
            // console.log("isAtTop", isAtTop)
            // console.log("isAtBottom", isAtBottom)

            if(isAtTop&&isAtBottom){
                return
            }
            // container.style.setProperty('--top-opacity', isAtTop ? '0' : '1');
            // container.style.setProperty('--bottom-opacity', isAtBottom ? '0' : '1');
        }
    };

    React.useEffect(() => {
        // Adding a slight delay to ensure the ref is attached
        const timer = setTimeout(() => {
            const container = scrollContainerRef.current;
            if (container) {
                container.addEventListener('scroll', handleScroll);
                handleScroll(); // Check initial state
            }
        }, 100);

        return () => {
            const container = scrollContainerRef.current;
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            clearTimeout(timer);
        };
    }, []);


    return (
        <ScrollableContainer ref={scrollContainerRef}>
            {props.dataFilteredAfterPaginatior.map((item, i) => (
                <div key={i}>
                    {isOrderStatusInfoReal(item) &&
                        <BoxCard
                            minHeight={"135px"}
                            gap={"9px 0"}
                            onClick={() => handleClickToGetInfo(item)}
                        >
                            <OrderCardContent order={item} />
                        </BoxCard>
                    }
                    {isRateAlertInfo(item) &&
                        <BoxCard
                            minHeight={"123px"}
                            gap={"12px 0"}>
                            <RateAlertCardContent rateAlert={item} />
                        </BoxCard>
                    }
                </div>
            ))}
        </ScrollableContainer>
    );
};

interface ScrollableContainerProps {
    maxHeight?: number;
}

const ScrollableContainer = styled.div<ScrollableContainerProps>`
    display: flex;
    flex-direction: column;
    max-height: ${({ maxHeight }) => maxHeight};
    overflow-y: auto;
    flex: 1 1 auto;
    position: relative;

`;

interface BoxCardProps {
    minHeight: string;
    gap: string;
}

const BoxCard = styled.div<BoxCardProps>`
    min-height: ${(props) => props.minHeight};
    margin: 10px 25px;
    background-color: white;
    border-radius: 7px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.gap};
`;
