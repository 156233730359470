import * as React from 'react';
import {SHOW_PAGE_RANGE} from "../../../constants/fx.constant";
import {PageChangeDirectionEnum} from "../interface/PageChangeDirection";

type Props = {
    firstItemNum: number,
    lastItemNum: number,
    totalNumOfItems: number
    currentPage:number
    onChangePage: (direction: number) => void
    havePageButNoButton:boolean
};
export const PageSelector = (props: Props) => {
    const isLeftArrowClickable: boolean = props.firstItemNum !== 1
    const isRightArrowClickable: boolean = props.lastItemNum < props.totalNumOfItems

    return (
        <div style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            marginBottom:0,
            marginTop:props.havePageButNoButton ? 30 : 0}}>
            <div style={{display: "flex", justifyContent: "center", height: 24}}>
                <div style={{fontSize: 14, fontWeight: 600}}>
                    <div style={{display: "flex", justifyContent: "space-between", width: 206}}>
                        <div
                            onClick={() => {
                                if (isLeftArrowClickable) {
                                    props.onChangePage(PageChangeDirectionEnum.DECREMENT)
                                }
                            }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 2L7 12.3517" stroke={isLeftArrowClickable ? "black" : "#989899"}
                                      strokeWidth="1.3" strokeMiterlimit="10"
                                      strokeLinecap="round"/>
                                <path d="M17 23.0001L7 12.6484" stroke={isLeftArrowClickable ? "black" : "#989899"}
                                      strokeWidth="1.3" strokeMiterlimit="10"
                                      strokeLinecap="round"/>
                            </svg>
                        </div>
                        {
                            SHOW_PAGE_RANGE ?
                                <div style={{display: "flex", alignItems: "center"}}>
                                    {props.firstItemNum}-{props.lastItemNum} of {props.totalNumOfItems}
                                </div> :
                                <div>{props.currentPage}</div>
                        }
                        <div
                            onClick={() => {
                                if (isRightArrowClickable) {
                                    props.onChangePage(PageChangeDirectionEnum.INCREMENT)
                                }
                            }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_17173_11362)">
                                    <path d="M6.85649 22.2876L17.144 12"
                                          stroke={isRightArrowClickable ? "black" : "#989899"}
                                          strokeWidth="1.3"
                                          strokeMiterlimit="10" strokeLinecap="round"/>
                                    <path d="M6.85649 1.71245L17.144 12"
                                          stroke={isRightArrowClickable ? "black" : "#989899"}
                                          strokeWidth="1.3"
                                          strokeMiterlimit="10" strokeLinecap="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_17173_11362">
                                        <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

