import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ErrorTimeoutType} from "../types/errorTimeout.type";
import {ONE_SECOND, POST_MESSAGE_PASSWORD_WAITING_TIME, POST_MESSAGE_RSA_WAITING_TIME} from "../constants/fx.constant";
import {useAppDispatch} from "../utils/store.utils";


const initialState: ErrorTimeoutType = {
    passwordResponseRemainTime: POST_MESSAGE_PASSWORD_WAITING_TIME,
    rsaResponseRemainTime: POST_MESSAGE_RSA_WAITING_TIME
};

/**
 * Represents the slice for managing error timeout.
 *
 * @typedef {Object} ErrorTimeoutSlice
 * @property {string} name - The name of the slice.
 * @property {*} initialState - The initial state of the slice.
 * @property {Object} reducers - The set of reducer functions.
 * @property {Function} reducers.minusPasswordErrorTimeoutByOneSec - Reducer function that subtracts one second from the password error timeout.
 * @property {Function} reducers.resetPasswordErrorTimeout - Reducer function that resets the password error timeout.
 * @property {Function} reducers.minusRsaErrorTimeoutByOneSec - Reducer function that subtracts one second from the RSA error timeout.
 * @property {Function} reducers.resetRsaErrorTimeout - Reducer function that resets the RSA error timeout.
 */
export const errorTimeoutSlice = createSlice({
    name: "errorTimeout",
    initialState,
    reducers: {
        minusPasswordErrorTimeoutByOneSec: (state) => {
            state.passwordResponseRemainTime -= ONE_SECOND
        },
        resetPasswordErrorTimeout: (state, action: PayloadAction<number>) => {
            state.passwordResponseRemainTime = action.payload
        },
        minusRsaErrorTimeoutByOneSec: (state) => {
            state.rsaResponseRemainTime -= ONE_SECOND
        },
        resetRsaErrorTimeout: (state, action: PayloadAction<number>) => {
            state.rsaResponseRemainTime = action.payload
        },
    },
});

export const {
    minusPasswordErrorTimeoutByOneSec,
    resetPasswordErrorTimeout,
    minusRsaErrorTimeoutByOneSec,
    resetRsaErrorTimeout
} = errorTimeoutSlice.actions;

export default errorTimeoutSlice.reducer;
