import moment from "moment";

export function formatDateGMTToMYT(input: string, showTime: boolean): {
    date: string,
    time: string
} {
    // Parse input string
    const date = moment.tz(input, 'YYYYMMDDHHmmss', 'GMT');

    // Convert to Malaysia Time (GMT+8)
    const malaysiaTime = date.clone().tz('Asia/Kuala_Lumpur');

    // Format date and time
    const formattedDate = malaysiaTime.format('DD MMMM YYYY');
    const formattedTime = malaysiaTime.format('hh:mm:ss A');

    // Return based on showTime flag
    if (showTime) {
        return {date: formattedDate, time: formattedTime}
    } else {
        return {date: formattedDate, time: ""};
    }
}

