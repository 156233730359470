import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import NationalFlag, { StyledNationalFlag } from "./NationalFlag";

import { COUNTRY_CURRENCY_MAP } from "../../constants/fx.constant";
import { CurrencyType } from "../../types/fx.type";

interface CurrencyItemProps {
	currencyObject: CurrencyType;
	onClick: (currencyObject: CurrencyType) => void;
}

export const StyledCurrencyItem = styled.div`
	display: flex;
	align-items: center;
	margin-left: 24px;
	margin-right: 24px;
	border-bottom: 1px solid var(--bordered-input-border-color);
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 18px;

	> ${StyledNationalFlag} {
		margin-right: 16px;
	}
`;

const CurrencyItemText = styled.div`
	flex: 1 0 auto;
`;

const CurrencyItem = ({ currencyObject, onClick }: CurrencyItemProps) => {
	const intl = useIntl();

	const handleClick = () => {
		onClick(currencyObject);
	};

	return (
		<StyledCurrencyItem onClick={handleClick}>
			<NationalFlag
				country={
					COUNTRY_CURRENCY_MAP[
						currencyObject.currency.toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				width={64}
				height={64}
			/>
			<CurrencyItemText>
				{currencyObject.currency} - {currencyObject.name}
			</CurrencyItemText>
		</StyledCurrencyItem>
	);
};

export default CurrencyItem;
