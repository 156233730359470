import { AxiosResponse } from "axios";
import {
	UserGetAccountsAPIResponseType,
	UserGetConfigAPIResponseType,
} from "../types/user.type";
import {api, apiMockPostman} from "../utils/api.utils";
import { getURLQueryValueByKey } from "../utils/common.utils";
import { ACCOUNT_ROLE } from "../constants/common.constant";
import {
	MOCK_ACCOUNTS,
	MOCK_AVAILABLE_CURRENCIES,
} from "../constants/user.constant";

/**
 * Represents the UserAPIType class.
 * This class defines the API methods for retrieving user configuration and accounts.
 * @typedef {Object} UserAPIType
 * @property {() => Promise<AxiosResponse<UserGetConfigAPIResponseType>>} getConfig - Retrieves user configuration.
 * @property {() => Promise<AxiosResponse<UserGetAccountsAPIResponseType>>} getAccounts - Retrieves user accounts.
 * @property {() => Promise<UserGetAccountsAPIResponseType>} getAccountsMock - Retrieves user accounts.
 */
type UserAPIType = {
	getConfig: () => Promise<AxiosResponse<UserGetConfigAPIResponseType>>;
	getAccounts: () => Promise<AxiosResponse<UserGetAccountsAPIResponseType>>;
	getAccountsMock: () => Promise<UserGetAccountsAPIResponseType>;
	//getConfigMock: () => Promise<UserGetConfigAPIResponseType>;
};

/**
 * User API object definition
 * @typedef {Object} UserAPIType
 * @property {Function} getConfig - Get user configuration API method
 * @property {Function} getAccounts - Get user accounts API method
 * @property {Function} getAccountsMock - Get mock user accounts API method
 */
const userAPI: UserAPIType = {
	getConfig: () => {
		return process.env.REACT_APP_USE_MOCK_API_GET_CONFIG === 'true'
			? apiMockPostman.get(`/auth`)
			: api.get(`/auth`, {timeout:5000});
	},
	getAccounts: () => {
		return process.env.REACT_APP_USE_MOCK_API_GET_ACCOUNTS === 'true'
			? apiMockPostman.get(`/account`)
			: api.get(`/account`);
	},
	getAccountsMock: () =>
		new Promise((resolve, reject) =>
			setTimeout(() => {
				return resolve({
					data: {
						ccylist: MOCK_AVAILABLE_CURRENCIES,
						account: MOCK_ACCOUNTS,
					},
					lastUpdate: Date.now(),
					rseponseTime: Date.now(),
					responseCode: "0",
				});
			}, 1000)
		),

	/*getConfigMock: () =>
		new Promise((resolve, reject) =>
			setTimeout(() => {
				const token = getURLQueryValueByKey("token") as string;

				let data;

				switch (token) {
					case "NOMFCA":
						data = {
							type: ACCOUNT_ROLE.NOMFCA,
							base_currency: "MYR",
							country: "MYS",
						};
						break;
					case "HNW":
						data = {
							type: ACCOUNT_ROLE.HNW,
							base_currency: "HKD",
							country: "HKG",
						};
						break;
					case "MFCA":
						data = {
							type: ACCOUNT_ROLE.MFCA,
							base_currency: "USD",
							country: "USA",
						};
						break;
					default:
						return reject({ responeCode: -1 });
				}

				return resolve({
					data,
					responseCode: 0,
				});
			}, 1000)
		),*/
};

export default userAPI;
