import {APP_PATH} from "../../../constants/app.constant";

/**
 * Determines if the user has come back from a later page.
 *
 * @param {string} path - The current path of the user.
 * @returns {boolean} - True if the user has come back from a later page, otherwise false.
 */
export const isComeBackFromLatterPage = (path: string): boolean => {
    const isComeBackFromStep2: boolean = path.includes(APP_PATH.fx.order.account)
    const isComeBackFromConfirmation: boolean = path.includes(APP_PATH.fx.order.confirmation)

    return (isComeBackFromStep2 || isComeBackFromConfirmation)
}
