import styled from "styled-components";

interface SectionContentProps {
	hasMarginBottom?: boolean;
	hasPaddingX?: boolean;
	hasBackgroundColor?: boolean;
}

// const SectionContent = styled.div<SectionContentProps>`
// 	${({ hasMarginBottom }) => hasMarginBottom && `margin-bottom: 16px;`}
// 	${({ hasPaddingX }) =>
// 		hasPaddingX &&
// 		`
//         padding-left: var(--common-layout-padding-x);
// 	    padding-right: var(--common-layout-padding-x);
//     `}
// 	${({ hasBackgroundColor }) =>
// 		`background-color: ${hasBackgroundColor ? "transparent" : "transparent"}`}
// `;

const SectionContent = styled.div<SectionContentProps>`
	${({ hasMarginBottom }) => hasMarginBottom && `margin-bottom: 16px;`}
	${({ hasPaddingX }) =>
	hasPaddingX &&
	`
        padding-left: var(--common-layout-padding-x);
	    padding-right: var(--common-layout-padding-x);
    `}
	background-color: transparent;
`;


export default SectionContent;
