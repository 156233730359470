import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import FXNationalFlag, { StyledFXNationalFlag } from "../common/FXNationalFlag";

import { COUNTRY_CURRENCY_MAP } from "../../constants/fx.constant";
import { FXType } from "../../types/fx.type";

interface FXItemProps {
	fxObject: FXType;
	onClick: (fxObject: FXType) => void;
}

export const StyledFXItem = styled.div`
	display: flex;
	align-items: center;
	margin-left: 24px;
	margin-right: 24px;
	border-bottom: 1px solid var(--bordered-input-border-color);
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 18px;

	> ${StyledFXNationalFlag} {
		margin-right: 16px;
	}
`;

const FXItemText = styled.div`
	flex: 1 0 auto;
`;

const FXItem = ({ fxObject, onClick }: FXItemProps) => {
	const intl = useIntl();

	const handleClick = () => {
		onClick(fxObject);
	};

	return (
		<StyledFXItem onClick={handleClick}>
			<FXNationalFlag
				source={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[0].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				target={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[1].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				width={48}
				height={48}
			/>
			<FXItemText>
				{intl.formatMessage(
					{ id: "app.common.fx" },
					{ source: fxObject.currencies[0], target: fxObject.currencies[1] }
				)}
			</FXItemText>
		</StyledFXItem>
	);
};

export default FXItem;
