import styled from 'styled-components';

interface TextWrapperProps {
    pushToRight?: boolean; // Optional parameter
}

export const TextWrapper = styled.div<TextWrapperProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({ pushToRight }) => (pushToRight ? 'flex-end' : 'flex-start')};
`;
