import {showServiceUnavailableSnackbar} from "./snackbar.utils";
import {
    ACCOUNT_FAIL_OR_DATA_IS_INVALID,
    fxlist_OR_ccylist_IS_FALSY_OR_EMPTY, MARKET_ORDER_CREATE_ORDER_FAIL, MARKET_ORDER_CREATE_ORDER_MISSING_DATA,
    RATE_DATA_IS_EMPTY_OR_INVALID, RESPONSE_ERROR_OR_USER_DATA_INVALID,
    USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING
} from "../constants/errorMsg.constant";
import axios from "axios";
import {ErrorForToastBar, ErrorObjForToastBar} from "../types/errorObjType.type";
import {setIsWentBackAndToggledFalse} from "../slice/app";
import {isWentBackAndToggledFalseEnum} from "../types/app.type";

// Reusable error handling function
/**
 * Function to manage API error responses.
 *
 * @param {ErrorObjForToastBar | any} error - The error object.
 * @param {() => void} [onCloseFunc] - Optional function to execute on close.
 * @param {string} [specificToastErrorMsg] - Optional specific toast error message.
 */
const manageApiErrorResponse = (
    error: (ErrorObjForToastBar | any)
    , onCloseFunc?: () => void,
    specificToastErrorMsg?: string
) => {
    // alert(error.message)
    console.error(error)
    console.error("error.errorMsg", error?.errorMsg);
    console.error("error.responseData", error?.responseData)
    console.error("error.responseData.responseCode", error?.responseData?.responseCode)


    // Check for specific error messages or HTTP exceptions
    const isErrorType = error.errorMsg || //011a /010a //001a
        error.errorMsg === RESPONSE_ERROR_OR_USER_DATA_INVALID || //newly added
        error.errorMsg === RATE_DATA_IS_EMPTY_OR_INVALID || //009a
        error.errorMsg === fxlist_OR_ccylist_IS_FALSY_OR_EMPTY || //004a
        error.errorMsg === USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING || //newly added
        error.errorMsg === MARKET_ORDER_CREATE_ORDER_FAIL || //newly added
        error.errorMsg === MARKET_ORDER_CREATE_ORDER_MISSING_DATA || //newly added
        error.errorMsg === ACCOUNT_FAIL_OR_DATA_IS_INVALID; //newly added

    //Explanation: it is fine to pass null, as it is optional
    let responseCode = null;
    if (error.responseData && error.responseData.responseCode) {
        responseCode = error.responseData.responseCode;
    }
    showServiceUnavailableSnackbar(responseCode, onCloseFunc, specificToastErrorMsg);

}

/**
 * Processes an API error and manages the appropriate response.
 *
 * @param {unknown | ErrorObjForToastBar} error - The error object to be processed.
 * @param {() => void} [callbackFn] - Optional callback function to be called after processing the error.
 * @param {string} [specificToastErrorMsg] - Optional specific error message for displaying in a toast message.
 *
 * @return {void}
 */
export function processApiError(
    error: unknown | ErrorObjForToastBar,
    callbackFn?: () => void,
    specificToastErrorMsg?: string,
) {

    //@ts-ignore
    if (axios.isAxiosError(error)) {
        console.log('this is axios error');
        console.log(error)
        // const convertedError2: ErrorObjForToastBar = {
        //     errorMsg: error.message,
        //     responseHttpStatus: error.response!.status,
        //     responseData: error.response?.data ?? null
        // };
        const convertedError: ErrorObjForToastBar = {
            errorMsg: error.message,
            responseHttpStatus: 0,
            responseData: error.response?.data ?? null
        };
        manageApiErrorResponse(convertedError, callbackFn, specificToastErrorMsg);
    } else if (isInstanceOfErrorObjForToastBar(error)) {
        manageApiErrorResponse(error, callbackFn, specificToastErrorMsg);
    } else {
        manageApiErrorResponse({errorMsg: 'An unknown error occurred'}, callbackFn);
    }
}

/**
 * Checks if the given object is an instance of ErrorObjForToastBar.
 *
 * @param {any} obj - The object to be checked.
 * @returns {boolean} - True if the object is an instance of ErrorObjForToastBar, false otherwise.
 */
function isInstanceOfErrorObjForToastBar(obj: any): obj is ErrorObjForToastBar {
    return typeof obj.errorMsg === 'string' &&
        (typeof obj.responseHttpStatus === 'number' || obj.responseHttpStatus === null) &&
        obj.hasOwnProperty('responseData'); // This checks for the existence of responseData, but not its type
}
