export const DEFAULT_RATE_UPDATE_INTERVAL = 3000;
export const DEFAULT_INACTIVATED_ACCOUNT_RATE_UPDATE_INTERVAL = 60000;

export const DEFAULT_FX_PRICE_CHANGE_COLOR_TIMEOUT = 2000;

export const MIN_WATCHLIST_NAME_LENGTH = 3;
export const MAX_WATCHLIST_NAME_LENGTH = 30;

export const MAX_FX_IN_WATCHLIST = 10;

export const DEFAULT_WATCHLIST_NAME_PATTERN = "Watchlist {number}";
