import axios, { AxiosResponse } from "axios";

import {
	C5DataApiRequestType,
	C5InfoAPIResponseType,
	NewsGetAPIRequestType,
	NewsGetAPIResponseType,
	NewsGetPayloadType,
	NewsGetStoryAPIResponseType,
	NewsType,
} from "../types/news.type";
import { MOCK_FX_NEWS } from "../constants/news.constant";
import { api, marketAxiosInstance } from "../utils/api.utils";
import { convertPayloadToQueryString } from "../utils/common.utils";

/**
 * Type representing the NewsAPI interface.
 * @typedef {Object} NewsAPIType
 *
 * @property {function} get - Asynchronously retrieves news articles based on provided parameters.
 * @param {NewsGetAPIRequestType} [payload] - Optional payload containing the request parameters.
 * @returns {Promise<AxiosResponse<NewsGetAPIResponseType>>} - A Promise that resolves to an Axios response containing the retrieved news articles.
 *
 * @property {function} getStory - Asynchronously retrieves a specific news story based on its ID.
 * @param {string} newsId - The ID of the news story to retrieve.
 * @returns {Promise<AxiosResponse<NewsGetStoryAPIResponseType>>} - A Promise that resolves to an Axios response containing the retrieved news story.
 *
 * @property {function} getC5Info - Asynchronously retrieves C5 information based on the provided RIC.
 * @param {string} [ric] - Optional RIC (Reuters Instrument Code) to retrieve C5 information for.
 * @returns {Promise<AxiosResponse<C5InfoAPIResponseType>>} - A Promise that resolves to an Axios response containing the retrieved C5 information.
 *
 * @property {function} getC5Data - Asynchronously retrieves C5 data based on the provided payload.
 * @param {C5DataApiRequestType} payload - The payload containing the request parameters.
 * @returns {Promise<AxiosResponse<any>>} - A Promise that resolves to an Axios response containing the retrieved C5 data.
 */
type NewsAPIType = {
	get: (
		payload?: NewsGetAPIRequestType
	) => Promise<AxiosResponse<NewsGetAPIResponseType>>;
	//getMock: (payload?: NewsGetAPIRequestType) => Promise<NewsGetAPIResponseType>;
	getStory: (
		newsId: NewsType["id"]
	) => Promise<AxiosResponse<NewsGetStoryAPIResponseType>>;
	getC5Info: (ric?: string) => Promise<AxiosResponse<C5InfoAPIResponseType>>;
	getC5Data: (payload: C5DataApiRequestType) => Promise<AxiosResponse<any>>;
};

/**
 * Represents the news API.
 * @typedef {Object} NewsAPIType
 * @property {function} get - Fetches the news headlines based on the specified payload.
 * @property {function} getStory - Fetches the story for a given news ID.
 * @property {function} getC5Info - Fetches the C5 info for a given RIC.
 * @property {function} getC5Data - Fetches the C5 data based on the specified payload.
 */
const newsAPI: NewsAPIType = {
	get: (payload) => {
		let queryParams: string[] = [];
		if (payload) {
			Object.keys(payload).forEach((v) => {
				if (payload[v as keyof NewsGetAPIRequestType]) {
					queryParams.push(
						`${v}=${encodeURIComponent(
							payload[v as keyof NewsGetAPIRequestType]?.toString() || ""
						)}`
					);
				}
			});
		}

		return marketAxiosInstance.get(
			`/news/headline${
				queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
			}`
		);
	},
	/*getMock: (payload) =>
		new Promise((resolve, reject) =>
			setTimeout(() => {
				let data;
				let startIndex = 0;
				let limit = payload?.limit || 10;

				if (payload?.fx) {
					data = MOCK_FX_NEWS.map((v) => ({
						id: v.id,
						headline: v.headline,
						datetime: v.datetime,
					}));
				} else {
					data = MOCK_FX_NEWS.map((v) => ({
						id: v.id,
						headline: v.headline,
						datetime: v.datetime,
					}));
				}

				if (payload?.page) {
					startIndex = payload.page > 0 ? (payload.page - 1) * limit : 0;
				}

				data = data.slice(startIndex, limit);

				return resolve({
					data,
					responseCode: 0,
					rseponseTime: Date.now(),
				});
			}, 1000)
		),*/
	getStory: (newsId) => {
		return marketAxiosInstance.get(`/news/story?nid=${newsId}`);
	},
	getC5Info: (ric) => {
		return marketAxiosInstance.get(`/c5data/info?ric=${ric}`);
	},
	getC5Data: (payload) => {
		const { periodType, ...newPayload } = payload;
		return marketAxiosInstance.get(
			`/c5data/${periodType}?${convertPayloadToQueryString(newPayload)}`
		);
	},
};

export default newsAPI;
