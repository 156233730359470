import { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

enum POSITION {
	TOP = "top",
	CENTER = "center",
	BOTTOM = "bottom",
}

interface StyledGradientContainerType {
	padding?: {
		top?: number | string;
		bottom?: number | string;
		left?: number | string;
		right?: number | string;
	};
}

interface GradientContainerType
	extends StyledGradientContainerType,
		React.ComponentPropsWithRef<any> {}

interface GradientLayerType {
	position: POSITION | null;
}

const StyledGradientContainer = styled.div<StyledGradientContainerType>`
	position: relative;
	height: 100%;
	${({ padding }) => {
		let paddingCSSText = "";
		if (padding && padding.top)
			paddingCSSText += `padding-top: ${
				typeof padding.top === "string" ? padding.top : `${padding.top}px`
			};`;
		if (padding && padding.bottom)
			paddingCSSText += `padding-bottom: ${
				typeof padding.bottom === "string"
					? padding.bottom
					: `${padding.bottom}px`
			};`;
		if (padding && padding.left)
			paddingCSSText += `padding-left: ${
				typeof padding.left === "string" ? padding.left : `${padding.left}px`
			};`;
		if (padding && padding.right)
			paddingCSSText += `padding-right: ${
				typeof padding.right === "string" ? padding.right : `${padding.right}px`
			};`;

		return paddingCSSText;
	}}
`;

const ContentContainer = styled.div`
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 0;
`;

const GradientLayer = styled.div<GradientLayerType>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	${({ position }) => {
		switch (position) {
			case "top":
				return `
					background: linear-gradient(
						0,
						var(--background-color-primary) 0%,
						rgba(255, 255, 255, 0) 15%,
						rgba(255, 255, 255, 0) 100%
					);
				`;
			case "center":
				return `
					background: linear-gradient(
						0,
						var(--background-color-primary) 0%,
						rgba(255, 255, 255, 0) 15%,
						rgba(255, 255, 255, 0) 75%,
						var(--background-color-primary) 100%
					);
				`;
			case "bottom":
				return `
					background: linear-gradient(
						0,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 0) 75%,
						var(--background-color-primary) 100%
					);
				`;
		}
	}}
	pointer-events: none;
	z-index: 10;
`;

const GradientContainer = forwardRef<HTMLDivElement, GradientContainerType>(
	({ padding, children }, ref) => {
		const [position, setPosition] = useState<POSITION | null>(null);
		const scrollableElementRef = useRef<HTMLDivElement | null>(null);

		useEffect(() => {
			applyGradient();
		}, [ref, scrollableElementRef]);

		/**
		 * Applies gradient based on the current scroll position of scrollableElementRef.
		 * @function applyGradient
		 * @returns {void}
		 */
		const applyGradient = () => {
			if (scrollableElementRef.current) {
				const scrollableScrollTop = scrollableElementRef.current.scrollTop;
				const contentHeight = scrollableElementRef.current.clientHeight;
				const atBottom =
					contentHeight + Math.ceil(scrollableScrollTop) >=
					scrollableElementRef.current.scrollHeight;
				if (scrollableElementRef.current.scrollHeight > contentHeight) {
					if (scrollableScrollTop <= 0) {
						setPosition(POSITION.TOP);
					} else if (atBottom) {
						setPosition(POSITION.BOTTOM);
					} else {
						setPosition(POSITION.CENTER);
					}
				} else {
					setPosition(null);
				}
			}
		};

		const handleScroll = () => {
			applyGradient();
		};

		return (
			<StyledGradientContainer padding={padding}>
				<GradientLayer position={position}></GradientLayer>
				<ContentContainer
					ref={(node) => {
						scrollableElementRef.current = node;
						if (typeof ref === "function") {
							ref(node);
						} else if (ref) {
							ref.current = node;
						}
					}}
					onScroll={handleScroll}
				>
					{children}
				</ContentContainer>
			</StyledGradientContainer>
		);
	}
);

export default GradientContainer;
