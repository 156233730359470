import { FX_STRING_LENGTH } from "../../../constants/fx.constant";

/**
 * Format FX symbol to array of currencies
 * @param {string} fx - FX symbol, e.g. USDJPY.
 * @return {Array<string>} The array of currencies.
 */
const formatFXSymbolToCurrencies = (fx: string): string[] => {
	let currencies = ["", ""];
	if (fx.length === FX_STRING_LENGTH) {
		currencies[0] = fx.substring(0, 3);
		currencies[1] = fx.substring(3);
	}

	return currencies;
};

export default formatFXSymbolToCurrencies;
