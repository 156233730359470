import {
	Dialog,
	dialogClasses,
	dialogContentClasses,
	dialogActionsClasses,
	DialogContent,
	DialogActions,
	DialogProps,
} from "@mui/material";
import { useIntl } from "react-intl";
import styled from "styled-components";

import Button from "../../styled/buttons/Button";
import AlertDialogTitle from "../../styled/dialogs/alert/AlertDialogTitle";
import AlertDialogCloseButton from "../../styled/dialogs/alert/AlertDialogCloseButton";

import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import * as React from "react";

interface AlertDialogProps extends DialogProps {
	titleComp: React.ReactNode;
	contentComp: React.ReactNode;
	confirmButtonText: string;
	onConfirm: () => void;
}

const AlertDialogTitleContainer = styled.div`
	padding-left: 40px;
	padding-right: 40px;
`;

const StyledAlertDialog = styled(Dialog)`
	position: fixed;

	> .MuiBackdrop-root {
		background-color: var(--dialog-backdrop-background-color);
	}

	.MuiDialog-container {
		overflow-y: auto;
	}

	.${dialogClasses.paper} {
		width: calc(100% - 24px);
		max-height: initial;
		margin: 24px;
		border-radius: 8px;
		background-color: var(--dialog-background-color);
	}

	${AlertDialogTitleContainer} {
		padding-left: 24px;
		padding-right: 24px;
	}

	.${dialogContentClasses.root} {
		margin-top: 0;
		padding: 0 40px 20px;
		font-size: 14px;
		font-weight: var(--font-weight-light);
		line-height: 21px;
	}

	.${dialogActionsClasses.root} {
		justify-content: center;
		padding: 0 40px 40px;
	}
`;

const AlertDialog = ({
	titleComp,
	contentComp,
	confirmButtonText,
	open,
	onConfirm,
	onClose,
	...rest
}: AlertDialogProps) => {
	const intl = useIntl();
	const onConfirmPreventPropagate = (event:React.MouseEvent<HTMLButtonElement>):void =>{
		event.stopPropagation()
		onConfirm()
	}

	const onClosePreventPropagate = (event:React.MouseEvent):void =>{
		event.stopPropagation()
		if(onClose){
			onClose({}, "escapeKeyDown")
		}
	}

	const preventPropagate = (event:React.MouseEvent):void =>{
		event.stopPropagation()
	}


	return (
		<StyledAlertDialog
			open={open}
			onClose={onClose}
			{...rest}
			onClick={(e:React.MouseEvent) => preventPropagate(e)}
		>
			<AlertDialogTitle>
				<AlertDialogCloseButton>
					<CloseIcon
						className="mbb-icon icon-close"
						onClick={(e:React.MouseEvent) => onClose && onClosePreventPropagate(e)}
					/>
				</AlertDialogCloseButton>
				<AlertDialogTitleContainer>{titleComp}</AlertDialogTitleContainer>
			</AlertDialogTitle>
			<DialogContent>{contentComp}</DialogContent>
			<DialogActions>
				<Button theme="primary" width="100%" onClick={(event)=>onConfirmPreventPropagate(event)}>
					{confirmButtonText}
				</Button>
			</DialogActions>
		</StyledAlertDialog>
	);
};

export default AlertDialog;
