import {useState} from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {ReactComponent as InformationIcon} from "../../../assets/icons/information.svg"
import {FXRateAlertType, FXType} from "../../../types/fx.type";
import Card from "../../../components/styled/Card";
import List from "../../../components/styled/List";
import Item, {StyledItem} from "../../../components/styled/items/Item";
import {useAppSelector} from "../../../utils/store.utils";
import {selectFXOrderForm, selectFXUnitQuote} from "../../../slice/fx";
import ItemStartContainer from "../../../components/styled/items/ItemStartContainer";
import ItemStartContent from "../../../components/styled/items/ItemStartContent";
import ItemEndContainer from "../../../components/styled/items/ItemEndContainer";
import {getRateByDealt} from "../../../formatters/fx";
import AlertDialog from "../../../components/common/dialog/AlertDialog";
import {renderRate} from "../../../components/order/CalculationCard";
import {getDpUqBySymbol} from "../../../components/fx/service/getDpUqBySymbol";

interface CalculationCardForRateAlertProps {
    source: {
        currency: string;
        name: string
    };
    // target: {
    //     currency?: string;
    //     amount: number;
    // };
    fxObject: FXType | undefined;
}

const StyledCalculationCard = styled(Card)`
    margin-bottom: 24px;
    margin-top: 24px;
`;

const CalculationCardList = styled(List)`
    ${StyledItem} {
        font-size: 12px;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
`;

/**
 * Renders the exchange rate between two currencies.
 *
 * @param {string} currency1 - The first currency code.
 * @param {string} currency2 - The second currency code.
 * @param {string | number | null} rate - The exchange rate between the currencies.
 * @returns {string} - The rendered exchange rate.
 */

const CalculationCardForRateAlert = ({
                                         source,
                                         // target,
                                         fxObject,
                                     }: CalculationCardForRateAlertProps) => {
    const intl = useIntl();
    const [isRateAlertDialogOpen, setIsRateAlertDialogOpen] =
        useState<boolean>(false);
    const fxUnitQuote = useAppSelector(selectFXUnitQuote)

    const handleRateAlertDialogClose = () => {
        setIsRateAlertDialogOpen(false);
    };

    const handleRateAlertDialogConfirm = () => {
        setIsRateAlertDialogOpen(false);
    };

    return (
        <StyledCalculationCard>
            <CalculationCardList>
                <Item>
                    <ItemStartContainer>
                        <ItemStartContent>
                            {intl.formatMessage({
                                id: "app.page.orderForm.card.calculation.item.rate.label",
                            })}
                        </ItemStartContent>
                        <InformationIcon
                            className="mbb-icon"
                            onClick={() => setIsRateAlertDialogOpen(true)}
                        />
                    </ItemStartContainer>
                    {
                        <ItemEndContainer>
                            {fxObject &&
                                (renderRate
                                    (
                                        fxObject.currencies[0],
                                        fxObject.currencies[1],
                                        getRateByDealt(source.currency, fxObject),
                                        getDpUqBySymbol(fxObject.symbol).dp,
                                        getDpUqBySymbol(fxObject.symbol).uq,
                                    )
                                )
                            }
                        </ItemEndContainer>
                    }
                </Item>
            </CalculationCardList>
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.orderForm.dialog.rate.title",
                })}
                contentComp={intl.formatMessage({
                    id: "app.page.createRateAlert.exchangeRate.Info",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.orderForm.dialog.rate.button.ok",
                })}
                open={isRateAlertDialogOpen}
                onClose={handleRateAlertDialogClose}
                onConfirm={handleRateAlertDialogConfirm}
            />
        </StyledCalculationCard>
    );
};

export default CalculationCardForRateAlert;
