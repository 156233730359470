import {Ref, forwardRef, useMemo, useState} from "react";
import {matchPath, useLocation} from "react-router-dom";
import {debounce} from "@mui/material";
import {useIntl} from "react-intl";
import styled from "styled-components";

import AppMenu from "./AppMenu";

import {
    APP_HEADER_BUTTON_TYPE,
    APP_HEADER_END_BUTTON_PATH_MAP,
    APP_HEADER_TITLE_SPECIAL_HANDLING_PATH,
    APP_PATH,
} from "../../constants/app.constant";
import {DEBUG_MODE_TOGGLE_COUNT} from "../../constants/app.constant";
import {closeApp, navigateToApp} from "../../utils/integration.utils";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {selectApp, setDebugMode, sliceHistoryToPoint, updateTriggerCloseApp} from "../../slice/app";
import {resetFXOrderForm, selectCurrentOrPrevOrderId, updateCurrentOrPrevOrderId} from "../../slice/fx";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import useAppHeader from "../../hooks/useAppHeader";

import {ReactComponent as ArrowLeftIcon} from "../../assets/icons/arrow-left.svg";
import {ReactComponent as CrossIcon} from "../../assets/icons/close.svg";
import {ReactComponent as MenuIcon} from "../../assets/icons/menu.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import fxAPI from "../../api/fx.api";
import {formatURL} from "../../formatters/common";
import {REJECT_QUOTE_USER_CLOSE_ORDER_FORM} from "../../constants/errorMsg.constant";

interface AppHeaderProps {
    onBack: () => void;
}

interface AppHeaderTitleTextProps {
    isInFXOrderForm?: boolean;
}

const StyledAppHeaderStartContainer = styled.div`
    min-width: 24px;
    flex-shrink: 0;
    line-height: 1;
`;

const StyledAppHeaderTitle = styled.div`
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
    font-weight: var(--font-weight-semi-bold);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledAppHeaderTitleText = styled.span<AppHeaderTitleTextProps>`
    ${({isInFXOrderForm}) =>
            isInFXOrderForm &&
            `
			color: var(--text-color-secondary);
			font-size: 12px;
			line-height: 18px;
	`}
`;

const StyledAppHeaderEndContainer = styled.div`
    min-width: 24px;
    flex-shrink: 0;
    line-height: 1;
`;

export const StyledAppHeader = styled.div`
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
    min-height: calc(44px + constant(safe-area-inset-top));
    min-height: calc(44px + env(safe-area-inset-top));
    padding-top: calc(18px + constant(safe-area-inset-top));
    padding-top: calc(18px + env(safe-area-inset-top));
    padding-right: 24px;
    padding-bottom: 18px;
    padding-left: 24px;
    background-color: var(--background-color-primary);
    user-select: none;
    z-index: 99;
`;

const VersionText = styled.div`
    position: fixed;
    top: calc(4px + constant(safe-area-inset-top));
    top: calc(4px + env(safe-area-inset-top));
    left: 50%;
    padding-left: 8px;
    padding-right: 8px;
    font-family: monospace;
    font-size: 12px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.87);
    transform: translateX(-50%);
    z-index: 99999;
`;

const AppHeader = forwardRef(
        ({onBack}: AppHeaderProps, ref: Ref<HTMLDivElement>) => {
            const navigate = useNavigateThrottle();
            const location = useLocation();
            const app = useAppSelector(selectApp);
            const currentOrPrevOrderId = useAppSelector(selectCurrentOrPrevOrderId)
            const dispatch = useAppDispatch();
            const [isAppMenuOpen, setIsAppMenuOpen] = useState<boolean>(false);
            const [hasAppHeader] = useAppHeader();
            const isInOrderStatusPages = location.pathname.toLowerCase().includes("/order/status".toLowerCase())
            const isInOrderConfirmationPage = location.pathname.toLowerCase().includes("/order/confirmation".toLowerCase())
            const isInRateAlertListPage = app.history[app.history?.length - 1]?.path?.toLowerCase().includes("rateAlert/view".toLowerCase())
            const isInOrderStep1 = location.pathname.toLowerCase().includes("form/step-1".toLowerCase())
            const isInWatchlist = location.pathname.toLowerCase().includes("/watchlist".toLowerCase())
            const isInCurrencyCalculator = location.pathname.toLowerCase().includes("/currency-calculator".toLowerCase())
            const noDomainLinkedTitle = "noDomainLinkedTitle"
            const titleGenerator = (): string => {
                if (isInOrderStatusPages) {
                    return "Order Status"
                } else if (isInRateAlertListPage) {
                    return "Rate Alert"
                } else if (isInOrderStep1) {
                    return "Step 1 of 2"
                } else if (isInWatchlist) {
                    return "Watchlist"
                } else if (isInCurrencyCalculator) {
                    return "Currency Calculator"
                } else {
                    return noDomainLinkedTitle
                }
            }
            function findIsPathIncluded(objects: { path: string }[], path: string): string | null {
                const foundObject = objects.find(obj => obj.path.includes(path));
                return foundObject ? foundObject.path : null;
            }

            function findStringInPaths(objects: { path: string }[], searchString: string): number {
                // Iterate from the second last item to the beginning
                for (let i = objects.length - 2; i >= 0; i--) {
                    if (objects[i].path.includes(searchString)) {
                        return -(objects.length - 1 - i);
                    }
                }
                // If no match is found
                return 0;
            }

            /**
             * Handles the back button functionality.
             * If the previous state in the window history exists and its index is greater than 0,
             * calls the onBack() function and navigates back by one step.
             * If the previous state index is less than 1, navigates to the app.
             *
             * @returns {void}
             */
            const handleBack = () => {
                if (window.history.state && window.history.state.idx > 0) {
                    if (isInRateAlertListPage) {
                        const chartPath = APP_PATH.chart.index
                        if (findIsPathIncluded(app.history, chartPath)) {
                            navigate(findStringInPaths(app.history, chartPath))
                            dispatch(sliceHistoryToPoint(findIsPathIncluded(app.history, chartPath)!))
                        } else {
                            navigateToApp();
                        }
                        return
                    }
                    onBack();
                    navigate(-1);
                } else if (window.history.state.idx < 1) {
                    navigateToApp();
                }
            };

            /**
             * Function to handle title click event
             *
             * @param {React.MouseEvent<HTMLSpanElement>} event - The click event
             * @returns {void}
             */

            /**
             * Opens the application menu.
             *
             * @function handleOpenMenu
             */
            const handleOpenMenu = () => {
                setIsAppMenuOpen(true);
            };

            /**
             * Closes the FX order form, either by navigating back to the previous page or closing the app.
             *
             * @function handleCloseFXOrderForm
             * @returns {void}
             */
            const handleCloseFXOrderForm = () => {
                const historyIndex = app.history.findIndex((v) =>
                    matchPath({path: v.path}, APP_PATH.watchlist.index) ||
                    matchPath({path: v.path}, APP_PATH.fx.order.statusList)
                );
                if (historyIndex > -1) {
                    const detailsPath = APP_PATH.fx.order.details
                    if (findIsPathIncluded(app.history, detailsPath)) {
                        navigate(findStringInPaths(app.history, detailsPath))
                        dispatch(sliceHistoryToPoint(findIsPathIncluded(app.history, detailsPath)!))
                    } else {
                        navigate(historyIndex - (app.history.length - 1));
                    }
                    dispatch(resetFXOrderForm());
                } else {
                    closeApp()
                    if (isInOrderConfirmationPage && currentOrPrevOrderId) {
                        fxAPI
                            .cancelOrder(currentOrPrevOrderId, REJECT_QUOTE_USER_CLOSE_ORDER_FORM)
                            .then(() => dispatch(updateCurrentOrPrevOrderId("")))
                            .catch((error) => console.log(error));
                    }
                }
                dispatch(updateTriggerCloseApp())
            };

            // Render functions
            /**
             * Renders a button in the start container.
             *
             * @return {void}
             */
            const renderButtonInStartContainer = () => {
                //switch
            };

            /**
             * Renders the title for the application header.
             * @returns {React.ReactNode} The rendered title.
             */
            const renderTitle = (): React.ReactNode => {
                let isInFXOrderForm = false;
                for (let i = 0; i < APP_HEADER_TITLE_SPECIAL_HANDLING_PATH.length; i++) {
                    if (
                        matchPath(
                            {path: APP_HEADER_TITLE_SPECIAL_HANDLING_PATH[i]},
                            location.pathname
                        )
                    ) {
                        isInFXOrderForm = true;
                        break;
                    }
                }

                return (
                    <StyledAppHeaderTitleText
                        isInFXOrderForm={isInFXOrderForm}
                        // onClick={handleTitleClick}
                    >
                        {titleGenerator() !== noDomainLinkedTitle ? titleGenerator() : app.title}
                    </StyledAppHeaderTitleText>
                );
            };

            /**
             * Renders a set of buttons in the end container of the app header.
             * The buttons to be rendered are determined based on the current path.
             * @returns {React.ReactNode} - The rendered buttons as a React node.
             */
            const renderButtonInEndContainer = (): React.ReactNode => {
                let buttonTypes: APP_HEADER_BUTTON_TYPE[] = [];
                let buttons: React.ReactNode[] = [];
                let key: keyof typeof APP_HEADER_END_BUTTON_PATH_MAP;
                for (key in APP_HEADER_END_BUTTON_PATH_MAP) {
                    if (matchPath({path: key}, location.pathname)) {
                        buttonTypes = APP_HEADER_END_BUTTON_PATH_MAP[key];
                        break;
                    }
                }

                if (buttonTypes.length > 0) {
                    for (let i = 0; i < buttonTypes.length; i++) {
                        switch (buttonTypes[i]) {
                            case APP_HEADER_BUTTON_TYPE.MENU:
                                buttons.push(
                                    <MenuIcon
                                        key={`app-header-end-button-${i}`}
                                        className="mbb-icon"
                                        onClick={handleOpenMenu}
                                    />
                                );
                                break;
                            case APP_HEADER_BUTTON_TYPE.BACK_TO_ENTRY_POINT:
                                buttons.push(
                                    <CloseIcon
                                        key={`app-header-end-button-${i}`}
                                        className="mbb-icon"
                                        onClick={handleCloseFXOrderForm}
                                    />
                                );
                                break;
                        }
                    }
                }

                return <>{buttons.map((v) => v)}</>;
            };
            return (
                <>
                    {hasAppHeader() && (
                        <StyledAppHeader ref={ref}>
                            <StyledAppHeaderStartContainer>
                                {!isInOrderStatusPages
                                    &&
                                    <ArrowLeftIcon id={"left-arrow-for-back"} className="mbb-icon"
                                                   onClick={handleBack}/>
                                }
                            </StyledAppHeaderStartContainer>
                            <StyledAppHeaderTitle>{renderTitle()}</StyledAppHeaderTitle>
                            <StyledAppHeaderEndContainer>
                                {!isInOrderStatusPages
                                    ? renderButtonInEndContainer() :
                                    <CrossIcon className="mbb-icon" onClick={handleBack}/>
                                }
                            </StyledAppHeaderEndContainer>
                        </StyledAppHeader>
                    )}
                    <AppMenu open={isAppMenuOpen} onClose={() => setIsAppMenuOpen(false)}/>
                    {/*{process.env.REACT_APP_ENV !== "production" && app.debugMode && (*/}
                    {/*	<VersionText>*/}
                    {/*		App version: {process.env.REACT_APP_APP_VERSION}*/}
                    {/*	</VersionText>*/}
                    {/*)}*/}
                </>
            );
        }
    )
;

export default AppHeader;
