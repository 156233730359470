import {
    CreateFutureOrderAPIResponseType,
    FXOrderFormRequiredType,
    FXOrderFormType,
    FXOrderTransactionAcknowledgementType, FXOrderTransactionAcknowledgementTypeForFuture
} from "../../../../../types/fx.type";
import {
    DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT,
    FX_ORDER_CONFIRM_STATUS_CODE, FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE,
    FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE
} from "../../../../../constants/fx.constant";
import {getIntlObject} from "../../../../../utils/intl";
import moment from "moment/moment";
import {formatNumber} from "../../../../../utils/common.utils";
import {getConversionFee} from "../../../order";
import {renderRate} from "../../../../../components/order/CalculationCard";
import {store} from "../../../../../store/store";
import {getDpUqBySymbol} from "../../../../../components/fx/service/getDpUqBySymbol";

// function formatDateString(input: string): string {
//     // Extracting year, month, day, etc. from the string
//     const year = parseInt(input.substring(0, 4), 10);
//     const month = parseInt(input.substring(4, 6), 10) - 1; // Month is 0-indexed in JavaScript
//     const day = parseInt(input.substring(6, 8), 10);
//     const hour = parseInt(input.substring(8, 10), 10);
//     const minute = parseInt(input.substring(10, 12), 10);
//     const second = parseInt(input.substring(12, 14), 10);
//
//     // Create a new date object
//     const date = new Date(year, month, day, hour, minute, second);
//
//     // Format the date
//     const options: Intl.DateTimeFormatOptions = {
//         year: 'numeric', month: 'long', day: 'numeric',
//         hour: '2-digit', minute: '2-digit', hour12: true
//     };
//     return date.toLocaleDateString('en-US', options);
// }

function formatDateString(input: string): string {
    // Extracting year, month, day, etc. from the string
    const year = parseInt(input.substring(0, 4), 10);
    const month = parseInt(input.substring(4, 6), 10) - 1; // Month is 0-indexed in JavaScript
    const day = parseInt(input.substring(6, 8), 10);
    const hour = parseInt(input.substring(8, 10), 10);
    const minute = parseInt(input.substring(10, 12), 10);
    const second = parseInt(input.substring(12, 14), 10);

    // Create a new date object
    const date = new Date(year, month, day, hour, minute, second);

    // Extract date components
    const yyyy = date.getFullYear().toString();
    const MM = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const dd = date.getDate().toString().padStart(2, '0');
    const HH = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    const ss = date.getSeconds().toString().padStart(2, '0');

    // Format the date
    return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
}


// function formatDateWithFixedTime(dateString: string): string {
//     const date = new Date(dateString);
//     const formattedDate = date.toLocaleDateString("en-GB", {
//         day: '2-digit',
//         month: 'long',
//         year: 'numeric'
//     });
//
//     return formattedDate + "\n11:59:59 PM";
// }

function formatDateWithFixedTime(dateString: string): string {
    const date = new Date(dateString);

    // Extract date components
    const yyyy = date.getFullYear().toString();
    const MM = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const dd = date.getDate().toString().padStart(2, '0');
    const HH = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    const ss = date.getSeconds().toString().padStart(2, '0');

    // Format the date
    return `${yyyy}-${MM}-${dd} ${23}:${59}:${59}`;
}


function formatDecimal(inputNumber: string | number, numberOfDecimals: number): string {
    const number = typeof inputNumber === 'string' ? parseFloat(inputNumber) : inputNumber;

    if (isNaN(number)) {
        return 'Invalid number';
    }

    return number.toFixed(numberOfDecimals);
}

export const formatCreateFutureOrder = (
    response: CreateFutureOrderAPIResponseType,
    fxOrderForm: FXOrderFormType
): FXOrderTransactionAcknowledgementTypeForFuture | FXOrderTransactionAcknowledgementType => {
    const intl = getIntlObject();
    const calculateExecutionRateFromTargetRate = (targetRate: string | number, limitMarkup: number):number => {
        const isBuy = fxOrderForm.target.currency === fxOrderForm.fx!.currencies[0]
        const dp = getDpUqBySymbol(fxOrderForm.fx?.symbol!).dp
        const impactToRate = limitMarkup * (10**(-dp))
        if(isBuy){
            return Number(targetRate) - impactToRate
        }else{
            return Number(targetRate) + impactToRate
        }
    }
    if (response.responseCode === FX_ORDER_CONFIRM_STATUS_CODE.SUCCESS) {
        return {
            transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.SUCCESS,
            statusDescription: intl.formatMessage({
                id: "app.page.futureReceipt.statusDescription.success.text",
            }),
            statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.SUCCESS,
            orderId: response.data.orderId,
            transactionDate: moment
                    .utc(response.data.inputDate, "YYYYMMDDHHmmss")
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss"),
            fromAccount: fxOrderForm.bankAccount.source!.id,
            fromAccountName: fxOrderForm.bankAccount.source!.label,
            toAccount: fxOrderForm.bankAccount.target!.id,
            toAccountName: fxOrderForm.bankAccount.target!.label,
            convertedFrom: `${fxOrderForm.source.currency} ${formatDecimal(fxOrderForm.source.amount.value, DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT)}`,
            amountReceived: `${fxOrderForm.target.currency} ${formatDecimal(fxOrderForm.target.amount.value, DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT)}`,
            targetRate: renderRate(fxOrderForm.fx!.currencies[0], fxOrderForm.fx!.currencies[1], fxOrderForm.futureTargetRate!,getDpUqBySymbol(fxOrderForm.fx?.symbol!).dp, getDpUqBySymbol(fxOrderForm.fx?.symbol!).uq),
            executionRate: renderRate(fxOrderForm.fx!.currencies[0], fxOrderForm.fx!.currencies[1], calculateExecutionRateFromTargetRate(fxOrderForm.futureTargetRate!, response.data.limitMarkup),getDpUqBySymbol(fxOrderForm.fx?.symbol!).dp, getDpUqBySymbol(fxOrderForm.fx?.symbol!).uq),
            transactionType: intl.formatMessage({
                id: "app.page.transactionReceipt.transactionType.future.text",
            }),
            conversionFee: `${fxOrderForm.fee.currency} ${formatNumber(
                getConversionFee(),
                DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
            )}`,
            receiptTitle: intl.formatMessage({
                id: "app.page.transactionReceipt.receipt.title",
            }),
            expiryDate: formatDateWithFixedTime(fxOrderForm.expiryDate!)
        }
    } else {
        return {
            transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
            statusDescription: intl.formatMessage({
                id: "app.page.transactionReceipt.statusDescription.fail.text",
            }),
            statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL,
            failureMessage: JSON.stringify({
                referenceId: "",
                //@ts-ignore
                orderId: response.data.orderId,
                "date&time": moment
                    //@ts-ignore
                    .utc(response.data.tradeDate, "YYYYMMDDHHmmss")
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss"),
            }),
        };
    }

}

