import {OrderStatusEnum} from "../enums/OrderStatusEnum";

export interface OrderStatusInfoResponse {
    orderStatus:OrderStatusEnum
    CCY1:string
    CCY2: string,
    expiryDate: string,
    CCY1Amount: string | number,
    CCY2Amount: string | number,
    fxRate: string
}

export interface OrderStatusInfo {
    orderStatus:OrderStatusEnum
    CCY1:string
    CCY2: string,
    expiryDate: Date,
    CCY1Amount: string | number,
    CCY2Amount: string | number,
    fxRate: string
}

export function isOrderStatusInfoReal(item: any): item is OrderStatusInfoReal {
    return 'orderStatus' in item; // Replace 'uniqueProperty' with the actual property that identifies the interface
}


export interface OrderStatusInfoReal {
    "symbol": string,
    "orderType": (1|2),
    "country": string,
    "cancelDate": null | string,
    "orderId": string,
    "purpose": string,
    "dealtCurr": string,
    "forwardMarkup": null|string,
    "buy": boolean,
    "orderStatus": OrderStatusEnum
    "takeProfit": 1,
    "orderState": 2,
    "toAccName": string,
    "tenor": string,
    "quote": null,
    "rate": null,
    "contraAmount": string|null,
    "spotRate": null,
    "limit": string,
    "spotDps": null,
    "toAccNo": string,
    "expireDate": string | null,
    "amount": string,
    "digitalId": string,
    "tradeDate": string | null,
    "valueDate": string,
    "dealType": string,
    "inputDate": string,
    "forwardPort": null,
    "bnmPurPose":string,
    "cancelUser": null,
    "fromAccNo":string,
    "spoMarkup": null,
    "orderTag": string,
    "cust_tier": string,
    "account": string,
    "fromAccName": string,
    "uq":number,
    "dp":number
}

export const DEFAULT_ORDER_STATUS_INFO: OrderStatusInfoReal = {
    symbol: "",
    orderType: 1,  // This will throw an error unless the type includes `null` or the property is made optional.
    country: "",
    cancelDate: null,
    orderId: "",
    purpose: "",
    dealtCurr: "",
    forwardMarkup: null,
    buy: false,
    orderStatus: OrderStatusEnum.ALL, // This is a constant value and cannot be empty or null.
    takeProfit: 1, // This is a constant value and cannot be empty or null.
    orderState: 2, // This is a constant value and cannot be empty or null.
    toAccName: "",
    tenor: "",
    quote: null,
    rate: null,
    contraAmount: null,
    spotRate: null,
    limit: "",
    spotDps: null,
    toAccNo: "",
    expireDate: null,
    amount: "",
    digitalId: "",
    tradeDate: null,
    valueDate: "",
    dealType: "",
    inputDate: "",
    forwardPort: null,
    bnmPurPose: "",
    cancelUser: null,
    fromAccNo: "",
    spoMarkup: null,
    orderTag: "",
    cust_tier: "",
    account: "",
    fromAccName: "",
    uq:0,
    dp:4,
};


