import styled from "styled-components";
import ButtonContainer from "./ButtonContainer";
import { StyledButton } from "./Button";

const StyledFooterButtonContainer = styled(ButtonContainer)`
	padding: 44px 24px 36px;

	> ${StyledButton} {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
`;

const FooterButtonContainer = ({
	...rest
}: React.ComponentPropsWithRef<"div">) => {
	return (
		<StyledFooterButtonContainer
			{...rest}
			isWrappable
			wrapCount={1}
		></StyledFooterButtonContainer>
	);
};

export default FooterButtonContainer;
