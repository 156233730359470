import { getURLQueryValueByKey } from "../../../utils/common.utils";

/**
 * Format URL with query parameters, return the URL including query parameters and token
 * @param {string} baseUrl - Base URL.
 * @param {{ [x: string]: number | string }=} params - URL query.
 * @return {string} The formatted URL.
 */
const formatURL = (
	baseUrl: string,
	params?: { [x: string]: number | string }
) => {
	const token = getURLQueryValueByKey("token") as string;

	let query: string[] = [];
	if (params)
		query = Object.keys(params).map((k) => {
			return `${k}=${params[k].toString()}`;
		});

	return `${baseUrl}?token=${token}${
		query.length > 0 ? `&${query.join("&")}` : ""
	}`;
};

export default formatURL;
