import { DEFAULT_NUMPAD_INPUT_DECIMAL_POINT } from "../../../constants/common.constant";

/**
 * Format NumPad real numeric value to input value
 * @param {number} input - NumPad numeric value.
 * @param {number=} decimal - Specify decimal point.
 * @return {string} The NumPad input value.
 */
const formatNumPadValueToInput = (
	value: number,
	decimal = DEFAULT_NUMPAD_INPUT_DECIMAL_POINT
): string => value.toFixed(Math.max(0, ~~decimal)).replace(".", "");

export default formatNumPadValueToInput;
