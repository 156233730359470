export const MOCK_AVAILABLE_CURRENCIES = [
	{
		ccy: "MYR",
		ccy_name: "Malaysia R",
		keyword: "Malaysia, Ringgit, RM",
		mo_conversion_fee: 0,
		mo_max_amt: 1000,
		mo_min_amt: 1,
		fo_conversion_fee: 0,
		fo_max_amt: 1000,
		fo_min_amt: 1,
	},
	{
		ccy: "USD",
		ccy_name: "United States Dollar",
		keyword: "United States, America, Dollar, $",
		fo_conversion_fee: 0,
		fo_max_amt: 1000,
		fo_min_amt: 1,
		mo_conversion_fee: 0,
		mo_max_amt: 1000,
		mo_min_amt: 1,
	},
];

export const MOCK_ACCOUNTS = [
	{
		key: "123456789011_USD",
		AccountId: "123456789011",
		Name: "Bucket Balance MFCA",
		Label: "Bucket Balance MFCA USD",
		Currency: "USD",
		Value: 1600.5,
		Text: "1,600.50",
		Status: "ACTIVE",
		AccountType: "MFCA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "123456789011_MYR",
		AccountId: "123456789011",
		Name: "Bucket Balance MFCA",
		Label: "Bucket Balance MFCA MYR",
		Currency: "MYR",
		Value: 2600.5,
		Text: "2,600.50",
		Status: "ACTIVE",
		AccountType: "MFCA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "123456789011_EUR",
		AccountId: "123456789011",
		Name: "Bucket Balance MFCA",
		Label: "Bucket Balance MFCA EUR",
		Currency: "EUR",
		Value: 3600.5,
		Text: "3,600.50",
		Status: "ACTIVE",
		AccountType: "MFCA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "123456789011_JPY",
		AccountId: "123456789011",
		Name: "Bucket Balance MFCA",
		Label: "Bucket Balance MFCA JPY",
		Currency: "JPY",
		Value: 4600.5,
		Text: "4,600.50",
		Status: "ACTIVE",
		AccountType: "MFCA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "987654321011_USD",
		AccountId: "987654321011",
		Name: "Bucket Balance G1P",
		Label: "Bucket Balance G1P USD",
		Currency: "USD",
		Value: 100.5,
		Text: "100.50",
		Status: "ACTIVE",
		AccountType: "G1P",
		AccountSubType: "G1A",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "987654321011_MYR",
		AccountId: "987654321011",
		Name: "Bucket Balance G1P",
		Label: "Bucket Balance G1P MYR",
		Currency: "MYR",
		Value: 200.5,
		Text: "200.50",
		Status: "ACTIVE",
		AccountType: "G1P",
		AccountSubType: "G1A",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "987654321011_EUR",
		AccountId: "987654321011",
		Name: "Bucket Balance G1P",
		Label: "Bucket Balance G1P EUR",
		Currency: "EUR",
		Value: 300.5,
		Text: "300.50",
		Status: "ACTIVE",
		AccountType: "G1P",
		AccountSubType: "G1A",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "987654321011_JPY",
		AccountId: "987654321011",
		Name: "Bucket Balance G1P",
		Label: "Bucket Balance G1P JPY",
		Currency: "JPY",
		Value: 400.5,
		Text: "400.50",
		Status: "ACTIVE",
		AccountType: "G1P",
		AccountSubType: "G1A",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "112233445566_USD",
		AccountId: "112233445566",
		Name: "Bucket Balance CA",
		Label: "Bucket Balance CA USD",
		Currency: "USD",
		Value: 1000.5,
		Text: "1,000.50",
		Status: "ACTIVE",
		AccountType: "CA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "112233445566_MYR",
		AccountId: "112233445566",
		Name: "Bucket Balance CA",
		Label: "Bucket Balance CA MYR",
		Currency: "MYR",
		Value: 2000.5,
		Text: "2,000.50",
		Status: "ACTIVE",
		AccountType: "CA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "112233445566_EUR",
		AccountId: "112233445566",
		Name: "Bucket Balance CA",
		Label: "Bucket Balance CA EUR",
		Currency: "EUR",
		Value: 3000.5,
		Text: "3,000.50",
		Status: "ACTIVE",
		AccountType: "CA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
	{
		key: "112233445566_JPY",
		AccountId: "112233445566",
		Name: "Bucket Balance CA",
		Label: "Bucket Balance CA JPY",
		Currency: "JPY",
		Value: 4000.5,
		Text: "4,000.50",
		Status: "ACTIVE",
		AccountType: "CA",
		AccountSubType: "FCA",
		ProductType: "CONVENTIONAL",
	},
];
