import styled from "styled-components";
import FieldLabel from "./FieldLabel";

const FormField = styled.div`
	margin-bottom: 24px;

	${FieldLabel} {
		margin-bottom: 16px;
	}
`;

export default FormField;
