import React, { useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ReactComponent as LineChartIcon } from "../../assets/icons/line-chart.svg";
import { ReactComponent as CandleChartIcon } from "../../assets/icons/candle-chart.svg";
import newsAPI from "../../api/news.api";
import { C5DataApiRequestType } from "../../types/news.type";
import {transformChartDataByUqAndDp} from "./service/transformStringToArray";
import {selectFxConfig} from "../../slice/fxConfig";
import {useAppSelector} from "../../utils/store.utils";
import {getDpUqBySymbol} from "./service/getDpUqBySymbol";

/**
 * Enumeration representing different time periods.
 *
 * @enum {string}
 */
enum PERIOD {
	ONE_D = "1D",
	ONE_W = "1W",
	ONE_M = "1M",
	THREE_M = "3M",
	SIX_M = "6M",
	ONE_Y = "1Y",
}
/**
 * Enumeration for different chart types.
 *
 * @enum {string}
 */
enum CHART_TYPE {
	CANDLESTICK = "candlestick",
	FILL = "fill",
}

enum PERIOD_TYPE {
	HTS = "hts",
	MTS = "mts",
}

enum DATE_FORMAT {
	HTS = "YYYYMMDD",
	MTS = "YYYYMMDDHHmm",
}

const listPeriod = [
	{
		label: "1 D",
		key: PERIOD.ONE_D,
	},
	{
		label: "1 W",
		key: PERIOD.ONE_W,
	},
	{
		label: "1 M",
		key: PERIOD.ONE_M,
	},
	{
		label: "3 M",
		key: PERIOD.THREE_M,
	},
	{
		label: "6 M",
		key: PERIOD.SIX_M,
	},
	{
		label: "1 Y",
		key: PERIOD.ONE_Y,
	},
];

interface PeriodItemProps {
	isActive?: boolean;
}
/**
 * Wrapper component for Chart5.
 *
 * @component
 * @example
 * <Chart5Wrapper>
 *   {/* Your content here *∕}
 * </Chart5Wrapper>
 */
const Chart5Wrapper = styled.div`
	padding: 0 24px;
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: var(--font-weight-semi-bold);
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 36px;
`;

const IconWrapper = styled.div`
	cursor: pointer;
	border-radius: 50%;
	padding: 8px;
	width: 32px;
	height: 32px;
	background-color: var(--card-background-color);
	display: flex;
	align-items: center;
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
`;

const PeriodWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 36px;
	margin-top: 24px;
`;

const PeriodItem = styled.div<PeriodItemProps>`
	font-size: 14px;
	font-weight: 600;
	border-radius: 16px;
	white-space: nowrap;
	${({ isActive }) =>
		isActive
			? "color:#ffffff; background-color:#000;padding: 6px 16px;"
			: "color:var(--text-color-secondary);padding: 6px 12px"}
`;

const IframeWrapper = styled.div`
	display: flex;
	margin-right: -20px;
`;

const Iframe = styled.iframe`
	width: 100%;
	height: 200px;
`;
/**
 * Loads data based on the specified period.
 *
 * @param {string} period - The period for which to load the data.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the info data, chart data, and period type.
 */
function Chart5() {
	const intl = useIntl();
	const params = useParams();
	const chartObj = useRef<HTMLIFrameElement | null>(null);
	const [chartType, setChartType] = useState<CHART_TYPE>(
		CHART_TYPE.CANDLESTICK
	);
	const [period, setPeriod] = useState<PERIOD>(PERIOD.ONE_M);
	const [isChartReady, setIsChartReady] = useState<boolean>(false);
	const fxConfig = useAppSelector(selectFxConfig);

	useEffect(() => {
		window.addEventListener(
			"message",
			(event) => {
				if (typeof event.data === "object") {
					return;
				}
				if (event.data) {
					let data = JSON.parse(event.data); // {source:"db-app", ric:..., token:..., theme:...}
					if (data.source === "labci-chart5" && data.message === "ready") {
						setIsChartReady(true);
					}
				}
			},
			false
		);

	}, []);
	useEffect(() => {
		if(fxConfig.dpUqConfig.length > 0){
			sendMessage();
		}
	}, [isChartReady, fxConfig]);

	/**
	 * Loads data based on the specified period.
	 *
	 * @param {string} period - The period for which to load the data.
	 * @returns {Promise<Object>} - A promise that resolves to an object containing the info data, chart data, and period type.
	 */
	const loadData = async (period: string) => {
		let payloadChart: C5DataApiRequestType = {
			ric: params.symbol ?? "",
			i: "5",
			fr: "202310230900",
			periodType: PERIOD_TYPE.MTS,
		};
		switch (period) {
			case PERIOD.ONE_D:
				payloadChart.i = "5";
				payloadChart.periodType = PERIOD_TYPE.MTS;
				payloadChart.fr = moment().subtract(1, "day").format(DATE_FORMAT.MTS);
				payloadChart.real = 1;
				break;
			case PERIOD.ONE_W:
				payloadChart.i = "60";
				payloadChart.periodType = PERIOD_TYPE.MTS;
				payloadChart.fr = moment().subtract(1, "week").format(DATE_FORMAT.MTS);
				payloadChart.real = 1;
				break;
			case PERIOD.ONE_M:
				payloadChart.i = "d";
				payloadChart.periodType = PERIOD_TYPE.HTS;
				payloadChart.fr = moment().subtract(1, "month").format(DATE_FORMAT.HTS);
				payloadChart.to = moment().format(DATE_FORMAT.HTS);
				break;
			case PERIOD.THREE_M:
				payloadChart.i = "d";
				payloadChart.periodType = PERIOD_TYPE.HTS;
				payloadChart.fr = moment().subtract(3, "month").format(DATE_FORMAT.HTS);
				payloadChart.to = moment().format(DATE_FORMAT.HTS);
				break;
			case PERIOD.SIX_M:
				payloadChart.i = "d";
				payloadChart.periodType = PERIOD_TYPE.HTS;
				payloadChart.fr = moment().subtract(6, "month").format(DATE_FORMAT.HTS);
				payloadChart.to = moment().format(DATE_FORMAT.HTS);
				break;
			case PERIOD.ONE_Y:
				payloadChart.i = "w";
				payloadChart.periodType = PERIOD_TYPE.HTS;
				payloadChart.fr = moment().subtract(1, "year").format(DATE_FORMAT.HTS);
				payloadChart.to = moment().format(DATE_FORMAT.HTS);
				break;
			default:
				payloadChart.i = "5";
				payloadChart.periodType = PERIOD_TYPE.MTS;
				payloadChart.fr = moment().subtract(1, "day").format(DATE_FORMAT.MTS);
				payloadChart.real = 1;
				break;
		}
		const dataChart = await fetchC5Mts(payloadChart);
		const infoChart = await fetchC5Info();
		return {
			infoData: infoChart,
			chartData: "\n" + dataChart,
			periodType: payloadChart.periodType,
		};
	};

	/**
	 * Asynchronously sends a message with chart data to the labci-chart5 iframe.
	 *
	 * @async
	 * @function sendMessage
	 *
	 * @returns {Promise<void>} A Promise that resolves with the completion of the message sending.
	 */
	const sendMessage = async () => {
		if (!isChartReady) return;
		let data = await loadData(period);
		const maxDecimal :number= getDpUqBySymbol(params.symbol!).dp-getDpUqBySymbol(params.symbol!).uq
		// @ts-ignore
		data.infoData![params.symbol].si.dp = maxDecimal //change the dp
		if (data) {
			let message = {
				source: "labci-chart5",
				ric: params.symbol,
				chartData: transformChartDataByUqAndDp(data.chartData,getDpUqBySymbol(params.symbol!).dp,getDpUqBySymbol(params.symbol!).uq),
				periodType: data.periodType,
				infoData: data.infoData,
				chartType,
				period,
			};
			console.log(transformChartDataByUqAndDp(data.chartData,getDpUqBySymbol(params.symbol!).dp,getDpUqBySymbol(params.symbol!).uq),)
			console.log('message', message)
			chartObj?.current?.contentWindow?.postMessage(
				JSON.stringify(message),
				"*"
			);
		}
	};
	/**
	 * Fetches C5 information for a given symbol.
	 *
	 * @async
	 * @function fetchC5Info
	 * @returns {Promise<Object>} The C5 information object.
	 *
	 * @throws {Error} If an error occurs while fetching the C5 information.
	 */
	const fetchC5Info = async () => {
		try {
			let response = await newsAPI.getC5Info(params.symbol);
			return response.data.info;
		} catch (error) {
			console.log(error);
			enqueueSnackbar(
				intl.formatMessage({
					id: "app.page.news.snackbar.getC5Info.message.error",
				}),
				{ variant: "general", mode: "info" }
			);
		}
	};
	/**
	 * Fetches C5 Mts data from the API.
	 *
	 * @param {C5DataApiRequestType} payloadChartRequest - The payload for the API request.
	 * @returns {Promise} - A promise that resolves with the response data.
	 */
	const fetchC5Mts = async (payloadChartRequest: C5DataApiRequestType) => {
		try {
			let response = await newsAPI.getC5Data(payloadChartRequest);
			return response.data;
		} catch (error) {
			console.log(error);
			enqueueSnackbar(
				intl.formatMessage({
					id: "app.page.news.snackbar.getC5Data.message.error",
				}),
				{ variant: "general", mode: "info" }
			);
		}
	};
	useEffect(() => {
		sendMessage();
	}, [period, chartType]);

	return (
		<Chart5Wrapper>
			<HeaderWrapper>
				<Title>
					{intl.formatMessage({ id: "app.page.fx.section.chart.title" })}
				</Title>
				<IconWrapper>
					{chartType === CHART_TYPE.CANDLESTICK ? (
						<LineChartIcon
							onClick={() => {
								setChartType(CHART_TYPE.FILL);
							}}
						/>
					) : (
						<CandleChartIcon
							onClick={() => {
								setChartType(CHART_TYPE.CANDLESTICK);
							}}
						/>
					)}
				</IconWrapper>
			</HeaderWrapper>
			<IframeWrapper>
				<Iframe
					ref={chartObj}
					frameBorder={0}
					src={process.env.PUBLIC_URL + "/chart5.bundle/index.html"}
				></Iframe>
			</IframeWrapper>

			<PeriodWrapper>
				{listPeriod.map((item) => {
					return (
						<PeriodItem
							key={item.key}
							onClick={() => setPeriod(item.key)}
							isActive={item.key === period}
						>
							{item.label}
						</PeriodItem>
					);
				})}
			</PeriodWrapper>
		</Chart5Wrapper>
	);
}

export default Chart5;
