import { forwardRef, useEffect, useState } from "react";
import {
	Dialog,
	DialogProps,
	DialogContent,
	DialogTitle,
	dialogTitleClasses,
	dialogContentClasses,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import moment from "moment";
import htmlParser from "html-react-parser";
import DOMPurify from "dompurify";

import LoadingContainer from "../../styled/LoadingContainer";
import LoadingSpinner from "../../styled/LoadingSpinner";

import { NewsStoryType, NewsType } from "../../../types/news.type";
import { ERROR_DIALOG_CLOSE_DELAY } from "../../../constants/common.constant";
import { DEFAULT_NEWS_DATE_FORMAT } from "../../../constants/news.constant";
import { formatNewsStory } from "../../../formatters/news/api/response";
import newsAPI from "../../../api/news.api";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";

interface NewsDialogProps extends DialogProps {
	newsId: NewsType["id"];
	onClose: () => void;
	onExited: () => void;
}

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const NewsDialogHeaderStartContainer = styled.div`
	min-width: 24px;
	line-height: 1;
`;

const NewsDialogHeaderTitle = styled.div`
	flex: 1 0 auto;
	margin-left: 16px;
	margin-right: 16px;
	font-weight: var(--font-weight-semi-bold);
	text-align: center;
`;

const NewsDialogHeaderEndContainer = styled.div`
	min-width: 24px;
	line-height: 1;
`;

const NewsDialogHeader = styled(DialogTitle)`
	display: flex;
	align-items: center;
	min-height: calc(44px + constant(safe-area-inset-top));
	min-height: calc(44px + env(safe-area-inset-top));
	padding-top: calc(18px + constant(safe-area-inset-top));
	padding-top: calc(18px + env(safe-area-inset-top));
	padding-right: 24px;
	padding-bottom: 18px;
	padding-left: 24px;
	background-color: var(--background-color-primary);
	user-select: none;
`;

const StyledNewsDialog = styled(Dialog)`
	.${dialogTitleClasses.root} {
		display: flex;
		justify-content: space-between;
		min-height: calc(44px + constant(safe-area-inset-top));
		min-height: calc(44px + env(safe-area-inset-top));
		padding-top: calc(18px + constant(safe-area-inset-top));
		padding-top: calc(18px + env(safe-area-inset-top));
		padding-right: 24px;
		padding-bottom: 18px;
		padding-left: 24px;
		font-family: inherit;
		font-size: 16px;
		letter-spacing: normal;

		&.close-only {
			justify-content: flex-end;
		}
	}

	/*.${dialogContentClasses.root} {
		overflow-y: hidden;
	}*/
`;

const NewsDialogContent = styled(DialogContent)`
	&.${dialogContentClasses.root} {
		display: flex;
		flex-direction: column;
		background-color: var(--background-color-primary);
		white-space: pre-wrap;
	}
`;

const NewsDatetime = styled.div`
	color: var(--text-color-secondary);
	font-size: 12px;
	font-weight: var(--font-weight-light);
	line-height: 16px;
`;

const NewsHeadline = styled.h3`
	margin-top: 8px;
	margin-bottom: 24px;
	font-size: 20px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 28px;
`;

const NewsBody = styled.div`
	margin-bottom: 24px;
	color: var(--text-color-primary);
	font-size: 14px;
	font-weight: var(--font-weight-light);
	line-height: 20px;
	overflow: auto;

	pre {
		margin: 0;
		font-family: monospace;

		&:first-of-type {
			margin-top: 16px;
		}
	}
`;

const NewsDialog = ({ newsId, open, onClose, onExited }: NewsDialogProps) => {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [newsStory, setNewsStory] = useState<NewsStoryType | undefined>();

	useEffect(() => {
		newsAPI
			.getStory(newsId)
			.then((response) => {
				setNewsStory(formatNewsStory(response.data.story));
			})
			.catch((error) => {
				console.log(error);
				enqueueSnackbar(
					intl.formatMessage({
						id: "app.page.news.snackbar.getStory.message.error",
					}),
					{ variant: "general", mode: "info" }
				);
				setTimeout(() => onClose(), ERROR_DIALOG_CLOSE_DELAY);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [open]);

	const handleExited = () => {
		setIsLoading(true);
		setNewsStory(undefined);
		onExited();
	};

	return (
		<StyledNewsDialog
			open={open}
			fullScreen
			TransitionComponent={Transition}
			TransitionProps={{ onExited: handleExited }}
			onClose={onClose}
		>
			<NewsDialogHeader>
				<NewsDialogHeaderStartContainer>
					<ArrowLeftIcon className="mbb-icon" onClick={onClose} />
				</NewsDialogHeaderStartContainer>
				<NewsDialogHeaderTitle></NewsDialogHeaderTitle>
				<NewsDialogHeaderEndContainer></NewsDialogHeaderEndContainer>
			</NewsDialogHeader>
			<NewsDialogContent>
				{isLoading || !newsStory ? (
					<LoadingContainer>
						<LoadingSpinner display="block" $centered />
					</LoadingContainer>
				) : (
					<>
						<NewsDatetime>
							{moment
								.utc(newsStory.datetime, "YYYY-MM-DD HH:mm")
								.local()
								.format(DEFAULT_NEWS_DATE_FORMAT)}
						</NewsDatetime>
						<NewsHeadline>
							{typeof newsStory.headline === "string"
								? htmlParser(newsStory.headline)
								: newsStory.headline}
						</NewsHeadline>
						<NewsBody>
							{typeof newsStory.body === "string"
								? htmlParser(
										DOMPurify.sanitize(
											newsStory.body.replaceAll("\n", "<p></p>"),
											{
												USE_PROFILES: { html: true },
											}
										)
								  )
								: newsStory.body}
						</NewsBody>
					</>
				)}
			</NewsDialogContent>
		</StyledNewsDialog>
	);
};

export default NewsDialog;
