/**
 * Checks whether a value is an array or not.
 *
 * @param {any} value - The value to be checked.
 *
 * @return {boolean} - Returns true if the value is an array, otherwise false.
 */
export function isArray(value: any) {
    return Array.isArray(value);
}
