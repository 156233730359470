import {
	NewsStoryAPIResponseType,
	NewsStoryType,
} from "../../../../../types/news.type";

/**
 * Format get news story API response
 * @param {NewsStoryAPIResponseType} response - The get news story API response.
 * @return {NewsStoryType} The formatted news story object.
 */
const formatNewsStory = (
	response: NewsStoryAPIResponseType
): NewsStoryType => ({
	newsId: response.nid,
	headline: response.headline,
	body: response.body,
	datetime: response.dt,
});

export default formatNewsStory;
