import styled from "styled-components";

import CheckboxCheckIcon from "../../assets/icons/checkbox-check.svg";

interface CheckboxProps extends React.ComponentPropsWithRef<"input"> {
	$size?: number | string;
}

const StyledCheckbox = styled.span<CheckboxProps>`
	display: inline-block;
	width: ${(props) =>
		props.$size
			? typeof props.$size === "string"
				? props.$size
				: `${props.$size}px`
			: "20px"};
	height: ${(props) =>
		props.$size
			? typeof props.$size === "string"
				? props.$size
				: `${props.$size}px`
			: "20px"};
	border: 1px solid #000;
	border-radius: 100%;
`;

const CheckboxContainer = styled.span<CheckboxProps>`
	display: inline-block;
	width: ${(props) =>
		props.$size
			? typeof props.$size === "string"
				? props.$size
				: `${props.$size}px`
			: "20px"};
	height: ${(props) =>
		props.$size
			? typeof props.$size === "string"
				? props.$size
				: `${props.$size}px`
			: "20px"};
	line-height: 1;

	> input[type="checkbox"] {
		display: none;

		&:checked + ${StyledCheckbox} {
			position: relative;
			background-color: var(--theme-color-primary);

			&::before {
				content: "";
				position: absolute;
				display: block;
				top: 3px;
				right: 3px;
				bottom: 3px;
				left: 3px;
				background-image: url(${CheckboxCheckIcon});
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
`;

const Checkbox = ({ ...rest }: React.ComponentPropsWithRef<"input">) => {
	return (
		<CheckboxContainer>
			<input type="checkbox" {...rest} />
			<StyledCheckbox />
		</CheckboxContainer>
	);
};

export default Checkbox;
