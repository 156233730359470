import { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { StyledAppHeader } from "./AppHeader";
import NumPad from "./numpad/NumPad";

import { AppType } from "../../types/app.type";
import { NumPadProps } from "../../types/common.type";
import { MIN_APP_HEADER_HEIGHT } from "../../constants/common.constant";
import { useAppDispatch } from "../../utils/store.utils";
import { setIsRootScrollable, updateAppTitle } from "../../slice/app";
import useAppHeader from "../../hooks/useAppHeader";

interface LayoutProps extends Partial<AppType> {
	children: React.ReactNode;
	numPadProps?: NumPadProps;
}

interface MainProps {
	hasBottomAppBar?: boolean;
	isShowingNumPad?: boolean;
}

interface AppContentProps {
	hasNumPad?: boolean;
}

const Main = styled.main<MainProps>`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100%;
	${({ hasBottomAppBar }) => hasBottomAppBar && `padding-bottom: 57px;`}
	${({ isShowingNumPad }) => isShowingNumPad && `overflow-y: hidden;`}
`;

const AppContent = styled.div<AppContentProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: 100%;
	background-color: var(--background-color-primary);
	${({ hasNumPad }) => hasNumPad && "overflow-y: auto;"}

	${StyledAppHeader} {
		padding-top: ${StyledAppHeader}px;
	}
`;

const NumPadContainer = styled.div`
	flex-grow: 1;
`;

const Layout = ({
	title,
	isRootScrollable,
	numPadProps,
	children,
}: LayoutProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const [appContentPaddingTop, setAppContentPaddingTop] = useState<number>(
		MIN_APP_HEADER_HEIGHT
	);
	const [hasAppHeader] = useAppHeader();
	const appHeaderRef = createRef<HTMLDivElement>();
	const safeAreaInsetTop = useRef<string>(
		getComputedStyle(document.documentElement).getPropertyValue(
			"--safe-area-inset-top"
		)
	);

	useEffect(() => {
		if (appHeaderRef.current?.clientHeight)
			setAppContentPaddingTop(appHeaderRef.current.clientHeight);
	}, [appHeaderRef]);

	useEffect(() => {
		if (typeof title !== "undefined" && title !== null)
			dispatch(updateAppTitle(title));
	}, [title]);

	useEffect(() => {
		dispatch(setIsRootScrollable(isRootScrollable === false ? false : true));
	}, [isRootScrollable]);

	return (
		<Main role="main" isShowingNumPad={numPadProps && numPadProps.open}>
			<AppContent
				hasNumPad={numPadProps ? true : false}
				style={{
					paddingTop: hasAppHeader()
						? `calc(${appContentPaddingTop}px + ${safeAreaInsetTop.current})`
						: undefined,
				}}
			>
				{children}
			</AppContent>
			{numPadProps && (
				<NumPadContainer>
					<NumPad {...numPadProps} />
				</NumPadContainer>
			)}
		</Main>
	);
};

Layout.defaultProps = {};

export default Layout;
