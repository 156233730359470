import fxAPI, {ListOrderAPIStateEnum} from "../../../api/fx.api";
import {AxiosResponse} from "axios";
import {GetOrderStatusListResponseType} from "../../../types/fx.type";
import {GET_ORDER_STATUS_LIST_INVALID} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {store} from "../../../store/store"

export const countActiveFO = async () => {
    const maxRetries = store.getState().app.fo_retry + 1
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response: AxiosResponse<GetOrderStatusListResponseType> = await fxAPI.getOrderStatusList(ListOrderAPIStateEnum.Pending);
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) {
                throw {
                    errorMsg: GET_ORDER_STATUS_LIST_INVALID,
                    responseHttpStatus: response.status,
                    responseData: response.data
                } as ErrorObjForToastBar;
            }
            const orderStatusList = response.data.data;
            return orderStatusList.length;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw error;
            }
        }
    }
};
