import styled from "styled-components";
import ItemStartContainer from "./ItemStartContainer";
import ItemEndContainer from "./ItemEndContainer";
import { useState } from "react";

interface StyledItemProps {
	margin?: {
		top?: number | string;
		bottom?: number | string;
		left?: number | string;
		right?: number | string;
	};
	hasBorder?: boolean;
	padding?: {
		top?: number | string;
		bottom?: number | string;
		left?: number | string;
		right?: number | string;
	};
	textWrap?: boolean;
	isHighlighted?: boolean;
}

interface ItemProps
	extends React.ComponentPropsWithRef<"div">,
		Omit<StyledItemProps, "isHighlighted"> {
	hasHighlight?: boolean;
}

export const StyledItem = styled.div<StyledItemProps>`
	display: flex;
	justify-content: space-between;
	color: var(--item-text-color);
	font-size: var(--item-font-size);
	font-weight: var(--item-font-weight);
	line-height: var(--item-line-height);
	${({ textWrap }) => !textWrap && `align-items: center;`}
	${({ margin }) => {
		let marginCSSText = "";
		if (margin && margin.top)
			marginCSSText += `margin-top: ${
				typeof margin.top === "string" ? margin.top : `${margin.top}px`
			};`;
		if (margin && margin.bottom)
			marginCSSText += `margin-bottom: ${
				typeof margin.bottom === "string" ? margin.bottom : `${margin.bottom}px`
			};`;
		if (margin && margin.left)
			marginCSSText += `margin-left: ${
				typeof margin.left === "string" ? margin.left : `${margin.left}px`
			};`;
		if (margin && margin.right)
			marginCSSText += `margin-right: ${
				typeof margin.right === "string" ? margin.right : `${margin.right}px`
			};`;

		return marginCSSText;
	}}
	${({ hasBorder }) =>
		hasBorder &&
		`
		border-bottom: 1px solid var(--bordered-input-border-color);
	`}
	${({ padding }) => {
		let paddingCSSText = "";
		if (padding && padding.top)
			paddingCSSText += `padding-top: ${
				typeof padding.top === "string" ? padding.top : `${padding.top}px`
			};`;
		if (padding && padding.bottom)
			paddingCSSText += `padding-bottom: ${
				typeof padding.bottom === "string"
					? padding.bottom
					: `${padding.bottom}px`
			};`;
		if (padding && padding.left)
			paddingCSSText += `padding-left: ${
				typeof padding.left === "string" ? padding.left : `${padding.left}px`
			};`;
		if (padding && padding.right)
			paddingCSSText += `padding-right: ${
				typeof padding.right === "string" ? padding.right : `${padding.right}px`
			};`;

		return paddingCSSText;
	}}
	${({ isHighlighted }) => isHighlighted && "background-color: rgba(0,0,0,.12);"}

	${ItemStartContainer} {
		${({ textWrap }) =>
			textWrap &&
			`
			overflow: visible;
			white-space: normal;
			text-overflow: clip;
			word-break: break-word;
		`}
	}
`;

const Item = ({
	hasBorder,
	hasHighlight,
	textWrap,
	children,
	...rest
}: ItemProps) => {
	const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

	return (
		<StyledItem
			hasBorder={hasBorder}
			isHighlighted={hasHighlight ? isHighlighted : false}
			textWrap={textWrap}
			onTouchStart={(e) => {
				setIsHighlighted(true);
			}}
			onTouchEnd={(e) => {
				setIsHighlighted(false);
			}}
			{...rest}
		>
			{children}
		</StyledItem>
	);
};

export default Item;
