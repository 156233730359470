import styled from "styled-components";
export const BoxButtonContainer = styled("div")<BoxButtonContainerProps>`
    padding-top: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: ${(props) => props.havePageAndButton ? '0px' : '15px'};
`
interface BoxButtonContainerProps {
    havePageAndButton?: boolean;
    isAtBottom:boolean;
}
