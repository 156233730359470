import styled from "styled-components";

interface TagProps {
	varient: "info";
}

const Tag = styled.div<TagProps>`
	display: inline-block;
	border-radius: 9999px;
	padding: 4px 16px;
	font-size: 12px;
	font-weight: var(--font-weight-semi-bold);
	text-align: center;
	line-height: 16px;

	${({ varient }) => `
    color: var(--tag-info-text-color);
    background-color: var(--tag-info-background-color);
`}
`;

export default Tag;
