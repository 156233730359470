import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Drawer } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import Button from "../styled/buttons/Button";
import Anchor from "../styled/Anchor";

import {
	SelectedPickerOptionType,
	SwiperSlideType,
} from "../../types/common.type";
import {
	DEFAULT_SELECT_DRAWER_PICKER_HEIGHT,
	DEFAULT_SELECT_DRAWER_PICKER_VIEW_ITEMS,
} from "../../constants/common.constant";

import "swiper/css";

interface PickerDrawerProps {
	open: boolean;
	options: {
		[x: string]: SwiperSlideType[];
	};
	selecting: number;
	onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
	onPick: (selectedPickerOption: SelectedPickerOptionType) => void;
}

const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
	}
`;

const DrawerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 24px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
`;

const DrawerDoneButton = styled(Button)`
	line-height: 21px;
`;

const DrawerContent = styled.div`
	mask-image: linear-gradient(
		0,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 15%,
		rgba(255, 255, 255, 1) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	overflow-x: hidden;
`;

const StyledSwiper = styled(Swiper)`
	height: 100%;
	min-height: ${DEFAULT_SELECT_DRAWER_PICKER_HEIGHT}px;
	max-height: ${DEFAULT_SELECT_DRAWER_PICKER_HEIGHT}px;
	overflow: hidden;
	z-index: auto;

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 8px;
		padding-right: 8px;
		font-weight: var(--font-weight-light);

		&.swiper-slide-active {
			font-weight: var(--font-weight-semi-bold);
			background-color: var(--select-drawer-selected-background-color);
		}

		&:not(.swiper-slide-active) {
			color: var(--text-color-secondary);
		}
	}

	&:first-child .swiper-slide {
		padding-left: 16px;
	}

	&:last-child .swiper-slide {
		padding-right: 16px;
	}
`;

const SwiperSlideContent = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const PickerContainer = styled.div`
	display: flex;

	> ${StyledSwiper} {
		flex: 1;
	}
`;

const PickerDrawer = ({
	open,
	options,
	selecting,
	onClose,
	onPick,
}: PickerDrawerProps) => {
	const intl = useIntl();
	const [selectedOptionMap, setSelectedOptionMap] =
		useState<SelectedPickerOptionType>(
			Object.keys(options).reduce<SelectedPickerOptionType>((obj, v) => {
				obj[v] = options[v].length > 0 ? options[v][0].value : null;
				return obj;
			}, {})
		);
	const isInitialized = useRef<boolean>(false);

	useEffect(() => {
		// Fill in default values
		if (isInitialized.current) {
			let key: keyof SelectedPickerOptionType;
			let updatedSelectedOptionMap = JSON.parse(
				JSON.stringify(selectedOptionMap)
			);
			for (key in selectedOptionMap) {
				if (selectedOptionMap[key] === null) {
					updatedSelectedOptionMap[key] = options[key][0].value;
				}
			}
			setSelectedOptionMap(updatedSelectedOptionMap);
		} else isInitialized.current = true;
	}, [open]);

	/**
	 * Updates the selected option map when a slide change event occurs.
	 *
	 * @param {string} column - The column to update in the selected option map.
	 * @param {*} value - The new value for the specified column.
	 */
	const onSlideChange = (column: string, value: any) => {
		setSelectedOptionMap({ ...selectedOptionMap, [column]: value });
	};

	/**
	 * Handles the pick of an option.
	 *
	 * @param {React.MouseEvent<HTMLButtonElement>} event - The event triggered by the pick.
	 * @returns {void}
	 */
	const handlePick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPick(selectedOptionMap);
		onClose(event);
	};

	return (
		<StyledDrawer anchor="bottom" open={open} onClose={onClose}>
			<DrawerHeader>
				<Anchor onClick={onClose}>
					{intl.formatMessage({ id: "app.common.cancel" })}
				</Anchor>
				<DrawerDoneButton theme="primary" size="sm" onClick={handlePick}>
					{intl.formatMessage({ id: "app.common.done" })}
				</DrawerDoneButton>
			</DrawerHeader>
			<DrawerContent>
				<PickerContainer>
					{options &&
						Object.keys(options).length > 0 &&
						Object.keys(options).map((column, i) => (
							<StyledSwiper
								key={`picker-${column}-${i}`}
								direction="vertical"
								height={DEFAULT_SELECT_DRAWER_PICKER_HEIGHT}
								slidesPerView={DEFAULT_SELECT_DRAWER_PICKER_VIEW_ITEMS}
								spaceBetween={0}
								centeredSlides={true}
								slideToClickedSlide={true}
								initialSlide={selecting}
								onSlideChangeTransitionEnd={(swiper: any) => {
									onSlideChange(
										column,
										options[column][swiper.realIndex].value
									);
								}}
							>
								{options[column] &&
									options[column].length > 0 &&
									options[column].map((v, j) => (
										<SwiperSlide key={`picker-${column}-slide-${j}`}>
											<SwiperSlideContent>{v.label}</SwiperSlideContent>
										</SwiperSlide>
									))}
							</StyledSwiper>
						))}
				</PickerContainer>
			</DrawerContent>
		</StyledDrawer>
	);
};

export default PickerDrawer;
