import styled from "styled-components";
import SectionHeaderStartContainer from "./SectionHeaderStartContainer";
import SectionHeaderEndContainer from "./SectionHeaderEndContainer";

interface SectionHeaderProps {
	hasMarginBottom?: boolean;
	textWrap?: boolean;
}

const SectionHeader = styled.div<SectionHeaderProps>`
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--section-header-margin-bottom);
	padding-left: 24px;
	padding-right: 24px;
	color: var(--section-header-text-color);
	font-size: var(--section-header-font-size);
	font-weight: var(--section-header-font-weight);

	${({ textWrap }) => !textWrap && `align-items: center;`}

	${({ hasMarginBottom }) =>
		hasMarginBottom &&
		`
		margin-bottom: 8px;
	`}

	${SectionHeaderStartContainer} {
		${({ textWrap }) =>
			textWrap &&
			`
			overflow: visible;
			white-space: normal;
			text-overflow: clip;
			word-break: break-word;
		`}
	}
`;

export default SectionHeader;
