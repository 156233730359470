import styled from "styled-components";

const AlertDialogCloseButton = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 4px;

	.icon-close {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
`;

export default AlertDialogCloseButton;
