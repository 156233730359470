import { FXType } from "../../../types/fx.type";

/**
 * Get FX rate by dealt currency
 * @param {string} dealt - Dealt currency (in a nutshell, the currency of user that last edited).
 * @param {FXType} fxObject - Trade currency type.
 * @return {number|null} The FX rate.
 */
const getRateByDealt = (dealt: string, fxObject?: FXType): number | null => {
	if (
		!fxObject ||
		fxObject.currencies.indexOf(dealt) < 0 ||
		!fxObject.ask ||
		!fxObject.bid
	)
		return null;

	if (fxObject.currencies[0] === dealt) {
		return Math.max(0, fxObject.bid);
	} else {
		return Math.max(0, fxObject.ask);
	}
};

export default getRateByDealt;
