import { useState } from "react";
import styled from "styled-components";

import Checkbox from "../../common/Checkbox";
import FXNationalFlag, {
	StyledFXNationalFlag,
} from "../../common/FXNationalFlag";

import { COUNTRY_CURRENCY_MAP } from "../../../constants/fx.constant";
import { FXType } from "../../../types/fx.type";
import { useIntl } from "react-intl";

interface FXCheckboxItemProps {
	id: string;
	fxObject: FXType;
	checked: boolean;
	onChange: (fxObject: FXType, checked: boolean) => void;
}

export const StyledFXCheckboxItem = styled.label`
	display: flex;
	align-items: center;
	margin-left: 24px;
	margin-right: 24px;
	border-bottom: 1px solid var(--bordered-input-border-color);
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 18px;

	> ${StyledFXNationalFlag} {
		margin-right: 16px;
	}
`;

const FXCheckboxItemText = styled.div`
	flex: 1 0 auto;
`;

const FXCheckboxItem = ({
	id,
	checked,
	fxObject,
	onChange,
}: FXCheckboxItemProps) => {
	const intl = useIntl();

	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(fxObject, e.currentTarget.checked);
	};

	return (
		<StyledFXCheckboxItem htmlFor={id}>
			<FXNationalFlag
				source={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[0].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				target={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[1].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				width={48}
				height={48}
			/>
			<FXCheckboxItemText>
				{intl.formatMessage(
					{ id: "app.common.fx" },
					{ source: fxObject.currencies[0], target: fxObject.currencies[1] }
				)}
			</FXCheckboxItemText>
			<Checkbox id={id} checked={checked} onChange={handleCheck} />
		</StyledFXCheckboxItem>
	);
};

export default FXCheckboxItem;
