import React, { useState } from "react";
import styled from "styled-components";

interface StyledNumPadKeyProps {
	isHighlighted?: boolean;
}

interface NumPadKeyProps extends React.ComponentPropsWithRef<"div"> {
	hasHighlight?: boolean;
}

const StyledNumPadKey = styled.div<StyledNumPadKeyProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: 33.3333%;
	padding-top: 18px;
	padding-bottom: 18px;
	font-size: 24px;
	font-weight: var(--font-weight-light);
	line-height: 23px;
	overflow: hidden;
	transition: color 0.15s var(--common-animation-curve);

	${({ isHighlighted }) => isHighlighted && "color: #d9aa00;"}
`;

const NumPadKey = ({ hasHighlight, children, ...rest }: NumPadKeyProps) => {
	const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

	return (
		<StyledNumPadKey
			isHighlighted={hasHighlight ? isHighlighted : false}
			onTouchStart={(e) => {
				setIsHighlighted(true);
			}}
			onTouchEnd={(e) => {
				setIsHighlighted(false);
			}}
			{...rest}
		>
			{children}
		</StyledNumPadKey>
	);
};

export default NumPadKey;
