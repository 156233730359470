import { useState } from "react";
import styled from "styled-components";

export interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
	theme?: "primary" | "secondary" | "red" | "green";
	size?: "lg" | "md" | "sm";
	width?: number | string;
	children: React.ReactNode;
}

interface StyledButtonProps {
	theme?: "primary" | "secondary" | "red" | "green";
	size?: "lg" | "md" | "sm";
	width?: number | string;
}

export const StyledButton = styled.button<StyledButtonProps>`
	min-width: 60px;
	border: var(--button-border-width) solid var(--button-border-color);
	border-radius: var(--button-border-radius);
	color: var(--button-text-color);
	font-weight: var(--button-font-weight);
	cursor: pointer;
	user-select: none;
	font-family: var(--font-family);

	${({ width }) =>
		width && `width: ${typeof width === "string" ? width : `${width}px`};`}

	${({ size }) => {
		switch (size) {
			case "lg":
				return `
					padding: var(--button-lg-padding-y) var(--button-lg-padding-x);
					font-size: var(--button-lg-font-size);
				`;
			case "sm":
				return `
					padding: var(--button-sm-padding-y) var(--button-sm-padding-x);
					font-size: var(--button-sm-font-size);
				`;
			default:
				return `
					padding: var(--button-md-padding-y) var(--button-md-padding-x);
					font-size: var(--button-md-font-size);
				`;
		}
	}}

	${({ theme }) => {
		switch (theme) {
			case "secondary":
				return `
					border: var(--button-border-width) solid var(--button-border-color);
					color: var(--button-secondary-text-color);
					background-color: var(--button-secondary-background-color);
				`;
			case "red":
				return `
					color: var(--button-red-text-color);
					background-color: var(--button-red-background-color);
				`;
			case "green":
				return `
					color: var(--button-green-text-color);
					background-color: var(--button-green-background-color);
				`;
			default:
				return `
					color: var(--button-primary-text-color);
					background-color: var(--button-primary-background-color);
				`;
		}
	}}

	&:disabled {
		${({ theme }) => {
			switch (theme) {
				case "secondary":
					return `
					border: var(--button-border-width) solid var(--button-border-color);
					color: var(--button-secondary-text-color);
					background-color: var(--button-secondary-background-color);
				`;
				case "red":
					return `
					color: var(--button-red-text-color);
					background-color: var(--button-red-background-color);
				`;
				case "green":
					return `
					color: var(--button-green-text-color);
					background-color: var(--button-green-background-color);
				`;
				default:
					return `
					color: var(--button-primary-disabled-text-color);
					background-color: var(--button-primary-disabled-background-color);
				`;
			}
		}}
	}
`;

const Button = ({ size, children, ...rest }: ButtonProps) => {
	return (
		<StyledButton
			size={size}
			onTouchStart={(e) => {
				//setIsHighlighted(true);
			}}
			onTouchEnd={(e) => {
				//setIsHighlighted(false);
			}}
			onTouchMove={(e) => {
				//setIsHighlighted(false);
			}}
			{...rest}
		>
			{children}
		</StyledButton>
	);
};

export default Button;
