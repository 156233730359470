export function transformChartDataByUqAndDp(dataStr: string, dp: number, uq: number): string {
    const multiplier = uq === 0 ? 1 : 10 ** uq;
    // const multiplier = 0.1
    const maxDecimal = dp-uq
    // const maxDecimal = 10
    // Split the string by newlines and process each line
    const transformedArray = dataStr.trim().split("\n").map(line => {
        // Split the line by commas and process each value
        return line.split(",").map((value, index) => {
            // Convert the date to an integer, multiply specific values by 100, and keep item[5] and item[6] unchanged
            if (index === 0) {
                return parseInt(value);
            } else if (index >= 1 && index <= 4) {
                return parseFloat(value) * multiplier;
            } else {
                return parseFloat(value);
            }
        });
    });

    // Convert the array of arrays back to the original string format
    const transformedString = "\n" + transformedArray.map(lineArray => {
        return lineArray.map((value, index) => {
            // Ensure the date is an integer, other values are fixed to 4 decimal places, and keep item[5] and item[6] unchanged
            if (index === 0) {
                return value.toString();
            } else if (index >= 1 && index <= 4) {
                return value.toFixed(maxDecimal);
            } else {
                return value.toString();
            }
        }).join(",");
    }).join("\n");

    return transformedString;
}
