import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { debounce } from "@mui/material";
import { useIntl } from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import { useNetworkState } from "@uidotdev/usehooks";
import { useSnackbar } from "notistack";

import RequireAuthenticate from "../../components/common/RequireAuthenticate";
import Layout from "../../components/common/Layout";
import LoadingContainer from "../../components/styled/LoadingContainer";
import LoadingSpinner from "../../components/styled/LoadingSpinner";
import Section from "../../components/styled/sections/Section";
import SectionContent from "../../components/styled/sections/SectionContent";
import SearchBar from "../../components/common/SearchBar";
import EmptyHeader from "../../components/styled/EmptyHeader";
import EmptyMessage from "../../components/styled/EmptyMessage";
import NewsCardList from "../../components/common/NewsCardList";

import { NewsGetPayloadType, NewsType } from "../../types/news.type";
import { API_DATA_DIRECTION } from "../../constants/common.constant";
import {
	DEFAULT_NEWS_PER_PAGE,
	DEFAULT_NEWS_UPDATE_INTERVAL,
	NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH,
} from "../../constants/news.constant";
import { getRealTextLength } from "../../utils/common.utils";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { formatGetNewsPayload } from "../../formatters/news/api/request";
import { formatNews } from "../../formatters/news/api/response";
import newsAPI from "../../api/news.api";
import {selectNewsUpdateInterval} from "../../slice/app";
import {useAppSelector} from "../../utils/store.utils";

interface LastTickDataType {
	newsList: NewsType[];
	searchText: string;
	scrollContainerScrollTop: number;
}

interface NewsLoadMoreOptionsType {
	direction: API_DATA_DIRECTION;
	newsId: string;
	datetime: string;
	currentFXNews: {
		all: NewsType[];
		single: NewsType[];
	};
}

const NewsCardListContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow-y: auto;
`;

const ScrollableContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow-y: auto;

	/*${Section} {
		height: 100%;
	}

	${SectionContent} {
		height: 100%;
	}*/
`;

let fetchNewsInterval: ReturnType<typeof setInterval>;

const NewsPage = () => {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const networkState = useNetworkState();
	const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fxNewsListSingle, setFXNewsListSingle] = useState<NewsType[]>([]);
	const [fxNewsListAll, setFXNewsListAll] = useState<NewsType[]>([]);
	const [fxNews, setFXNews] = useState<NewsType[]>(
		JSON.parse(JSON.stringify(fxNewsListSingle))
	);
	const [pendingFXNews, setPendingFXNews] = useState<NewsType[]>([]);
	const [searchText, setSearchText] = useState<string>("");
	const [hasMore, setHasMore] = useState<boolean>(true);
	//const isInitialized = useRef<boolean>(false);
	const lastTickData = useRef<LastTickDataType>({
		newsList: fxNewsListSingle,
		searchText,
		scrollContainerScrollTop: 0,
	});
	// const intervalTime = useMemo(() => {
	// 	return isNaN(parseInt(process.env.REACT_APP_NEWS_UPDATE_INTERVAL))
	// 		? DEFAULT_NEWS_UPDATE_INTERVAL
	// 		: parseInt(process.env.REACT_APP_NEWS_UPDATE_INTERVAL);
	// }, []);
	const intervalTime = useAppSelector(selectNewsUpdateInterval);
	const [scrollableContainerRef, isFetchingMore, setIsFetchingMore] =
		useInfiniteScroll(
			() => {
				if (
					(getRealTextLength(searchText) <
						NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH &&
						fxNewsListSingle.length > 0) ||
					(getRealTextLength(searchText) >=
						NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH &&
						fxNewsListAll.length > 0)
				) {
					fetchNews(searchText, {
						direction: API_DATA_DIRECTION.OLD,
						newsId:
							getRealTextLength(searchText) <
							NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
								? fxNewsListSingle[fxNewsListSingle.length - 1].id
								: fxNewsListAll[fxNewsListAll.length - 1].id,
						datetime:
							getRealTextLength(searchText) <
							NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
								? fxNewsListSingle[fxNewsListSingle.length - 1].datetime
								: fxNewsListAll[fxNewsListAll.length - 1].datetime,
						currentFXNews: { all: fxNewsListAll, single: fxNewsListSingle },
					});
				}
			},
			hasMore,
			100
		);

	/*useLayoutEffect(() => {
		if (scrollableContainerRef.current) {
			if (
				getRealTextLength(searchText.trim()) <
					getRealTextLength(lastTickData.current.searchText) &&
				getRealTextLength(lastTickData.current.searchText) >=
					NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH &&
				getRealTextLength(searchText.trim()) <
					NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
			) {
				console.log(
					"SCROLL BACK",
					lastTickData.current.scrollContainerScrollTop,
					scrollableContainerRef.current.scrollTop
				);

				scrollableContainerRef.current.scrollTo({
					top: lastTickData.current.scrollContainerScrollTop,
					behavior: "smooth",
				});
			}

			lastTickData.current = {
				newsList: fxNews,
				searchText,
				scrollContainerScrollTop:
					getRealTextLength(searchText.trim()) <
					NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
						? scrollableContainerRef.current.scrollTop
						: lastTickData.current.scrollContainerScrollTop,
			};
		}
	}, [fxNews]);*/

	useEffect(() => {
		fetchNews(searchText);
	}, []);

	useEffect(() => {
		if (fetchNewsInterval) clearInterval(fetchNewsInterval);
		if (
			networkState.online &&
			getRealTextLength(searchText) < NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
		) {
			fetchNewsInterval = setInterval(() => {
				fetchLatestNews();
			}, intervalTime);
		}

		return () => clearInterval(fetchNewsInterval);
	}, [networkState.online, searchText, fxNews, pendingFXNews]);

	useEffect(() => {
		//console.log("useEffect [fxNewsListAll, fxNewsListSingle]");
		if (getRealTextLength(searchText) < NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH)
			setFXNews(fxNewsListSingle);
		else setFXNews(fxNewsListAll);
	}, [fxNewsListAll, fxNewsListSingle]);

	useEffect(() => {
		if (scrollableContainerRef.current) {
			scrollableContainerRef.current.addEventListener(
				"scroll",
				debouncedHandleScrollToTop
			);
		}

		handleScrollToTop();

		return () => {
			if (scrollableContainerRef.current) {
				scrollableContainerRef.current.removeEventListener(
					"scroll",
					debouncedHandleScrollToTop
				);
			}
		};
	}, [scrollableContainerRef.current, searchText, pendingFXNews]);

	const fetchNews = useCallback(
		(currentSearchText: string, loadMoreOptions?: NewsLoadMoreOptionsType) => {
			let payload: NewsGetPayloadType = { limit: DEFAULT_NEWS_PER_PAGE };
			if (
				getRealTextLength(currentSearchText) >=
				NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
			) {
				//console.log("SEARCH WITH KEYWORD: ", currentSearchText);
				payload.keyword = currentSearchText;
			}

			if (loadMoreOptions) {
				payload.lastNewsId = loadMoreOptions.newsId || "";
				payload.lastNewsDatetime = loadMoreOptions.datetime || "";
			}

			newsAPI
				.get(formatGetNewsPayload(payload))
				.then((response) => {
					//console.log(response.data);
					if (response.data.status !== 0) response.data.data = [];

					if (response.data.data.length < DEFAULT_NEWS_PER_PAGE)
						setHasMore(false);

					if (
						getRealTextLength(currentSearchText) <
						NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
					) {
						setFXNewsListSingle(
							loadMoreOptions
								? loadMoreOptions.currentFXNews.single.concat(
										formatNews(response.data.data)
								  )
								: formatNews(response.data.data)
						);
					} else {
						setFXNewsListAll(
							loadMoreOptions
								? loadMoreOptions.currentFXNews.all.concat(
										formatNews(response.data.data)
								  )
								: formatNews(response.data.data)
						);
					}

					if (response.data.status !== 0) {
						throw new Error(
							response.data.msg ||
								intl.formatMessage({ id: "app.common.message.error" })
						);
					}
				})
				.catch((error) => {
					console.error(error);
					enqueueSnackbar(
						intl.formatMessage({
							id: "app.page.news.snackbar.get.message.error",
						}),
						{ variant: "general", mode: "info" }
					);
				})
				.finally(() => {
					setIsFirstLoading(false);
					setIsLoading(false);
					if (loadMoreOptions) setIsFetchingMore(false);
				});
		},
		[]
	);

	const debouncedFetchNews = useMemo(() => {
		return debounce(fetchNews, 500);
	}, [fetchNews]);

	/**
	 * Fetches the latest news from the newsAPI and updates the pendingFXNews state.
	 *
	 * @function fetchLatestNews
	 * @returns {void}
	 * @throws {Error} Throws an error if there is an issue with the API response or request.
	 */
	const fetchLatestNews = () => {
		if (!fxNewsListSingle || fxNewsListSingle.length < 1) return;

		const lastNews =
			pendingFXNews.length > 0 ? pendingFXNews[0] : fxNewsListSingle[0];

		newsAPI
			.get(
				formatGetNewsPayload({
					limit: DEFAULT_NEWS_PER_PAGE,
					lastNewsId: lastNews.id,
					lastNewsDatetime: lastNews.datetime,
					direction: API_DATA_DIRECTION.NEW,
				})
			)
			.then((response) => {
				//console.log(response.data);
				if (response.data.status !== 0)
					throw new Error(
						response.data.msg ||
							intl.formatMessage({ id: "app.common.message.error" })
					);

				if (response.data.data.length > 0) {
					const formatted = formatNews(response.data.data);
					setPendingFXNews(formatted.concat(pendingFXNews));
				}
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(
					intl.formatMessage({
						id: "app.page.news.snackbar.getLatest.message.error",
					}),
					{ variant: "general", mode: "info" }
				);
			});
	};

	/**
	 * Handles the change event of the search bar and updates the search text state.
	 * Also sets the hasMore state to true.
	 * If the length of the search text is less than the NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH,
	 * it sets the FXNews state to fxNewsListSingle.
	 * Otherwise, it sets the isLoading state to true and triggers the debounced news fetching function.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
	 */
	const handleSearchBarChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchText(event.currentTarget.value);
		setHasMore(true);
		if (
			getRealTextLength(event.currentTarget.value) <
			NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
		) {
			setFXNews(fxNewsListSingle);
		} else {
			setIsLoading(true);
			/*setFXNews([]);
			setFXNewsListAll([]);*/
			debouncedFetchNews(event.currentTarget.value);
		}
	};

	/**
	 * Clears the search text and resets the state variables.
	 *
	 * @function handleSearchTextClear
	 */
	const handleSearchTextClear = () => {
		setSearchText("");
		setHasMore(true);
		setFXNewsListAll([]);
		setFXNews(fxNewsListSingle);
	};

	/**
	 * Function to handle prepending a new headline to the FX news list.
	 *
	 * This function checks if there are pending FX news items and if the real text length of the search text is less than the NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH. If both conditions are
	 * met, it scrolls the scrollable container to the top using the scrollableContainerRef, concatenates the pendingFXNews with fxNewsListSingle using setFXNewsListSingle, and sets the pending
	 *FXNews to an empty array using setPendingFXNews.
	 *
	 * @function handlePrependNewHeadline
	 */
	const handlePrependNewHeadline = () => {
		if (
			pendingFXNews.length > 0 &&
			getRealTextLength(searchText) < NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
		) {
			if (scrollableContainerRef.current)
				scrollableContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
			setFXNewsListSingle(pendingFXNews.concat(fxNewsListSingle));
			setPendingFXNews([]);
		}
	};

	/**
	 * Handles scrolling to top of the scrollable container.
	 *
	 * If the scrollable container is available and the scrollTop is less than or equal to 0,
	 * this function will trigger a callback to prepend a new headline.
	 *
	 * @function handleScrollToTop
	 */
	const handleScrollToTop = () => {
		if (
			scrollableContainerRef.current &&
			scrollableContainerRef.current.scrollTop <= 0
		) {
			handlePrependNewHeadline();
		}
	};

	const debouncedHandleScrollToTop = debounce(handleScrollToTop, 100);

	/**
	 * Scrolls the scrollable container to the last position specified by the offset.
	 *
	 * @param {Object} offset - The offset object containing the x and y coordinates.
	 * @param {number} offset.x - The x coordinate.
	 * @param {number} offset.y - The y coordinate.
	 * @returns {void}
	 */
	const scrollToLastPosition = (offset: { x?: number; y?: number }) => {
		if (scrollableContainerRef.current) {
			scrollableContainerRef.current.scrollTo(offset);
		}
	};

	return (
		<RequireAuthenticate>
			<Layout title={intl.formatMessage({ id: "app.page.news.header.title" })}>
				<Helmet>
					<title>
						{intl.formatMessage({
							id: "app.page.news.header.title",
						})}
					</title>
				</Helmet>
				<SearchBar
					value={searchText}
					onClear={handleSearchTextClear}
					inputProps={{
						placeholder: intl.formatMessage({
							id: "app.page.news.input.search.placeholder",
						}),
						onChange: handleSearchBarChange,
					}}
				/>
				<NewsCardListContainer>
					<ScrollableContainer ref={scrollableContainerRef}>
						{(getRealTextLength(searchText) >=
							NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH &&
							isLoading) ||
						isFirstLoading ? (
							<LoadingContainer>
								<LoadingSpinner display="block" $centered />
							</LoadingContainer>
						) : (
							<Section hasMarginBottom>
								<SectionContent hasPaddingX>
									{/*getRealTextLength(searchText) <
								NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH ? (
									<NewsCardList newsList={fxNewsListSingle} grouped />
								) : (
									<NewsCardList
										newsList={fxNewsListAll}
										highlightBy={searchText}
									/>
								)*/}
									{fxNews.length > 0 ? (
										<>
											<NewsCardList
												newsList={fxNews}
												highlightBy={
													getRealTextLength(searchText) >=
													NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
														? searchText
														: undefined
												}
												grouped={
													getRealTextLength(searchText) <
													NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH
												}
											/>
											{isFetchingMore && hasMore && (
												<LoadingSpinner display="block" $centered />
											)}
										</>
									) : (
										<>
											<EmptyHeader marginTop={50}>
												{intl.formatMessage({
													id: "app.page.news.message.empty.header",
												})}
											</EmptyHeader>
											<EmptyMessage>
												{intl.formatMessage({
													id: "app.page.news.message.empty.content",
												})}
											</EmptyMessage>
										</>
									)}
								</SectionContent>
							</Section>
						)}
					</ScrollableContainer>
				</NewsCardListContainer>
			</Layout>
		</RequireAuthenticate>
	);
};

export default NewsPage;
