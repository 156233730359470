import { forwardRef, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import FieldLabel from "../styled/FieldLabel";
import FormField from "../styled/FormField";
import FormFieldRow from "../styled/FormFieldRow";
import InputField, { InputFieldContainer } from "../common/InputField";
import SelectField, { SelectFieldContainer } from "../common/SelectField";

import { FX_TRADE_CURRENCY_TYPE } from "../../constants/fx.constant";
import formatNumPadInputToField from "../../formatters/common/formatNumPadInputToField";
import { useAppDispatch, useAppSelector } from "../../utils/store.utils";
import { selectFXOrderForm } from "../../slice/fx";

interface SourceFormFieldProps {
	onSourceInputRefChange: (sourceInputElement: Element) => void;
	onCurrencyClick: (type: FX_TRADE_CURRENCY_TYPE) => void;
	onAmountClick: () => void;
}

const SourceFormFieldRow = styled(FormFieldRow)`
	${SelectFieldContainer} {
		width: 83px;
		margin-right: 5px;
	}

	${InputFieldContainer} {
		flex: 1;
	}
`;

const SourceFormField = forwardRef<HTMLInputElement, SourceFormFieldProps>(
	({ onSourceInputRefChange, onCurrencyClick, onAmountClick }, ref) => {
		const intl = useIntl();
		const fxOrderForm = useAppSelector(selectFXOrderForm);
		const dispatch = useAppDispatch();
		const sourceCurrencyInputRef = useRef<HTMLInputElement | null>(null);

		useEffect(() => {
			const handleSourceInputFocus = () => {
				onSourceInputRefChange(sourceCurrencyInputRef.current as Element);
			};

			sourceCurrencyInputRef.current?.addEventListener(
				"focusin",
				handleSourceInputFocus
			);

			return () => {
				sourceCurrencyInputRef.current?.removeEventListener(
					"focusin",
					handleSourceInputFocus
				);
			};
		}, [sourceCurrencyInputRef.current]);

		/**
		 * Function to handle currency select click event.
		 * @param {React.MouseEvent<HTMLElement>} event - The click event object.
		 */
		const handleCurrencySelectClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			event.preventDefault();
			event.stopPropagation();
			onCurrencyClick(FX_TRADE_CURRENCY_TYPE.SOURCE);
		};

		/**
		 * Executes the onAmountClick function.
		 *
		 * @function handleAmountClick
		 * @returns {void}
		 */
		const handleAmountClick = () => {
			onAmountClick();
		};

		return (
			<FormField>
				<FieldLabel id="from-currency-text" hasMarginBottom>
					{intl.formatMessage({
						id: "app.page.orderForm.form.order.label.from",
					})}
				</FieldLabel>
				<SourceFormFieldRow>
					<SelectField fullWidth onClick={handleCurrencySelectClick}>
						{fxOrderForm.source.currency}
					</SelectField>
					<InputField
						id={"from-currency-input"}
						value={formatNumPadInputToField(
							fxOrderForm.source.amount.input,
							true
						)}
						fullWidth
						inputProps={{
							type: "text",
							ref: (node) => {
								sourceCurrencyInputRef.current = node;
								if (typeof ref === "function") {
									ref(node);
								} else if (ref) {
									ref.current = node;
								}
							},
							readOnly: true,
							onClick: handleAmountClick,
						}}
						helperText={fxOrderForm.source.amount.helper?.text}
						helperTextProps={{
							type: fxOrderForm.source.amount.helper?.type,
						}}
					/>
				</SourceFormFieldRow>
			</FormField>
		);
	}
);

export default SourceFormField;
