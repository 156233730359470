import { FXType, SortableFXType } from "../../../types/fx.type";

/**
 * Format FX list to sortable FX list
 * @param {Array<FXType>} fxList - FX list.
 * @return {Array<SortableFXType>} The sortable FX list.
 */
const formatFXListToSortable = (fxList: FXType[]): SortableFXType[] =>
	fxList.map((v) => ({
		...v,
		id: v.symbol,
	}));

export default formatFXListToSortable;
