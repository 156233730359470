export const MAE_APP_BACK_TO_APP_EVENT_NAME = "mae_back";
export const MAE_APP_CLOSE_APP_EVENT_NAME = "mae_close";
export const MAE_APP_EMIT_ERROR_EVENT_NAME = "mae_error";
export const MAE_APP_CONTACT_BANK_EVENT_NAME = "mae_contact_bank";
export const MAE_APP_OPEN_ACCOUNT_EVENT_NAME = "mae_open_account";
export const MAE_APP_PASSWORD_EVENT_NAME = "mae_password";
export const MAE_APP_RSA_EVENT_NAME = "mae_rsa";
export const MAE_APP_TRANSACTION_ACK_EVENT_NAME = "mae_acknowledgement";
export const MAE_APP_RETRY_EVENT_NAME = "mae_retry";
export const MAE_APP_OPEN_TNC_VIEW_EVENT_NAME = "mae_terms_and_conditions";
export enum MAE_APP_EVENT_TYPE {
	BACK_TO_APP = MAE_APP_BACK_TO_APP_EVENT_NAME,
	CLOSE_APP = MAE_APP_CLOSE_APP_EVENT_NAME,
	ERROR = MAE_APP_EMIT_ERROR_EVENT_NAME,
	CONTACT_BANK = MAE_APP_CONTACT_BANK_EVENT_NAME,
	OPEN_ACCOUNT = MAE_APP_OPEN_ACCOUNT_EVENT_NAME,
	PASSWORD = MAE_APP_PASSWORD_EVENT_NAME,
	RSA = MAE_APP_RSA_EVENT_NAME,
	TRANSACTION_ACK = MAE_APP_TRANSACTION_ACK_EVENT_NAME,
	RETRY = MAE_APP_RETRY_EVENT_NAME,
	OPEN_TNC = MAE_APP_OPEN_TNC_VIEW_EVENT_NAME,
}

export const MAE_APP_PASSWORD_RESPONSE_EVENT_NAME = "mae_password_response";
export const MAE_APP_RSA_RESPONSE_EVENT_NAME = "mae_rsa";
export enum MAE_APP_RESPONSE_EVENT_TYPE {
	PASSWORD = MAE_APP_PASSWORD_RESPONSE_EVENT_NAME,
	RSA = MAE_APP_RSA_RESPONSE_EVENT_NAME,
}
