export function formatCurrencyPairAddSlash(currencyPair:string, currency:string):string {
    // Check if the currency is at the beginning or end of the currencyPair string
    if (currencyPair.startsWith(currency)) {
        return currency + '/' + currencyPair.replace(currency, '');
    } else if (currencyPair.endsWith(currency)) {
        return currencyPair.replace(currency, '') + '/' + currency;
    } else {
        return "{currencyPair}"
    }
}
