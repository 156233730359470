import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {OrderStatusEnum} from "../order/enums/OrderStatusEnum";
import {Swiper, SwiperSlide} from "swiper/react";
import styled from "styled-components";
import {RateAlertTabEnum} from "../rateAlert/enums/RateAlertTabEnum";
import {
    selectAppBackFromPath,
    selectIsAfterPressingCancelOrder,
    updateIsAfterPressingCancelOrder
} from "../../slice/app";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {selectTabBarPosition, updateTabBarPosition} from "../../slice/fx";

type Props = {
    types: RateAlertTabEnum[] | OrderStatusEnum[];
    selectedType: string | OrderStatusEnum;
    handleSelectType: (type: RateAlertTabEnum | OrderStatusEnum) => void;
    resetCurrentPage: () => void;
};

export const TypeSelectBar = (props: Props) => {
    const isAfterPressingCancelOrder = useAppSelector(selectIsAfterPressingCancelOrder);
    const dispatch = useAppDispatch();
    const swiperRef = useRef<any>(null);
    const backFromPath: string = useAppSelector(selectAppBackFromPath)
    const [isFirstBackFromPathThatShouldSwipe, setIsFirstBackFromPathThatShouldSwipe] = useState(false)
    const handleSelectedType = (type: RateAlertTabEnum | OrderStatusEnum) => {
        if (type !== props.selectedType) {
            props.resetCurrentPage();
        }
        props.handleSelectType(type);
    };
    const tabBarPosition = useAppSelector(selectTabBarPosition)

    useEffect(() => {
        if (isAfterPressingCancelOrder) { //this is for orderStatus, in order details, that user click cancel order
            props.resetCurrentPage();
            props.handleSelectType(OrderStatusEnum.CANCELLED);
            dispatch(updateIsAfterPressingCancelOrder(false));
            if (swiperRef.current) { //this is for auto swiping to cancel
                // @ts-ignore
                const selectedIndex = props.types.indexOf(OrderStatusEnum.CANCELLED as OrderStatusEnum);
                if (selectedIndex !== -1) {
                    swiperRef.current.swiper.slideTo(selectedIndex);
                }
            }
        }
    }, [isAfterPressingCancelOrder]);

    // useEffect(() => {
    //         console.log(props.selectedType);
    //         if (backFromPath.includes("/order/details") && !isFirstBackFromPathThatShouldSwipe) {
    //             console.log("trigger");
    //
    //             // @ts-ignore
    //             const selectedIndex = props.types.indexOf(props.selectedType as OrderStatusEnum);
    //
    //             if (swiperRef.current && swiperRef.current.swiper) {
    //                 const swiperInstance = swiperRef.current.swiper;
    //
    //                 // First, move to the correct slide
    //                 swiperInstance.slideTo(selectedIndex, 0);
    //
    //                 // Add a slight right offset after the slideTo action
    //                 setTimeout(() => {
    //                     const currentTranslate = swiperInstance.getTranslate();
    //
    //                     const rightOffset = -100; // Adjust this value for the desired right offset
    //                     if(selectedIndex!==0 && selectedIndex!==-1){
    //                         console.log("double trigger")
    //                         swiperInstance.setTranslate(currentTranslate - rightOffset);
    //                         swiperInstance.updateProgress();
    //                     }
    //                 }, 30);
    //
    //                 setIsFirstBackFromPathThatShouldSwipe(true);
    //             }
    //         }
    // }, [props.selectedType]);

    useEffect(() => {
        setTimeout(() => {
            swiperRef.current.swiper.setTranslate(tabBarPosition, 0);
        }, 30); //this is must, otherwise it won't work

        if (swiperRef.current && swiperRef.current.swiper) {
            const swiperInstance = swiperRef.current.swiper;

            const logPosition = () => {
                const currentTranslate = swiperInstance.getTranslate();
                console.log("Current Translate Position:", currentTranslate);
                dispatch(updateTabBarPosition(currentTranslate));
            };

            // Create a debounced version of the logPosition function
            const debouncedLogPosition = debounce(logPosition, 100); // 200 milliseconds debounce

            // Attach only to the transitionEnd event
            swiperInstance.on('transitionEnd', debouncedLogPosition);

            // Clean up event listeners on unmount
            return () => {
                swiperInstance.off('transitionEnd', debouncedLogPosition);
            };
        }

    }, []);

    function debounce(func: Function, wait: number) {
        let timeout: NodeJS.Timeout;
        return function (...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const whatEverFunc = (index: number) => {
        console.log(index)
        swiperRef.current.swiper.slideTo(index, 0);
    }

    return (
        <TypeSelectBarContainer>
            {/*<button onClick={() => whatEverFunc(0,)}>scrollTo0</button>*/}
            {/*<button onClick={() => whatEverFunc(1)}>scrollTo1</button>*/}
            {/*<button onClick={() => whatEverFunc(2)}>scrollTo2</button>*/}
            {/*<button onClick={() => whatEverFunc(3)}>scrollTo3</button>*/}
            {/*<button onClick={() => whatEverFunc(4)}>scrollTo4</button>*/}
            {/*<button onClick={() => whatEverFunc(5)}>scrollTo5</button>*/}
            {/*<button onClick={() => whatEverFunc(6)}>scrollTo6</button>*/}
            <StyledSwiper
                ref={swiperRef}
                slidesPerView={"auto"}
            >
                {props.types.map((v, i) => (
                    <SwiperSlide key={i}>
                        <StyledSlide
                            onClick={() => handleSelectedType(v)}
                            style={props.selectedType === v ? {
                                fontWeight: "bold",
                                color: "black",
                                borderBottomStyle: "solid",
                                borderWidth: "3px"
                            } : {}}
                        >
                            {v}
                        </StyledSlide>
                    </SwiperSlide>
                ))}
            </StyledSwiper>
        </TypeSelectBarContainer>
    );
};

const StyledSwiper = styled(Swiper)`
    overflow: hidden;
    z-index: auto;
    height: 40px;

    .swiper-slide {
        &:first-child {
            padding-left: 24px;
        }

        &:last-child:not(:nth-child(1)) {
            padding-right: 24px;
        }

        &.swiper-slide {
            width: max-content;
            padding-right: 23px;
        }

        &.swiper-slide-active {
        }

        &.swiper-slide-active {

        }


    }
`;

const SwiperSlideContent = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledSlide = styled.div`
    width: max-content; // This makes the slide width as wide as its content
    color: #7c7c7d;
    font-size: 15px;
`;

const TypeSelectBarContainer = styled.div`
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
`;
