import {useEffect, useLayoutEffect, useRef, useState} from "react";
//import { useDispatch, useSelector } from "react-redux";
import {Routes, Route, useLocation} from "react-router-dom";
import {useTransition, animated} from "react-spring";

import AppHeader from "./components/common/AppHeader";
import Layout from "./components/common/Layout";
import LoadingContainer from "./components/styled/LoadingContainer";
import LoadingSpinner from "./components/styled/LoadingSpinner";
import WatchlistPage from "./pages/watchlist";
import WatchlistEditPage from "./pages/edit";
import FXPage from "./pages/fx";
import OrderFormPage from "./pages/order/form";
import OrderFormAccountPage from "./pages/order/accounts";
import OrderConfirmationPage, {confirmationMethods} from "./pages/order/confirmation";
import OrderPlacePage from "./pages/order/place";
// import CalculatorPage from "./pages/calculator";
import AccountProductPage from "./pages/account/product";
import CurrencyCalculatorPage from "./pages/currency-calculator";

import {APP_NAVIGATE_DIRECTION} from "./constants/app.constant";
import {DEFAULT_TOKEN_RENEW_REMAIN_TIME} from "./constants/common.constant";
import {useAppDispatch, useAppSelector} from "./utils/store.utils";
import {
    pushHistory,
    selectApp, selectFoRetry, selectRaRetry, setFoMaxActiveCount,
    setFoMaxExpiryDate,
    setFoProfitLimitation, setFoRetry,
    setNewsUpdateInterval,
    setRaMaxActiveCount, setRaMaxExpiryDate, setRaRetry, setUiPostMsgWaitTime, setWlUpdateInterval
} from "./slice/app";
import {updateUserBasicInformation} from "./slice/user";
import userAPI from "./api/user.api";
import {formatUserBasicInformation} from "./formatters/user/api/response";
import {emitErrorToApp} from "./utils/integration.utils";
import {
    formatCurrencies,
    formatFXRawList,
} from "./formatters/fx/api/response";
import {updateCurrencies, updateForexes} from "./slice/fx";
import NewsPage from "./pages/news";
import {updateMarketAxiosInstanceToken} from "./utils/api.utils";
import { getSessionStorage, setSessionStorage } from "./utils/common.utils";
import {
    fxlist_OR_ccylist_IS_FALSY_OR_EMPTY,
    RESPONSE_ERROR_OR_USER_DATA_INVALID,
    USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING
} from "./constants/errorMsg.constant";
import {useSnackbar} from "notistack";
import {processApiError} from "./utils/errorHandling.utils";
import {SHOW_WATCHLIST} from "./constants/test.constant";
import {ErrorObjForToastBar} from "./types/errorObjType.type";
import OrderStatus from "./pages/order/orderStatus/OrderStatus";
import {RateAlert} from "./pages/rateAlert/RateAlert";
import {CreateRateAlertStepThree} from "./pages/rateAlert/CreateRateAlertStepThree";
import {CreateRateAlertStepOne} from "./pages/rateAlert/CreateRateAlertStepOne";
import {CreateRateAlertStepTwo} from "./pages/rateAlert/CreateRateAlertStepTwo";
import {OrderDetail} from "./pages/order/orderStatus/OrderDetail";
import {AxiosResponse} from "axios";
import {UserGetConfigAPIResponseType} from "./types/user.type";
import {updateFxConfigDpUqConfig} from "./slice/fxConfig";
import {resetPasswordErrorTimeout} from "./slice/errorTimeout";

// Rename MUI component class name
/*unstable_ClassNameGenerator.configure((componentName) =>
    componentName.replace("Mui", "ex-mui-"),
);*/

/**
 * Initializes the application by fetching user config data and updating state.
 *
 * @async
 * @function init
 * @throws {ErrorObjForToastBar} - If response data is invalid or missing required fields.
 * @throws {Error} - If an error occurs during API call or processing.
 * @returns {void}
 */
function App() {
    const confirmationRef = useRef<confirmationMethods>(null);
    const location = useLocation();
    const app = useAppSelector(selectApp);
    const dispatch = useAppDispatch();
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [isPageBack, setIsPageBack] = useState<boolean>(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const transitions = useTransition(location, {
        from: {
            opacity: 0,
            transform:
                app.navigateDirection === APP_NAVIGATE_DIRECTION.NEXT
                    ? "translate3d(100%,0,0)"
                    : app.navigateDirection === APP_NAVIGATE_DIRECTION.PREVIOUS
                        ? "translate3d(-100%,0,0)"
                        : "translate3d(0%,0,0)",
        },
        enter: {
            opacity: 1,
            transform: "translate3d(0%,0,0)",
        },
        leave: {
            opacity: 0,
            transform:
                app.navigateDirection === APP_NAVIGATE_DIRECTION.NEXT
                    ? "translate3d(-50%,0,0)"
                    : app.navigateDirection === APP_NAVIGATE_DIRECTION.PREVIOUS
                        ? "translate3d(50%,0,0)"
                        : "translate3d(0%,0,0)",
        },
        initial: null,
    });

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "production")
            console.log(`App version: ${process.env.REACT_APP_APP_VERSION}`);
        init();
        dispatch(pushHistory({path: location.pathname})); // Record the first page
    }, []);

    useEffect(() => {
        setIsPageBack(false); // Trick for page transition animation

    }, [location]);

    // App Init
    /**
     * Initializes the application by fetching user config data and updating state.
     *
     * @async
     * @function init
     * @throws {ErrorObjForToastBar} - If response data is invalid or missing required fields.
     * @throws {Error} - If an error occurs during API call or processing.
     * @returns {void}
     */
    const init = async () => {
        try {
            const response:AxiosResponse<UserGetConfigAPIResponseType> = await userAPI.getConfig();
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data ||
                !response.data.data.type
            )
                throw {
                    errorMsg: RESPONSE_ERROR_OR_USER_DATA_INVALID,
                    responseHttpStatus: response.status,
                    responseData: response.data
                } as ErrorObjForToastBar
            if (!response.data.ccylist ||
                !response.data.fxlist ||
                response.data.ccylist.length === 0 ||
                response.data.fxlist.length === 0
            ) throw {
                errorMsg: fxlist_OR_ccylist_IS_FALSY_OR_EMPTY,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar

            if (!response.data.data.country ||
                !response.data.data.baseCurrency ||
                !response.data.data.type ||
                !response.data.data.fromCurrency
            ) throw {
                errorMsg: USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING,
                    responseHttpStatus: response.status,
                    responseData: response.data
            } as ErrorObjForToastBar

            const formattedUserBasicInformation = formatUserBasicInformation(
                response.data.data
            );
            const formattedCurrencies = formatCurrencies(response.data.ccylist);
            const formattedForexes = formatFXRawList(response.data.fxlist);
            if (response.data.data.mkttoken && response.data.data.mkttokenexp) {
                updateMarketAxiosInstanceToken(
                    response.data.data.mkttoken,
                    response.data.data.mkttokenexp
                );
            }
            setIsTokenValid(true);
            dispatch(updateUserBasicInformation(formattedUserBasicInformation));
            dispatch(updateCurrencies(formattedCurrencies));
            dispatch(updateForexes(formattedForexes));
            dispatch(updateFxConfigDpUqConfig(response.data.fxconfig))

            const {
                ra_max_active_count,
                ra_max_expiry_date,
                ra_retry,
                fo_max_active_count,
                fo_max_expiry_date,
                fo_retry,
                wl_update_interval,
                ui_post_msg_wait_time,
                news_update_interval,
                fo_profit_limitation,
            } = response.data.data;

            if (ra_max_active_count !== null && ra_max_active_count !== undefined) {
                dispatch(setRaMaxActiveCount(ra_max_active_count));
            }
            if (ra_max_expiry_date !== null && ra_max_expiry_date !== undefined) {
                dispatch(setRaMaxExpiryDate(ra_max_expiry_date));
            }
            if (fo_max_active_count !== null && fo_max_active_count !== undefined) {
                dispatch(setFoMaxActiveCount(fo_max_active_count));
            }
            if (fo_max_expiry_date !== null && fo_max_expiry_date !== undefined) {
                dispatch(setFoMaxExpiryDate(fo_max_expiry_date));
            }
            if (wl_update_interval !== null && wl_update_interval !== undefined) {
                dispatch(setWlUpdateInterval(wl_update_interval));
            }
            if (ui_post_msg_wait_time !== null && ui_post_msg_wait_time !== undefined) {
                dispatch(setUiPostMsgWaitTime(ui_post_msg_wait_time));
                dispatch(resetPasswordErrorTimeout(ui_post_msg_wait_time))
                dispatch(resetPasswordErrorTimeout(ui_post_msg_wait_time))
            }
            if (news_update_interval !== null && news_update_interval !== undefined) {
                dispatch(setNewsUpdateInterval(news_update_interval));
            }
            if (fo_profit_limitation !== null && fo_profit_limitation !== undefined) {
                dispatch(setFoProfitLimitation(fo_profit_limitation));
            }
            if (fo_retry !== null && fo_retry !== undefined) {
                dispatch(setFoRetry(fo_retry));
            }
            if (ra_retry !== null && ra_retry !== undefined) {
                dispatch(setRaRetry(ra_retry));
            }
            setInterval(refreshMarketToken, 60000);

        } catch (error) {
            processApiError(error, emitErrorToApp)
        } finally {
            setIsFirstLoading(false);
        }
    };

    /**
     * Refreshes the market token if it is expired or close to expiration.
     * If the token is refreshed, it updates the Axios instance with the new token.
     * If there is an error during the token refresh or if the response data is invalid,
     * it throws an error object.
     */
    const refreshMarketToken = () => {
        let tokenRemainTime = 9999999999;
        const marketTokenExpiration = getSessionStorage<number>(
            "marketTokenExpiration"
        );
        if (marketTokenExpiration) {
            tokenRemainTime = marketTokenExpiration;
        }

        if (tokenRemainTime < DEFAULT_TOKEN_RENEW_REMAIN_TIME) {
            userAPI
                .getConfig()
                .then((response) => {
                    if (
                        response.data.responseCode === "0" &&
                        response.data &&
                        response.data.data.mkttoken &&
                        response.data.data.mkttokenexp
                    ) {
                        updateMarketAxiosInstanceToken(
                            response.data.data.mkttoken,
                            response.data.data.mkttokenexp
                        );
                    }
                    if (
                        !response.data ||
                        response.data.responseCode !== "0" ||
                        !response.data.data ||
                        !response.data.data.type
                    )
                        throw {
                            errorMsg: RESPONSE_ERROR_OR_USER_DATA_INVALID,
                            responseHttpStatus: response.status,
                            responseData: response.data
                        } as ErrorObjForToastBar
                    if (!response.data.ccylist ||
                        !response.data.fxlist ||
                        response.data.ccylist.length === 0 ||
                        response.data.fxlist.length === 0) {
                        throw {
                            errorMsg: fxlist_OR_ccylist_IS_FALSY_OR_EMPTY,
                            responseHttpStatus: response.status,
                            responseData: response.data
                        } as ErrorObjForToastBar
                    }
                    if (!response.data.data.country ||
                        !response.data.data.baseCurrency ||
                        !response.data.data.type ||
                        !response.data.data.fromCurrency) {
                        throw {
                            errorMsg: USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING,
                            responseHttpStatus: response.status,
                            responseData: response.data
                        } as ErrorObjForToastBar
                    }

                })
                .catch((error) => {
                    processApiError(error, emitErrorToApp)
                });
        }
    };

    /**
     * Sets isPageBack flag to true and closes snackbar if confirmationRef is available.
     * @function
     * @name handlePageBack
     * @returns {void}
     */
    const handlePageBack = () => {
        setIsPageBack(true)
        if (confirmationRef.current) {
            confirmationRef.current.closeSnackbar()
        }

    };
    return (
        <div className="app">
            <AppHeader onBack={handlePageBack}/>
            {!isTokenValid ? (
                <Layout>
                    {isFirstLoading && (
                        <LoadingContainer>
                            <LoadingSpinner display="block" $centered/>
                        </LoadingContainer>
                    )}
                </Layout>
            ) : (
                transitions((style, item) => {
                    return (
                        <animated.div className="app-animate-container" style={style}>
                            <Routes location={item}>
                                {SHOW_WATCHLIST && (
                                    <>
                                        <Route path="/watchlist" element={<WatchlistPage/>}/>
                                        <Route path="/watchlist/edit/:watchlistId" element={<WatchlistEditPage/>}/>
                                    </>
                                )}

                                <Route path="/fx/:symbol" element={<FXPage/>}/>
                                <Route path="/news/">
                                    <Route path="" element={<NewsPage/>}/>
                                </Route>
                                {/*<Route path="/calculator" element={<CalculatorPage/>}/>*/}
                                <Route
                                    path="/account/product"
                                    element={<AccountProductPage/>}
                                />
                                <Route
                                    path="/currency-calculator"
                                    element={<CurrencyCalculatorPage />}
                                />
                                <Route path="/order/">
                                    <Route path="form/step-1" element={<OrderFormPage/>}/>
                                    <Route
                                        path="form/step-2"
                                        element={<OrderFormAccountPage/>}
                                    />
                                    <Route
                                        path="confirmation"
                                        element={<OrderConfirmationPage
                                            ref={confirmationRef}
                                        />}
                                    />
                                    <Route path="place" element={<OrderPlacePage/>}/>
                                    <Route path="status" element={<OrderStatus/>}/>
                                    <Route path="details" element={<OrderDetail/>}/>
                                </Route>
                                <Route path={"/rateAlert/"}>
                                    <Route path="view" element={<RateAlert/>}/>
                                    <Route path={"create/"}>
                                        <Route path="step1" element={<CreateRateAlertStepOne/>}/>
                                        <Route path="step2" element={<CreateRateAlertStepTwo/>}/>
                                        <Route path="step3" element={<CreateRateAlertStepThree/>}/>
                                    </Route>

                                </Route>


                            </Routes>

                        </animated.div>
                    );
                })
            )}
        </div>
    );
}

export default App;
