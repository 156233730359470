import { forwardRef } from "react";
import { SnackbarContent, CustomContentProps, closeSnackbar } from "notistack";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface StyledSnackbarProps {
	mode: "success" | "error" | "info";
}

interface SnackbarProps extends CustomContentProps, StyledSnackbarProps {
	mode: "success" | "error" | "info";
}

const StyledSnackbarContent = styled(SnackbarContent)<StyledSnackbarProps>`
	width: 100%;
	flex-wrap: nowrap;
	padding: 20px 24px;

	${({ mode }) => {
		switch (mode) {
			case "success":
				return `
					color: var(--snackbar-success-text-color);
					background-color: var(--snackbar-success-background-color);
				`;
			case "error":
				return `
					color: var(--snackbar-error-text-color);
					background-color: var(--snackbar-error-background-color);
				`;
			case "info":
				return `
					color: var(--snackbar-info-text-color);
					background-color: var(--snackbar-info-background-color);
				`;
		}
	}}

	.mbb-icon {
		flex-shrink: 0;
		margin-left: 12px;

		path {
			${({ mode }) => {
				switch (mode) {
					case "success":
						return `
							stroke: var(--snackbar-success-text-color);
						`;
					case "error":
						return `
							stroke: var(--snackbar-error-text-color);
						`;
					case "info":
						return `
							stroke: var(--snackbar-info-text-color);
						`;
				}
			}}
		}
	}
`;

const SnackbarMessage = styled.div`
	min-height: 54px;
	flex-grow: 1;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 18px;
`;

const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>((props, ref) => {
	const {
		id,
		message,
		persist,
		anchorOrigin,
		autoHideDuration,
		hideIconVariant,
		iconVariant,
		...rest
	} = props;

	return (
		<StyledSnackbarContent ref={ref} role="alert" {...rest}>
			<SnackbarMessage>{message}</SnackbarMessage>
			<CloseIcon className="mbb-icon" onClick={() => closeSnackbar(id)} />
		</StyledSnackbarContent>
	);
});

export default Snackbar;
