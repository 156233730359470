import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import {
	useParams,
	unstable_useBlocker as useBlocker,
	unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";
import { useIntl } from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import { ReactSortable } from "react-sortablejs";
import { useSnackbar } from "notistack";

import Layout from "../components/common/Layout";
import Button from "../components/styled/buttons/Button";
import Section from "../components/styled/sections/Section";
import SectionHeader from "../components/styled/sections/SectionHeader";
import SectionHeaderStartContainer from "../components/styled/sections/SectionHeaderStartContainer";
import SectionContent from "../components/styled/sections/SectionContent";
import FieldLabel from "../components/styled/FieldLabel";
import InputField from "../components/common/InputField";
import FooterButtonContainer from "../components/styled/buttons/FooterButtonContainer";
import EditableWatchlistFXCard, {
	StyledEditableWatchlistFXCard,
} from "../components/watchlist/edit/EditableWatchlistFXCard";
import FXMultipleSelectorDialog from "../components/watchlist/edit/FXMultipleSelectorDialog";
import AlertDialog from "../components/common/dialog/AlertDialog";

import { useAppDispatch, useAppSelector } from "../utils/store.utils";
import { selectWatchlist, updateWatchlist } from "../slice/watchlist";
import { FXType, SortableFXType } from "../types/fx.type";
import { WatchlistEditFormType } from "../types/watchlist.type";
import { INPUT_FIELD_TEXT_COUNT_MODE } from "../constants/common.constant";
import {
	DEFAULT_WATCHLIST_NAME_PATTERN,
	MAX_WATCHLIST_NAME_LENGTH,
	MIN_WATCHLIST_NAME_LENGTH,
} from "../constants/watchlist.constant";
import { formatFXListToSortable } from "../formatters/fx";
import { formatWatchlistUpdatePayload } from "../formatters/watchlist/api/request";
import watchlistAPI from "../api/watchlist.api";
import useNavigateThrottle from "../hooks/useNavigateThrottle";

import { ReactComponent as PlusIcon } from "../assets/icons/plus.svg";
import GradientContainer from "../components/common/GradientContainer";
import { getRealTextLength } from "../utils/common.utils";

const deepEqual = require("deep-equal");

const WatchlistEditFXSection = styled(Section)`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow-y: hidden;

	${SectionContent} {
		height: 100%;
		overflow-y: auto;
	}

	${StyledEditableWatchlistFXCard} {
		margin-left: calc(var(--common-layout-padding-x) + 8px);
		margin-right: var(--common-layout-padding-x);

		&:first-child {
			margin-top: 0;
		}
	}
`;

const ToggleFXSelectorButton = styled.div`
	display: flex;
	align-items: center;
	margin-left: var(--common-layout-padding-x);
	margin-right: var(--common-layout-padding-x);
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 18px;
`;

const ToggleFXSelectorButtonIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-right: 12px;
	border: 1px solid var(--button-secondary-border-color);
	border-radius: 100%;
	background-color: #fff;

	.mbb-icon {
		fill: var(--button-text-color);
	}
`;

const WatchlistEditPage = () => {
	const navigate = useNavigateThrottle();
	const params = useParams();
	const watchlistId = params.watchlistId ? parseInt(params.watchlistId) : -1;
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const watchlists = useAppSelector(selectWatchlist);
	const dispatch = useAppDispatch();
	const [watchlistEditForm, setWatchlistEditForm] =
		useState<WatchlistEditFormType>({
			name: watchlists[watchlistId] ? watchlists[watchlistId].name : "",
			fxList: watchlists[watchlistId]
				? formatFXListToSortable(watchlists[watchlistId].fxList)
				: [],
		});
	const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
	const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
	const [isFXMultipleSelectorDialogOpen, setIsFXMultipleSelectorDialogOpen] =
		useState<boolean>(false);
	const [isUnsavedAlertDialogOpen, setIsUnsavedAlertDialogOpen] =
		useState<boolean>(false);
	const isInitialized = useRef<boolean>(false);
	const selectedFXListSectionContentRef = createRef<HTMLDivElement>();
	const shouldBlock = useCallback<BlockerFunction>(
		({ currentLocation, nextLocation, historyAction }) => {
			if (isFormDirty) setIsUnsavedAlertDialogOpen(true);

			return isFormDirty;
		},
		[isFormDirty]
	);
	const blocker = useBlocker(shouldBlock);

	useEffect(() => {
		if (isInitialized.current) {
			if (watchlists[watchlistId]) {
				setIsFormDirty(
					!deepEqual(
						{
							...watchlistEditForm,
							fxList: watchlistEditForm.fxList.map((v) => v.symbol),
						},
						{
							name: watchlists[watchlistId].name,
							fxList: watchlists[watchlistId].fxList.map((v) => v.symbol),
						}
					)
				);
			}
		} else isInitialized.current = true;
	}, [watchlistEditForm]);

	/**
	 * Updates the name property of the watchlistEditForm state object
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The onChange event object
	 * @returns {void}
	 */
	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWatchlistEditForm({
			...watchlistEditForm,
			name: event.currentTarget.value.trimStart().replace(/\s+/g, " "),
		});
	};

	/**
	 * Updates the FX list in the watchlist edit form based on the given updated FX list.
	 *
	 * @param {SortableFXType[]} updatedFXList - The updated FX list.
	 */
	const handleFXSortChange = (updatedFXList: SortableFXType[]) => {
		setWatchlistEditForm({
			...watchlistEditForm,
			fxList: updatedFXList,
		});
	};

	/**
	 * Removes an item from the fxList array in the watchlistEditForm state.
	 * @param {number} index - The index of the item to be removed.
	 * @returns {Function} - A function that updates the watchlistEditForm state by removing an item at the specified index.
	 */
	const handleFXRemove = (index: number) => () => {
		setWatchlistEditForm((prev) => {
			let cloned = [...prev.fxList];
			cloned.splice(index, 1);
			return { ...watchlistEditForm, fxList: cloned };
		});
	};

	/**
	 * Handles the editing of the FX list.
	 * Updates the watchlistEditForm state with the formatted and sortable updatedFXList.
	 *
	 * @param {FXType[]} updatedFXList - The updated FX list to be formatted and sorted.
	 * @returns {void}
	 */
	const handleEditFXList = (updatedFXList: FXType[]) => {
		setWatchlistEditForm({
			...watchlistEditForm,
			fxList: formatFXListToSortable(updatedFXList),
		});
	};

	/**
	 * Resets the watchlist edit form by updating the name field with a default pattern.
	 * If the watchlistId is valid, the name field in the watchlist edit form will be updated with a default name pattern.
	 *
	 * @function
	 * @name handleReset
	 * @returns {void}
	 */
	const handleReset = () => {
		if (watchlists[watchlistId]) {
			setWatchlistEditForm({
				...watchlistEditForm,
				//name: watchlists[watchlistId].name,
				name: DEFAULT_WATCHLIST_NAME_PATTERN.replace(
					"{number}",
					watchlistId.toString()
				), // weird behaviour, bad UX
			});
		}
	};

	/**
	 * Handles the update of a watchlist.
	 *
	 * If the watchlist exists, it updates the watchlist with the values from the watchlistEditForm and sets its name to the trimmed value.
	 * It then submits the updated watchlist to the watchlistAPI for updating.
	 * If the update is successful, it flushes the changes using flushSync, sets the form dirty state to false, displays a success snackbar message, updates the watchlist in the global state
	 * using the dispatch function, and navigates back to the previous page.
	 * If the update fails, it displays an error snackbar message.
	 * Finally, it sets the form submitting state to false.
	 */
	const handleUpdate = () => {
		if (watchlists[watchlistId]) {
			const updatedWatchlist = {
				...watchlists[watchlistId],
				...watchlistEditForm,
				name: watchlistEditForm.name.trim(),
			};

			setIsFormSubmitting(true);
			watchlistAPI
				.update(formatWatchlistUpdatePayload(updatedWatchlist))
				.then((response) => {
					//console.log(response.data);
					flushSync(() => {
						setIsFormDirty((prev) => false);
					});
					enqueueSnackbar(
						intl.formatMessage(
							{
								id: "app.page.watchlistEdit.snackbar.update.message.success",
							},
							{ name: updatedWatchlist.name }
						),
						{ variant: "general", mode: "success" }
					);
					dispatch(
						updateWatchlist({
							selectedWatchlistIndex: watchlistId,
							watchlist: updatedWatchlist,
						})
					);
					navigate(-1);
				})
				.catch((error) => {
					console.error(error);
					enqueueSnackbar(
						intl.formatMessage({
							id: "app.page.watchlistEdit.snackbar.update.message.error",
						}),
						{ variant: "general", mode: "info" }
					);
				})
				.finally(() => {
					setIsFormSubmitting(false);
				});
		}
	};

	/**
	 * Handles the closing of the unsaved alert dialog.
	 */
	const handleUnsavedAlertDialogClose = () => {
		setIsUnsavedAlertDialogOpen(false);
		if (blocker.state === "blocked") blocker.reset();
	};

	/**
	 * Handles the confirmation of the unsaved alert dialog.
	 * Closes the unsaved alert dialog and resets the form dirty flag.
	 *
	 * @function handleUnsavedAlertDialogConfirm
	 * @returns {void}
	 */
	const handleUnsavedAlertDialogConfirm = () => {
		setIsUnsavedAlertDialogOpen(false);
		setIsFormDirty(false);
	};

	/**
	 * Handles the event when the unsaved alert dialog is exited.
	 *
	 * @function handleUnsavedAlertDialogExited
	 * @memberof {Object}
	 */
	const handleUnsavedAlertDialogExited = () => {
		if (blocker.proceed) blocker.proceed();
	};

	/**
	 * Validates the watchlist edit form value.
	 *
	 * @param {string} value - The value to be validated.
	 * @returns {boolean} Returns true if the value is valid, false otherwise.
	 */
	const watchlistEditFormValidator = (value: string) => {
		if (getRealTextLength(value.trim()) < MIN_WATCHLIST_NAME_LENGTH) {
			return false;
		}
		return true;
	};

	return (
		<Layout
			title={intl.formatMessage({ id: "app.page.watchlistEdit.header.title" })}
		>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: "app.page.watchlistEdit.header.title",
					})}
				</title>
			</Helmet>
			<Section hasMarginBottom>
				<SectionHeader>
					<FieldLabel>
						{intl.formatMessage({
							id: "app.page.watchlistEdit.form.edit.label.name",
						})}
					</FieldLabel>
				</SectionHeader>
				<SectionContent hasPaddingX>
					<InputField
						value={watchlistEditForm.name}
						fullWidth={true}
						textCount={{
							limit: MAX_WATCHLIST_NAME_LENGTH,
						}}
						inputProps={{
							onChange: handleNameChange,
						}}
					/>
				</SectionContent>
			</Section>
			<WatchlistEditFXSection>
				<SectionHeader>
					<FieldLabel>
						{intl.formatMessage({
							id: "app.page.watchlistEdit.form.edit.label.fx",
						})}
					</FieldLabel>
				</SectionHeader>
				<SectionContent ref={selectedFXListSectionContentRef}>
					<GradientContainer>
						<ReactSortable
							list={watchlistEditForm.fxList}
							setList={handleFXSortChange}
							easing="cubic-bezier(.4, 0, .2, 1)"
							animation={250}
							handle=".icon-sort"
						>
							{watchlistEditForm.fxList.map((v, i) => (
								<EditableWatchlistFXCard
									key={`editable-watchlist-fx-card-${i}`}
									id={v.id}
									fxObject={v}
									onRemove={handleFXRemove(i)}
								/>
							))}
						</ReactSortable>
						<ToggleFXSelectorButton
							onClick={() => setIsFXMultipleSelectorDialogOpen(true)}
						>
							<ToggleFXSelectorButtonIcon>
								<PlusIcon className="mbb-icon" />
							</ToggleFXSelectorButtonIcon>
							{intl.formatMessage({
								id: "app.page.watchlistEdit.form.edit.button.add",
							})}
						</ToggleFXSelectorButton>
					</GradientContainer>
				</SectionContent>
			</WatchlistEditFXSection>
			<FooterButtonContainer>
				<Button theme="secondary" size="lg" onClick={handleReset}>
					{intl.formatMessage({
						id: "app.page.watchlistEdit.form.edit.button.reset",
					})}
				</Button>
				<Button
					theme="primary"
					size="lg"
					onClick={handleUpdate}
					disabled={
						!watchlistEditFormValidator(watchlistEditForm.name) ||
						isFormSubmitting ||
						!isFormDirty
					}
				>
					{intl.formatMessage({
						id: "app.page.watchlistEdit.form.edit.button.update",
					})}
				</Button>
			</FooterButtonContainer>
			<FXMultipleSelectorDialog
				open={isFXMultipleSelectorDialogOpen}
				selectedFXList={watchlistEditForm.fxList || []}
				onClose={() => setIsFXMultipleSelectorDialogOpen(false)}
				onConfirm={handleEditFXList}
			/>
			<AlertDialog
				titleComp={intl.formatMessage({
					id: "app.page.watchlistEdit.dialog.unsaved.title",
				})}
				contentComp={intl.formatMessage({
					id: "app.page.watchlistEdit.dialog.unsaved.content",
				})}
				confirmButtonText={intl.formatMessage({
					id: "app.page.watchlistEdit.dialog.unsaved.button.ok",
				})}
				open={isUnsavedAlertDialogOpen}
				onClose={handleUnsavedAlertDialogClose}
				onConfirm={handleUnsavedAlertDialogConfirm}
				TransitionProps={{
					onExited: handleUnsavedAlertDialogExited,
				}}
			/>
		</Layout>
	);
};

export default WatchlistEditPage;
