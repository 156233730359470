import { NewsAPIResponseType, NewsType } from "../../../../../types/news.type";

/**
 * Format get news API response
 * @param {Array<NewsAPIResponseType>} response - The get news API response.
 * @return {Array<NewsType>} The array of formatted news object.
 */
const formatNews = (response: NewsAPIResponseType[]): NewsType[] =>
	response.map((v) => ({
		id: v.id,
		headline: v.hl,
		datetime: v.rt,
	}));

export default formatNews;
