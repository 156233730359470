import { forwardRef, useEffect, useRef, useState } from "react";
import {
	List as VirtualList,
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	ListRowRenderer,
} from "react-virtualized";
import { useIntl } from "react-intl";
import moment from "moment";

import NewsCard from "./NewsCard";
import NewsDialog from "./dialog/NewsDialog";

import {
	GroupedNewsListType,
	NewsType,
	NewsVirtualizedListItemType,
} from "../../types/news.type";
import {
	GROUPED_NEWS_SECTION_HEADER_TEXT_MAP,
	NEWS_VIRTUALIZED_LIST_ITEM_TYPE,
} from "../../constants/news.constant";
import { formatNewsVirtualizedItem } from "../../formatters/news";
import HeaderTitle from "../styled/HeaderTitle";

/**
 * Represents the properties for the NewsCardList component.
 *
 * @typedef {Object} NewsCardListProps
 *
 * @property {NewsType[]} newsList - The list of news items.
 * @property {string} [highlightBy] - The property to highlight news items by.
 * @property {boolean} [grouped] - Indicates whether the news items should be grouped.
 */
interface NewsCardListProps {
	newsList: NewsType[];
	highlightBy?: string;
	grouped?: boolean;
}

/**
 * The height of a news card.
 *
 * @type {number}
 * @constant
 * @default 82
 */
const NEWS_CARD_HEIGHT = 82;

/**
 * Represents a component that displays a list of news cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array.<NewsType>} props.newsList - The list of news to be displayed.
 * @param {string} props.highlightBy - The property to highlight news cards.
 * @param {boolean} props.grouped - Whether to group the news by date or not.
 * @param {React.Ref<unknown>} ref - The ref to forward to the underlying component.
 * @returns {React.ReactNode} The rendered NewsCardList component.
 */
const NewsCardList = forwardRef<unknown, NewsCardListProps>(
	({ newsList, highlightBy, grouped }, ref) => {
		const intl = useIntl();
		const [newsVirtualizedList, setNewsVirtualizedList] = useState<
			NewsVirtualizedListItemType[]
		>([]);
		const [selectedNews, setSelectedNews] = useState<NewsType | undefined>();
		const [isNewsDialogOpen, setIsNewsDialogOpen] = useState<boolean>(false);
		const virtualListRef = useRef<VirtualList | null>(null);
		const newsCardMeasureCache = new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: NEWS_CARD_HEIGHT,
		});

		useEffect(() => {
			let remadeNewsList: NewsVirtualizedListItemType[] = [];
			if (grouped) {
				const groupedNewsList = newsList.reduce(
					(a, b) => {
						if (
							moment
								.utc(b.datetime, "YYYYMMDDHHmmss")
								.local()
								.isSame(moment(), "day")
						)
							a.today.push(b);
						else a.thirtyDays.push(b);
						return a;
					},
					{ today: [], thirtyDays: [] } as GroupedNewsListType
				);

				Object.keys(groupedNewsList).forEach((group) => {
					if (groupedNewsList[group as keyof GroupedNewsListType].length > 0) {
						remadeNewsList.push({
							item: GROUPED_NEWS_SECTION_HEADER_TEXT_MAP[
								group as keyof typeof GROUPED_NEWS_SECTION_HEADER_TEXT_MAP
							]
								? intl.formatMessage({
										id: GROUPED_NEWS_SECTION_HEADER_TEXT_MAP[
											group as keyof typeof GROUPED_NEWS_SECTION_HEADER_TEXT_MAP
										],
								  })
								: intl.formatMessage({
										id: "app.page.fx.section.news.title",
								  }),
							type: NEWS_VIRTUALIZED_LIST_ITEM_TYPE.LABEL,
						});
					}
					for (
						let i = 0;
						i < groupedNewsList[group as keyof GroupedNewsListType].length;
						i++
					) {
						remadeNewsList.push(
							formatNewsVirtualizedItem(
								groupedNewsList[group as keyof GroupedNewsListType][i],
								NEWS_VIRTUALIZED_LIST_ITEM_TYPE.ITEM
							)
						);
					}
				});

				//if (grouped) setNewsVirtualizedList(grouped);
			} else {
			}

			setNewsVirtualizedList(remadeNewsList);
		}, [newsList]);

		useEffect(() => {
			if (virtualListRef.current) {
				virtualListRef.current.recomputeRowHeights();
			}
		}, [newsVirtualizedList]);

		const renderItem = (index: number) => {
			if (!newsVirtualizedList[index]) return null;

			let item = null;

			switch (newsVirtualizedList[index].type) {
				case NEWS_VIRTUALIZED_LIST_ITEM_TYPE.LABEL:
					if (newsVirtualizedList[index].type)
						item = (
							<HeaderTitle key={`news-grouped-header-${index}`}>
								{typeof newsVirtualizedList[index].item === "string" &&
									(newsVirtualizedList[index].item as string)}
							</HeaderTitle>
						);
					break;
				case NEWS_VIRTUALIZED_LIST_ITEM_TYPE.ITEM:
					if (
						newsVirtualizedList[index].item &&
						typeof newsVirtualizedList[index].item !== "string"
					) {
						item = (
							<NewsCard
								key={`news-${
									(newsVirtualizedList[index].item as NewsType).id
								}-${index}`}
								news={newsVirtualizedList[index].item as NewsType}
								highlightBy={highlightBy}
								onClick={() =>
									handleNewsClick(newsVirtualizedList[index].item as NewsType)
								}
							/>
						);
					}
					break;
			}

			return item;
		};

		const renderVirtualizedItem: ListRowRenderer = ({
			key,
			index,
			style,
			parent,
		}) => {
			if (!newsVirtualizedList[index]) return null;

			let item = null;

			switch (newsVirtualizedList[index].type) {
				case NEWS_VIRTUALIZED_LIST_ITEM_TYPE.LABEL:
					item = (
						<CellMeasurer
							key={key}
							cache={newsCardMeasureCache}
							parent={parent}
							columnIndex={0}
							rowIndex={index}
						>
							{({ measure }) => (
								<HeaderTitle style={style}>
									{typeof newsVirtualizedList[index].item === "string" &&
										(newsVirtualizedList[index].item as string)}
								</HeaderTitle>
							)}
						</CellMeasurer>
					);
					break;
				case NEWS_VIRTUALIZED_LIST_ITEM_TYPE.ITEM:
					if (newsVirtualizedList[index].item) {
						item = (
							<CellMeasurer
								key={key}
								cache={newsCardMeasureCache}
								parent={parent}
								columnIndex={0}
								rowIndex={index}
							>
								{({ measure }) => (
									<NewsCard
										key={`news-${newsList[index].id}-${index}`}
										news={newsVirtualizedList[index].item as NewsType}
										highlightBy={highlightBy}
										onClick={() => handleNewsClick(newsList[index])}
										onLoad={measure}
										style={style}
									/>
								)}
							</CellMeasurer>
						);
					}
					break;
			}

			return item;
		};

		const handleNewsClick = (news: NewsType) => {
			setSelectedNews(news);
			setIsNewsDialogOpen(true);
		};

		return (
			<>
				{/*
					<AutoSizer>
						{({ width, height }) => (
							<>
								<VirtualList
									height={height}
									ref={(node) => {
										virtualListRef.current = node;
										if (typeof ref === "function") {
											ref(node);
										} else if (ref) {
											ref.current = node;
										}
									}}
									deferredMeasurementCache={newsCardMeasureCache}
									rowCount={newsVirtualizedList.length}
									rowHeight={newsCardMeasureCache.rowHeight}
									rowRenderer={renderItem}
									width={width}
								/>
								{console.log(height)}
							</>
						)}
					</AutoSizer>
				*/}
				{grouped
					? newsVirtualizedList.map((v, i) => renderItem(i))
					: newsList.map((v, i) => (
							<NewsCard
								key={`news-card-${v.id}-${i}`}
								news={v}
								highlightBy={highlightBy}
								onClick={() => handleNewsClick(v)}
							/>
					  ))}
				{selectedNews?.id && (
					<NewsDialog
						newsId={selectedNews?.id}
						open={isNewsDialogOpen}
						onClose={() => setIsNewsDialogOpen(false)}
						onExited={() => setSelectedNews(undefined)}
					/>
				)}
			</>
		);
	}
);

export default NewsCardList;
