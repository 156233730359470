import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DpUqConfigType, FxConfigType} from "../types/fxConfig.type";
import {RootState} from "../store/store";

const initialState: FxConfigType = {
    dpUqConfig:[]
}

const fxConfigSlice = createSlice({
    name: "fxConfig",
    initialState,
    reducers: {
        updateFxConfigDpUqConfig: (
            state,
            action: PayloadAction<DpUqConfigType[]>
        ) => {
            state.dpUqConfig = action.payload;
        },
    }
});
export const {
    updateFxConfigDpUqConfig
} = fxConfigSlice.actions;
export const selectFxConfig = (state: RootState) => state.fxConfig
export const selectFxConfigDpUqConfig = (state: RootState)=>state.fxConfig.dpUqConfig
export default fxConfigSlice.reducer;
