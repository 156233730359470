import { matchPath, useLocation } from "react-router-dom";
import { APP_HEADER_HIDDEN_PATH } from "../constants/app.constant";

const useAppHeader = () => {
	const location = useLocation();

	const hasAppHeader = () => {
		let matches = [];
		for (let i = 0; i < APP_HEADER_HIDDEN_PATH.length; i++) {
			matches.push(
				matchPath({ path: APP_HEADER_HIDDEN_PATH[i] }, location.pathname)
			);
		}

		return matches.some((v) => !v);
	};

	return [hasAppHeader];
};

export default useAppHeader;
