import styled from "styled-components";

const Card = styled.div`
	border-radius: var(--card-border-radius);
	padding: 16px;
	background-color: var(--card-background-color);
	box-shadow: var(--card-shadow);
`;

export default Card;
