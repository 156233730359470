import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import RequireAuthenticate from "../components/common/RequireAuthenticate";
import Layout from "../components/common/Layout";
import Section from "../components/styled/sections/Section";
import SectionContent from "../components/styled/sections/SectionContent";
import SectionHeader from "../components/styled/sections/SectionHeader";
import SectionHeaderStartContainer from "../components/styled/sections/SectionHeaderStartContainer";
import LoadingContainer from "../components/styled/LoadingContainer";
import LoadingSpinner from "../components/styled/LoadingSpinner";
import Button from "../components/styled/buttons/Button";
import Anchor from "../components/styled/Anchor";
import FooterButtonContainer from "../components/styled/buttons/FooterButtonContainer";
import FXDashboardSection from "../components/fx/FXDashboardSection";
import FXSelectorDialog from "../components/common/dialog/FXSelectorDialog";
import NewsCardList from "../components/common/NewsCardList";
import Chart5 from "../components/fx/Chart5";
import { FXType } from "../types/fx.type";
import { NewsType } from "../types/news.type";
import { APP_NAVIGATE_DIRECTION } from "../constants/app.constant";
import { LATEST_FX_NEWS_AMOUNT } from "../constants/news.constant";
import { useAppDispatch, useAppSelector } from "../utils/store.utils";
import { selectApp } from "../slice/app";
import { selectFXLatestNews, updateFXLatestNews } from "../slice/news";
import useNavigateThrottle from "../hooks/useNavigateThrottle";
import { formatURL } from "../formatters/common";
import { formatFXRawList } from "../formatters/fx/api/response";
import { formatGetNewsPayload } from "../formatters/news/api/request";
import { formatNews } from "../formatters/news/api/response";
import newsAPI from "../api/news.api";
import fxAPI from "../api/fx.api";

const ScrollableContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow-y: auto;
`;

const FXPage = () => {
	const navigate = useNavigateThrottle();
	const params = useParams();
	const paramFX = params.symbol || "";
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const [isNewsFirstLoading, setIsNewsFirstLoading] = useState<boolean>(true);
	const [fxLatestNews, setFXLatestNews] = useState<NewsType[]>([]);
	const [isFXSelectorDialogOpen, setIsFXSelectorDialogOpen] =
		useState<boolean>(false);
	const selectingFX = useRef<FXType>(formatFXRawList([paramFX])[0]);
	//const isInitialized = useRef<boolean>(false);

	useEffect(() => {
		//console.log(selectingFX.current);
		//if (app.navigateDirection === APP_NAVIGATE_DIRECTION.NEXT) {
		fetchLatestNews();
		//}
	}, []);

	/**
	 * Fetches the latest news from the news API and sets the formatted news data in the state.
	 */
	const fetchLatestNews = () => {
		newsAPI
			.get(
				formatGetNewsPayload({
					fx: paramFX,
					limit: LATEST_FX_NEWS_AMOUNT,
				})
			)
			.then((response) => {
				//console.log(response.data);
				if (response.data.status !== 0)
					throw new Error(
						response.data.msg ||
							intl.formatMessage({ id: "app.common.message.error" })
					);
				setFXLatestNews(formatNews(response.data.data));
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(
					intl.formatMessage({
						id: "app.page.fx.snackbar.getLatestNews.message.error",
					}),
					{ variant: "general", mode: "info" }
				);
			})
			.finally(() => {
				setIsNewsFirstLoading(false);
			});
	};

	/**
	 * Function to handle the click event of FX.
	 * It sets the variable `isFXSelectorDialogOpen` to true.
	 *
	 * @function
	 * @name handleFXClick
	 * @returns {void}
	 */
	const handleFXClick = () => {
		setIsFXSelectorDialogOpen(true);
	};

	/**
	 * Handles the selection of a FX type object.
	 * Navigates to the formatted URL for the selected fxObject.
	 *
	 * @param {FXType} fxObject - The selected FX type object.
	 * @returns {void}
	 */
	const handleFXSelected = (fxObject: FXType) => {
		navigate(formatURL(`/fx/${fxObject.symbol}`), { replace: true });
	};

	return (
		<RequireAuthenticate>
			<Layout title={intl.formatMessage({ id: "app.page.fx.header.title" })}>
				<Helmet>
					<title>
						{intl.formatMessage({
							id: "app.page.fx.header.title",
						})}
					</title>
				</Helmet>
				<FXDashboardSection
					fxObject={selectingFX.current}
					onFXClick={handleFXClick}
				/>
				<ScrollableContainer>
					{/*<Section hasMarginBottom>
						<SectionHeader>
							<SectionHeaderStartContainer>
								{intl.formatMessage({
									id: "app.page.fx.section.chart.title",
								})}
							</SectionHeaderStartContainer>
							<FilterIcon className="mbb-icon" />
						</SectionHeader>
						<SectionContent hasPaddingX>
							<img
								src={fxChartPlaceholder}
								alt={`FX Chart - ${intl.formatMessage(
									{ id: "app.common.fx" },
									{
										source: selectingFX.current.currencies[0],
										target: selectingFX.current.currencies[1],
									}
								)}`}
								onContextMenu={(event) => event.preventDefault()}
							/>
						</SectionContent>
					</Section>*/}
					<Chart5 />
					<Section hasMarginBottom>
						<SectionHeader>
							<SectionHeaderStartContainer>
								{intl.formatMessage({
									id: "app.page.fx.section.news.title",
								})}
							</SectionHeaderStartContainer>
							<Anchor onClick={() => navigate(formatURL(`/news`))}>
								{intl.formatMessage({
									id: "app.page.fx.section.news.button.viewAll",
								})}
							</Anchor>
						</SectionHeader>
						<SectionContent hasPaddingX>
							{isNewsFirstLoading ? (
								<LoadingContainer>
									<LoadingSpinner display="block" $centered />
								</LoadingContainer>
							) : (
								<NewsCardList newsList={fxLatestNews} />
							)}
						</SectionContent>
					</Section>
				</ScrollableContainer>
				<FXSelectorDialog
					open={isFXSelectorDialogOpen}
					onClose={() => setIsFXSelectorDialogOpen(false)}
					onConfirm={handleFXSelected}
				/>
			</Layout>
		</RequireAuthenticate>
	);
};

export default FXPage;
