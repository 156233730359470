import {
	CurrencyType,
	FXOrderCurrencyConfigType,
} from "../../../../types/fx.type";

/**
 * Get currency min or max amount by order type
 * @param {CurrencyType} currency - The currency object.
 * @param {string} boundary - min or max.
 * @param {boolean} future - Is it future order.
 * @return {number} boundary amount.
 */
const getCurrencyAmountBoundaryByOrderType = (
	currency: CurrencyType,
	boundary: keyof FXOrderCurrencyConfigType["amount"],
	future: boolean
): number =>
	future
		? currency.config.futureOrder.amount[boundary]
		: currency.config.marketOrder.amount[boundary];

export default getCurrencyAmountBoundaryByOrderType;
