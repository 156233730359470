import {EXPIRY_DAYS_RANGE_T_PLUS_NDAYS} from "../../../../constants/futureCalendar.constant";
import {store} from "../../../../store/store"

/**
 * Function to check if a given date lies within a certain range of the current date
 *
 * @param {Date} date - The date to be checked
 * @param {Date} currentDate - The current date in consideration
 * @returns {boolean} - Returns true if the given date lies within the range, else false
 */
export function isWithinExpiryRange(date: Date, currentDate: Date, isRateAlert?:boolean): boolean {
    const state = store.getState();

    const expiryDaysRangeTPlusNDays = isRateAlert
        ? state.app.ra_max_expiry_date
        : state.app.fo_max_expiry_date;

    const currentDateMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const inputDateMidnight = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const dateAfterCertainDays = new Date(
        currentDateMidnight.getFullYear(),
        currentDateMidnight.getMonth(),
        currentDateMidnight.getDate() + expiryDaysRangeTPlusNDays
    );
    return inputDateMidnight >= currentDateMidnight && inputDateMidnight <= dateAfterCertainDays;
}
