import styled from "styled-components";

interface FieldLabelProps {
	hasMarginBottom?: boolean;
}

const FieldLabel = styled.div<FieldLabelProps>`
	${({ hasMarginBottom }) => hasMarginBottom && `margin-bottom: 16px;`}

	font-size: 14px;
	font-weight: var(--font-weight-light);
	line-height: 18px;
`;

export default FieldLabel;
