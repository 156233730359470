import styled from "styled-components";
import Card from "../../styled/Card";
import { ReactComponent as TickFilledIcon } from "../../../assets/icons/tick-filled.svg";
import {formatAccountNumber} from "../../../formatters/account/formatAccountNumber";

interface BankAccountSelectableCardProps {
	name: string;
	accountNumber: string;
	currency: string;
	balanceText: string;
	selected?: boolean;
}

const StyledBankAccountSelectableCard = styled(Card)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 86px;
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 12px;
`;

const BankAccountInformationContainer = styled.div`
	white-space: normal;
	word-break: break-word;
`;

const BankAccountSelectedIconContainer = styled.div`
	width: 24px;
	flex: 0 0 24px;
	margin-left: 16px;
`;

const BankAccountName = styled.div`
	margin-bottom: 4px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 16px;
`;

const BankAccountNumber = styled.div`
	margin-bottom: 5px;
	color: var(--text-color-secondary);
	font-weight: var(--font-weight-semi-light);
`;

const BankAccountBalance = styled.div`
	font-weight: var(--font-weight-semi-light);
`;

const BankAccountSelectableCard = ({
	name,
	accountNumber,
	currency,
	balanceText,
	selected,
}: BankAccountSelectableCardProps) => {
	return (
		<StyledBankAccountSelectableCard>
			<BankAccountInformationContainer>
				<BankAccountName>{name}</BankAccountName>
				<BankAccountNumber>{formatAccountNumber(accountNumber)}</BankAccountNumber>
				<BankAccountBalance>
					{currency} {balanceText}
				</BankAccountBalance>
			</BankAccountInformationContainer>
			<BankAccountSelectedIconContainer>
				{selected && <TickFilledIcon className="mbb-icon" />}
			</BankAccountSelectedIconContainer>
		</StyledBankAccountSelectableCard>
	);
};

export default BankAccountSelectableCard;
